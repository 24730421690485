export const getImportConfigs = /* GraphQL */ `
  query ImportByType(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelimportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    importByType(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        fieldMapping
        parInRow
        yardageInRow
        headerBeginsOnRow
        dataBeginsOnRow
        importType
        importAs
        updateExistingRecordsBasedOn
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

export const listNodes = /* GraphQL */ `
  query NodeByTargetType(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByTargetType(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        name
      }
      nextToken
    }
  }
`;

export const getRecordByName = /* GraphQL */ `
  query NodeByName(
      $name: String!
      $filter: ModelnodeFilterInput
      $limit: Int
      $nextToken: String
    ) {
      nodeByName(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        name
        date
        city
        type
        playerId
      }
      nextToken
    }
  }
`;

export const getRecordByPlayerId = /* GraphQL */ `
  query NodeByPlayerId(
    $playerId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByPlayerId(
      playerId: $playerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        name
        date
        city
        type
      }
      nextToken
    }
  }
`;

export const getRecordByNameWithDate = /* GraphQL */ `
  query NodeByNameSortedByDate(
      $name: String!
      $date: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelnodeFilterInput
      $limit: Int
      $nextToken: String
    ) {
      nodeByNameSortedByDate(
      name: $name
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId  
        targetTypeWithId
        name
        date
        city
        type
      }
      nextToken
    }
  }
`;

export const getRecordByNameWithCity = /* GraphQL */ `
  query NodeByNameSortedByCity(
      $name: String!
      $city: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelnodeFilterInput
      $limit: Int
      $nextToken: String
    ) {
      nodeByNameSortedByCity(
      name: $name
      city: $city
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId  
        targetTypeWithId
        name
        date
        city
        type
      }
      nextToken
    }
  }
`;

export const getAllNamesByType = /* GraphQL */ `
  query NodeByTargetType(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByTargetType(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        name
        date
        city
      }
      nextToken
    }
  }
`;

export const getPlayersForSelect = /* GraphQL */ `
  query NodeByTargetType(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByTargetType(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        type
        city
        country
        name
      }
      nextToken
    }
  }
`;

export const listChampcodes = /* GraphQL */ `
  query ListChampcodes(
    $id: ID
    $filter: ModelChampcodesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChampcodes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        code
        name
      }
      nextToken
    }
  }
`;
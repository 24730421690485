<template>
	<teleport to="#notification">
        <tw-notification 
        v-if="showNotification" 
        @close="showNotification = false" 
        :title="notificationTitle" 
        :subtitle="notificationSubtitle" 
        :duration="8000" 
        :icon="notificationIcon"/>
    </teleport>

	<div
		v-if="editable"
		class="px-6 sm:float-right sm:-mt-16 lg:float-right lg:-mt-16">
		<teleport
			to="#ribbon-actions"
			:disabled="!needsTeleported">
			<div
				class="ml-auto"
				id="ribbon-actions">
				<tw-button 
					v-show="!editing" 
					class="button btn-base btn-red w-full sm:w-24" 
					:rounded="true" @click="beginEdit()">
					Edit
				</tw-button>
				<tw-button 
					v-show="editing" 
					class="button btn-base btn-red "
					:class="{'w-full sm:w-24 lg:mr-4' : !needsTeleported, 'w-auto mr-4 sm:w-24' : needsTeleported}" 
					:rounded="true" 
					@click="saveEdit()">
					Save
				</tw-button>
				<tw-button 
					v-show="editing" 
					:class="{'mt-4 sm:mt-0 w-full sm:w-24' : !needsTeleported, 'w-auto sm:w-24' : needsTeleported}"
					class="button btn-base btn-white " 
					:rounded="true" @click="cancelEdit()">
					Cancel
				</tw-button>
			</div>
		</teleport>
	</div>

    <div class="grid grid-cols-2 gap-6 px-6 mt-4">

        <div class="card col-span-2 grid-flow-row gap-4">
            <h1 class="text-2xl mb-4">Personal Information</h1>
            <div v-if="!editing && !parentEditing" class="grid grid-cols-6 gap-4">
                <div v-for="(col, i) in personalInfo" :key="i" :class="col.cols">
                    <!--header-->
                    <p v-if="col.key != 'date' || !isRestricted" class="field-label">{{col.label}}</p>
                    <p v-else-if="col.key == 'date' && isRestricted" class="field-label">Age</p>
                    <!--value-->
                    <p v-if="col.key == 'date' && !isRestricted">{{ $filters.date(playerInfo[col.key] ) || '—' }}</p>
                    <p v-else-if="col.key == 'date' && isRestricted">
						<span v-if="restrictedDOB!='NaN'">{{ restrictedDOB}}</span><span v-else>—</span>
                    </p>
                    <p v-else>{{playerInfo[col.key] || "—"}}</p>
                </div>
            </div>
            <div v-else class="grid grid-cols-6 gap-4">
                <div v-for="(col, i) in personalInfo" :key="i" :class="col.cols">
					<div class="flex gap-2" v-if="col.key == 'name' && canEdit">
						<tw-input
							v-model="editedPlayerInfo.nameFirst"
							class="w-1/2"
							label="First Name"/>
						<tw-input
							v-model="editedPlayerInfo.nameLast"
							class="w-1/2"
							label="Last Name"/>
					</div>
					<div v-else-if="col.key == 'name' && !canEdit">
						<div class="flex justify-between">
							<label
								class="block text-sm font-medium text-usgaDarkGray truncate"
								for="name">
								Name
							</label>
						</div>
						<div id="name">{{editedPlayerInfo.name}}</div>
					</div>
					<template v-else-if="col.editType == 'select'">
						<div class="flex justify-between">
							<label
								class="block text-sm font-medium text-usgaDarkGray truncate"
								for="country-select">
								{{col.label}}
							</label>
						</div>
						<select
							v-model="editedPlayerInfo[col.key]"
							class="w-full border-gray-300 focus:ring-usgaRed focus:border-usgaRed shadow-sm rounded-sm mt-1"
							id="country-select"
							:label="col.label"
							style="height: 28pt" >
							<option v-for="country in countries" :key="country" :value="country.name">
								{{country.name}}
							</option>
						</select>
					</template>
                    <tw-multiselect
						v-else-if="col.editType == 'multiSelect'"
						v-model="editedPlayerInfo[col.key]"
						:items="countries" 
						:label="col.label"/>
                    <tw-input 
						v-else-if="col.key == 'date'" 
						v-model="editedPlayerInfo[col.key]"
						id="birthdate"
						:label="col.label"
						@input="validateBirthdate()"
						:isInvalid="invalidBirthdate"
						validation="Please enter a valid date" />
                    <tw-input 
						v-else 
						v-model="editedPlayerInfo[col.key]"
						:label="col.label"/>
                </div>
            </div>
        </div>

        <div class="card col-span-2 lg:col-span-1 grid-flow-row gap-4">
            <h1 class="text-2xl mb-4">Contact Information</h1>
            <div v-if="!editing && !parentEditing" class="grid grid-cols-2 gap-4">
                <div v-for="(col, i) in contactInfo" :key="i" :class="col.cols">
					<p class="field-label">{{col.label}}</p>
					<p>{{playerInfo[col.key] || "—"}}</p>
                </div>
            </div>
            <div v-else class="grid grid-cols-2 gap-4">
                <div v-for="(col, i) in contactInfo" :key="i" :class="col.cols">
                    <p class="field-label">{{col.label}}</p>
                    <textarea v-if="col.editType == 'textArea'" class="w-full border-gray-300 focus:ring-usgaRed focus:border-usgaRed shadow-sm rounded-sm" :rows="col.rows" v-model="editedPlayerInfo[col.key]"/>
                    <div v-else-if="col.editType == 'radioButton'" class="">
                        <label for="yes">Yes</label>
                        <input type="radio" name="yes" v-model="editedPlayerInfo[col.key]" class="mr-2"/>
                        <label for="no">No</label>
                        <input type="radio" name="no" v-model="editedPlayerInfo[col.key]" />
                    </div>
                    <tw-input v-else v-model="editedPlayerInfo[col.key]"></tw-input>
                </div>
            </div>
        </div>


        <div class="card col-span-2 lg:col-span-1 grid-flow-row">
            <h1 class="text-2xl mb-4">Education</h1>
            <div v-if="!editing && !parentEditing" class="grid grid-cols-2 gap-4">
                <div v-for="(col, i) in educationInfo" :key="i" :class="col.cols">
                    <p class="field-label">{{col.label}}</p>
                    <p>{{playerInfo[col.key] || "—"}}</p>
                </div>
            </div>
            <div v-else class="grid grid-cols-2 gap-4">
                <div v-for="(col, i) in educationInfo" :key="i" :class="col.cols">
                    <p class="field-label">{{col.label}}</p>
                    <textarea v-if="col.editType == 'textArea'" class="w-full border-gray-300 focus:ring-usgaRed focus:border-usgaRed shadow-sm rounded-sm" :rows="col.rows" v-model="editedPlayerInfo[col.key]"/>
                    <div v-else-if="col.editType == 'radioButton'" class="">
                        <label for="yes">Yes</label>
                        <input type="radio" name="yes" v-model="editedPlayerInfo[col.key]" class="mr-2"/>
                        <label for="no">No</label>
                        <input type="radio" name="no" v-model="editedPlayerInfo[col.key]" />
                    </div>
                    <tw-input v-else v-model="editedPlayerInfo[col.key]"></tw-input>
                </div>
            </div>
        </div>

        <div class="card col-span-2 grid-flow-row gap-4">
            <h1 class="text-2xl mb-4">Career & Interests</h1>
            <div v-if="!editing && !parentEditing" class="grid grid-cols-4 gap-4">
                <div v-for="(col, i) in careerAndInterestsInfo" :key="i" :class="col.cols">
                    <p class="field-label">{{col.label}}</p>
                    <p>{{playerInfo[col.key] || "—"}}</p>
                </div>
            </div>
            <div v-else class="grid grid-cols-4 gap-4">
                <div v-for="(col, i) in careerAndInterestsInfo" :key="i" :class="col.cols">
                    <p class="field-label">{{col.label}}</p>
                    <textarea v-if="col.editType == 'textArea'" class="w-full border-gray-300 focus:ring-usgaRed focus:border-usgaRed shadow-sm rounded-sm" :rows="col.rows" v-model="editedPlayerInfo[col.key]"/>
                    <div v-else-if="col.editType == 'radioButton'" class="">
                        <label for="yes">Yes</label>
                        <input type="radio" name="yes" v-model="editedPlayerInfo[col.key]" class="mr-2"/>
                        <label for="no">No</label>
                        <input type="radio" name="no" v-model="editedPlayerInfo[col.key]" />
                    </div>
                    <tw-input v-else v-model="editedPlayerInfo[col.key]"></tw-input>
                </div>
            </div>
        </div>

        <div class="card col-span-2 grid-flow-row gap-4">
            <h1 class="text-2xl mb-4">Playing History</h1>
            <div v-if="!editing && !parentEditing" class="grid grid-cols-4 gap-4 mb-4">
                <div v-for="(col, i) in playingHistoryInfo1" :key="i" :class="col.cols">
                    <p class="field-label">{{col.label}}</p>
                    <p v-if="typeof playerInfo[col.key] == 'boolean'">{{(playerInfo[col.key] ? "Yes" : "No")}}</p>
                    <p v-else>{{playerInfo[col.key] || "—"}}</p>
                </div>
            </div>
            <div v-else class="grid grid-cols-4 gap-4">
                <div v-for="(col, i) in playingHistoryInfo1" :key="i" :class="col.cols">
                    <p class="field-label">{{col.label}}</p>
                    <textarea v-if="col.editType == 'textArea'" class="w-full border-gray-300 focus:ring-usgaRed focus:border-usgaRed shadow-sm rounded-sm" :rows="col.rows" v-model="editedPlayerInfo[col.key]"/>
                    <div v-else-if="col.editType == 'radioButton'" class="">
                        <input type="radio" id="no"  :value="false" v-model="editedPlayerInfo[col.key]" class="mr-2 text-usgaRed focus:ring-usgaRed"/>
                        <label for="no" class="mr-10">No</label>
                        <input type="radio" id="yes" :value="true" v-model="editedPlayerInfo[col.key]" class="mr-2 text-usgaRed focus:ring-usgaRed"/>
                        <label for="yes">Yes</label>
                    </div>
                    <tw-input v-else v-model="editedPlayerInfo[col.key]"></tw-input>
                </div>
            </div>
            <div v-if="!editing && !parentEditing" class="grid grid-cols-4 gap-4 mt-3">
                <div v-for="(col, i) in playingHistoryInfo2" :key="i" :class="col.cols">
                    <p class="field-label">{{col.label}}</p>
                    <p v-if="typeof playerInfo[col.key] == 'boolean'">{{(playerInfo[col.key] ? "Yes" : "No")}}</p>
                    <p v-else-if="col.key == 'reinstatedAmateurDate'">{{ playerInfo[col.key] || '—' }}</p>
                    <p v-else>{{playerInfo[col.key] || "—"}}</p>
                </div>
            </div>
            <div v-else class="grid grid-cols-4 gap-4 mt-3">
                <div v-for="(col, i) in playingHistoryInfo2" :key="i" :class="col.cols">
					<template v-if="col.editType == 'textArea'">
						<label class="field-label" :for="col.key">{{col.label}}</label>
						<textarea 
							v-model="editedPlayerInfo[col.key]"
							class="w-full border-gray-300 focus:ring-usgaRed focus:border-usgaRed shadow-sm rounded-sm" 
							:id="col.key"
							:rows="col.rows" />
					</template>
                    <div v-else-if="col.editType == 'radioButton'" class="">
						<p class="field-label">{{col.label}}</p>
                        <input type="radio" id="no"  :value="false" v-model="editedPlayerInfo[col.key]" class="mr-2 text-usgaRed focus:ring-usgaRed"/>
                        <label for="no" class="mr-10">No</label>
                        <input type="radio" id="yes" :value="true" v-model="editedPlayerInfo[col.key]" class="mr-2 text-usgaRed focus:ring-usgaRed"/>
                        <label for="yes">Yes</label>
                    </div>
                    <tw-input 
						v-else v-model="editedPlayerInfo[col.key]" 
						:label="col.label"
						:disabled="col.relatedBool && !editedPlayerInfo[col.relatedBool]" 
						:class="{'opacity-60': col.relatedBool && !editedPlayerInfo[col.relatedBool]}" 
						:id="col.key"/>
                </div>
            </div>
        </div>

        <div class="card col-span-2 grid-flow-row gap-4">
            <h1 class="text-2xl mb-4 gap-4">Family</h1>
            <div v-if="!editing && !parentEditing" class="w-full">
                <div v-for="(col, i) in familyInfo" :key="i" :class="col.cols">
                    <p class="field-label">{{col.label}}</p>
                    <p>{{playerInfo[col.key] || "—"}}</p>
                </div>
            </div>
            <div v-else class="w-full">
                <div v-for="(col, i) in familyInfo" :key="i" :class="col.cols">
                    <p class="field-label">{{col.label}}</p>
                    <textarea v-if="col.editType == 'textArea'" class="w-full border-gray-300 focus:ring-usgaRed focus:border-usgaRed shadow-sm rounded-sm" :rows="col.rows" v-model="editedPlayerInfo[col.key]"/>
                    <div v-else-if="col.editType == 'radioButton'" class="">
                        <label for="yes">Yes</label>
                        <input type="radio" name="yes" v-model="editedPlayerInfo[col.key]" class="mr-2"/>
                        <label for="no">No</label>
                        <input type="radio" name="no" v-model="editedPlayerInfo[col.key]" />
                    </div>
                    <tw-input v-else v-model="editedPlayerInfo[col.key]"></tw-input>
                </div>
            </div>
        </div>
    </div>
    <div v-if="editable" class="flex">
        <div class="ml-auto mt-4">
            <tw-button v-show="editing" class="mr-4 button btn-base btn-white w-full mt-4 sm:mt-0 sm:w-24 md:w-full md:mt-4 lg:mt-0 lg:w-24" :rounded="true" @click="cancelEdit()">Cancel</tw-button>
            <tw-button v-show="editing" class="mr-6 button btn-base btn-red w-full sm:w-auto md:w-full lg:w-24 h-10" :rounded="true" @click="saveEdit()">Save</tw-button>
        </div>
    </div>
    <div class="flex">
        <p class="ml-auto my-4 mx-6"><span v-if="lastEditedDate">Last updated: {{lastEditedDate}}</span></p>
    </div>
</template>

<script setup>
    // #region IMPORT STATEMENTS

    // import { useStore } from 'vuex'
    import { defineProps, defineEmits, ref, onBeforeMount, defineExpose } from 'vue'
    import { updatePlayerProfileInfo } from './mutations.ts'
    import api from "@/utilities/api"
    import countries from "@/utilities/countries.json"
    import { useStore } from 'vuex'
	import { validateDate } from '@/utilities/validate.js'
	import { sanitizeDate } from '@/utilities/sanitize.js'
    import { VL_DB_FIELDS_PROFILE } from '@/utilities/valueLists.js'
	import { Auth } from 'aws-amplify'
    // #endregion


    // #region VARIABLES

    const store = useStore()
    const props = defineProps({
		playerInfo: {
			type: Object,
			default: null
		},
		editable: {
			type: Boolean,
			default: false
		},
		needsTeleported: {
			type: Boolean,
			default: false
		},
		isRestricted: {
			type: Boolean,
			default: false
		},
		restrictedDOB: {
			type: String,
			default: ''
		},
		parentEditing: {
			type: Boolean,
			default: false
		}
	})
    const emit = defineEmits([
        'updatePlayerInfo',
        'editing'
    ])
    defineExpose({
        saveEdit, validateBool
    })

    const editing = ref(false)

    const editedPlayerInfo = ref(JSON.parse(JSON.stringify(props.playerInfo)))

    const lastEditedDate = ref(null)

    const playerProfileHistory = ref([])
    

    const personalInfo = ref([
        {"label": "Name", "key": "name", "cols": "col-span-6 sm:col-span-2 xl:col-span-1"},
        {"label": "Maiden Name(s) (if applicable)", "key": "maidenName", "cols": "col-span-6 sm:col-span-2 xl:col-span-1"},
        {"label": "Nickname", "key": "nickname", "cols": "col-span-6 sm:col-span-2 xl:col-span-1"},
        {"label": "Date of Birth", "key": "date", "cols": "col-span-6 sm:col-span-2 xl:col-span-1"},
        {"label": "List All Countries of Citizenship", "key": "citizenshipCountries", "cols": "col-span-6 sm:col-span-2 xl:col-span-2", "editType": "multiSelect"},
        {"label": "Preferred City", "key": "city", "cols": "col-span-6 sm:col-span-2 xl:col-span-1"},
        {"label": "Preferred State/Province/Region", "key": "state", "cols": "col-span-6 sm:col-span-2 xl:col-span-1"},
        {"label": "Preferred Country", "key": "country", "cols": "col-span-6 sm:col-span-2 xl:col-span-1", "editType": "select"},
        {"label": "Birth City", "key": "birthCity", "cols": "col-span-6 sm:col-span-2 xl:col-span-1"},
        {"label": "Birth State/Region/Province", "key": "birthState", "cols": "col-span-6 sm:col-span-2 xl:col-span-1"},
        {"label": "Birth Country", "key": "birthCountry", "cols": "col-span-6 sm:col-span-2 xl:col-span-1", "editType": "select"},
    ])

    const contactInfo = ref([
        
        {"label": "Instagram", "key": "instagram", "cols": "col-span-1"},
        {"label": "Twitter", "key": "twitter", "cols": "col-span-1"},
    ])
	if(!props.isRestricted) {
		// add email
		contactInfo.value.unshift({"label": "Email Address", "key": "email", "cols": "col-span-1"})
	}

    const educationInfo = ref([
        {"label": "Name of School Attended (or Attending)", "key": "schoolName", "cols": "col-span-1"},
        {"label": "Year Graduated (or Expected to Graduate)", "key": "graduationYear", "cols": "col-span-1"},
        {"label": "Major/Field of Study (list all)", "key": "majorNames", "cols": "col-span-2"},
    ])

    const careerAndInterestsInfo = ref([
        {"label": "Occupation", "key": "occupation", "cols": "col-span-4 sm:col-span-2 lg:col-span-1"},
        {"label": "Company", "key": "company", "cols": "col-span-4 sm:col-span-2 lg:col-span-1"},
        {"label": "What are your interests and talents outside of golf?", "key": "interests", "cols": "col-span-4", "editType": "textArea", "rows": 3},
        {"label": "Tell us about yourself. What makes your story unique and noteworthy?", "key": "about", "cols": "col-span-4", "editType": "textArea", "rows": 4},
    ])

    const playingHistoryInfo1 = ref([
        {"label": "What age did you begin playing golf?", "key": "startAge", "cols": "col-span-4 sm:col-span-2 md:col-span-4 lg:col-span-2 xl:col-span-1"},
        {"label": "Who influenced you?", "key": "influencedBy", "cols": "col-span-4 sm:col-span-2 md:col-span-4 lg:col-span-2 xl:col-span-1"},
        {"label": "Do you play golf left-handed?", "key": "leftHanded", "cols": "col-span-4 sm:col-span-2 md:col-span-4 lg:col-span-2 xl:col-span-1", "editType": "radioButton"},
    ])

    const playingHistoryInfo2 = ref([
        {"label": "Have you made a hole in one?", "key": "holeInOne", "cols": "col-span-2 mt-6", "editType": "radioButton"},
        {"label": "Did you participate in any junior golf program?", "key": "juniorParticipant", "cols": "col-span-2 mt-6", "editType": "radioButton"},
        {"label": "If yes, please include course, year, and any details", "key": "holeInOneDetails", "cols": "col-span-2", "relatedBool": "holeInOne"},
        {"label": "If yes, please list program name and city", "key": "juniorProgramName", "cols": "col-span-2 mb-6", "relatedBool": "juniorParticipant"},
        {"label": "Are you a reinstated amateur?", "key": "reinstatedAmateur", "cols": "col-span-2", "editType": "radioButton"},
        {"label": "Did you participate in your school's golf team?", "key": "schoolGolfTeamParticipant", "cols": "col-span-2", "editType": "radioButton"},
        {"label": "If yes, as of when?", "key": "reinstatedAmateurDate", "cols": "col-span-2 mb-6", "relatedBool": "reinstatedAmateur"},
        {"label": "Please list all high school and/or college teams (include years)", "key": "schoolGolfTeamsList", "cols": "col-span-2 mb-6", "relatedBool": "schoolGolfTeamParticipant"},
        {"label": "List your complete record in USGA championships (please include year, championship, result)", "key": "usgaChampionshipRecord", "cols": "whitespace-pre-wrap col-span-4 mb-6", "editType": "textArea", "rows": 10},
        {"label": "List your playing record for non-USGA championships and events", "key": "recordOther", "cols": "whitespace-pre-wrap col-span-4 mb-6", "editType": "textArea", "rows": 10},
        {"label": "Lowest Competitive 18-hole score (please include year, course, score details)", "key": "lowestScore", "cols": "col-span-4 mb-6", "editType": "textArea", "rows": 2},
        {"label": "Please list any golf honors, highlights (wins or runner-up finishes), and awards", "key": "awards", "cols": "whitespace-pre-wrap col-span-4 mb-6", "editType": "textArea", "rows": 8},
        {"label": "How did you and your partner team up? (Please specify year, Four-Ball only)", "key": "partner", "cols": "col-span-4 mb-6", "editType": "textArea", "rows": 2},
    ])

    const familyInfo = ref([
        {"label": "List key family members: parents, brothers, sisters; spouse, children, etc. (please include name, age and relationship)", "key": "familyMembers", "cols": "w-full mb-6", "editType": "textArea", "rows": 5},
        {"label": "List all family members or significant others who have competed in USGA championships", "key": "usgaFamilyMembers", "cols": "w-full mb-6", "editType": "textArea", "rows": 3},
    ])

    const allObj = ref([
        personalInfo.value,
        contactInfo.value,
        educationInfo.value,
        careerAndInterestsInfo.value,
        playingHistoryInfo1.value, 
        playingHistoryInfo2.value,
        familyInfo.value
    ])
    // #endregion

	// #region notification
		const showNotification = ref(false)
		const notificationIcon = ref(0)
		const notificationTitle = ref('')
		const notificationSubtitle = ref('')
	// #endregion

	const invalidBirthdate = ref(false)
	/**
	 * Formats entered date for round
	 * validateRoundDate
	 */
	function validateBirthdate(){
		let valid = validateDate(editedPlayerInfo.value.date)
		invalidBirthdate.value = !valid
	}


	/**
	 * check that boolean answers have required details
	 */
	function validateBool(){	
        let isValid = true
        const bools = [
            {"bool": "holeInOne", "input": "holeInOneDetails", "message": "Please include course, year, and any details of the hole in one."},
            {"bool": "juniorParticipant", "input": "juniorProgramName", "message": "Please list program name and city of your junior golf program."} ,
            {"bool": "reinstatedAmateur", "input": "reinstatedAmateurDate", "message": "Please include the date you were reinstated."} ,
            {"bool": "schoolGolfTeamParticipant", "input": "schoolGolfTeamsList", "message": "Please list all school teams and the years you participated."} 
        ]
        for (let i=0; i<bools.length; i++){
            let bool = bools[i].bool
            let input = bools[i].input
            if(editedPlayerInfo.value[bool] && (editedPlayerInfo.value[input] == null || editedPlayerInfo.value[input] == '')){
                notificationTitle.value = 'Error'
                notificationSubtitle.value = bools[i].message
                showNotification.value = true
                notificationIcon.value = 1
                isValid = false
                document.getElementById(input).focus()
            }
        }
        return isValid
	}

    
    // #region MOUNTED
	const canEdit = ref(false)
    onBeforeMount( async () => {
        lastEditedDate.value = props.playerInfo.playerProfileUpdateAt ? new Date(props.playerInfo.playerProfileUpdateAt).toLocaleString() : ''
		try {
            const user = await Auth.currentAuthenticatedUser()
            canEdit.value = user?.attributes['custom:azureGroup'].indexOf(process.env.VUE_APP_ADMIN_GROUP) > -1
        }
        catch(e) {
            console.log(e)
        }
    })

    // #endregion

    // #region SAVE EDITED FIELDS

    async function saveEdit() {
		// validate
		if(invalidBirthdate.value){
			notificationTitle.value = 'Error'
			notificationSubtitle.value = 'Please enter a valid date of birth.'
			showNotification.value = true
			notificationIcon.value = 1
			document.getElementById('birthdate').focus()
			return
		}
        if(!validateBool()){
            return
        }
        try{          
            const timestamp = new Date().toISOString()

            if (props.playerInfo.playerProfileEditHistory) playerProfileHistory.value = JSON.parse(props.playerInfo.playerProfileEditHistory)

            const user = store.state.user.name
            
            var historyObj = {
                time: timestamp,
                //user: store.state.user.name, need to update this once demo is over
                user: user,
                fields: [],
                type: 'edited'
            }

            //Create input obj for api call    
            var input = {
                nodeTypeWithId: editedPlayerInfo.value.nodeTypeWithId,
                targetTypeWithId: editedPlayerInfo.value.targetTypeWithId,
                type: 'p',
                playerProfileUpdateAt: timestamp,
                playerProfileConfirmedAt: timestamp,
				needsPlayerProfileEmailSent: 0
            }
            for (const i in allObj.value){
                for (const j in allObj.value[i]){
                    if (editedPlayerInfo.value[allObj.value[i][j].key] != undefined){
                        //If country has been edited then update countryCode as well
                        if (allObj.value[i][j].key == "country"){
                            var country = countries.filter(obj => {
                                return obj.name === editedPlayerInfo.value[allObj.value[i][j].key]
                            })
                            input['countryCode'] = country[0].code
                            editedPlayerInfo.value['countryCode'] = country[0].code
                        }
						

                        //Add field to edit history
                        if (editedPlayerInfo.value[allObj.value[i][j].key] != props.playerInfo[allObj.value[i][j].key]){
                            // console.log(allObj.value[i][j].key)
                            const fieldLabel = (VL_DB_FIELDS_PROFILE.filter(obj => obj.value == allObj.value[i][j].key))[0].label

                            historyObj.fields.push({
                                [fieldLabel]: {
                                    old: props.playerInfo[allObj.value[i][j].key], 
                                    new: editedPlayerInfo.value[allObj.value[i][j].key]
                                }
                            })
                        }

						if(allObj.value[i][j].key == "date") {
							input['date'] = sanitizeDate(editedPlayerInfo.value['date'])
						}
						else if(allObj.value[i][j].key == "name") {
							input.nameFirst = editedPlayerInfo.value.nameFirst
							input.nameLast = editedPlayerInfo.value.nameLast
							let fullName = editedPlayerInfo.value.nameFirst + " " + editedPlayerInfo.value.nameLast
							input.name = fullName
							editedPlayerInfo.value.name = fullName
						}
						else {
							input[allObj.value[i][j].key] = editedPlayerInfo.value[allObj.value[i][j].key]
						}
                    }
                }
            }  

            if (historyObj.fields.length){
                playerProfileHistory.value.push(historyObj)
                input['playerProfileEditHistory'] = JSON.stringify(playerProfileHistory.value)
                editedPlayerInfo.value['playerProfileEditHistory'] = JSON.stringify(playerProfileHistory.value)
            }
            // format multiselect
			// input.citizenshipCountries = input.citizenshipCountries.join(', ')

            //API call to update record with new data
            await api(updatePlayerProfileInfo, { input })
        }
		catch(e) {
            console.log(e)
        }
		finally {
            //emit updated data to parent to update playerInfo obj
            emit('updatePlayerInfo', editedPlayerInfo.value)
            editing.value = false
        }
    }

    // #endregion 


    // #region CANCEL EDIT

    function cancelEdit(){
        editedPlayerInfo.value = props.playerInfo
        editing.value = false
        emit('editing', false)
    }

    // #endregion

    // #region CANCEL EDIT

    function beginEdit(){
        editing.value = true
        emit('editing', true)
    }

    // #endregion

</script>
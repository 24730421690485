
/**
 * Converts list of dates to human readable date range string
 * @param {Array} dates - array of AWSDate date strings
 * @returns string
 */
export default function (dates) {
    if(dates){
        let date1 = new Date(dates[0].replace(/-/g, '/')).toDateString()
        let date2 = new Date(dates[dates.length - 1 ].replace(/-/g, '/')).toDateString()
        return date1 + ' — ' + date2 
    }
    return '—'
}
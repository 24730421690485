export const createSystem = /* GraphQL */ `
  mutation CreateSystem(
    $input: CreateSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    createSystem(input: $input, condition: $condition) {
      id
      playerRegistrationTemplate
	  playerRegistrationVars
	  playerReminderTemplate
	  playerReminderVars
      createdAt
      updatedAt
    }
  }
`;

export const updateSystem = /* GraphQL */ `
  mutation UpdateSystem(
    $input: UpdateSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    updateSystem(input: $input, condition: $condition) {
	  id
	  playerRegistrationTemplate
	  playerRegistrationVars
	  playerReminderTemplate
	  playerReminderVars
      createdAt
      updatedAt
    }
  }
`;

export const createChampcodes = /* GraphQL */ `
  mutation CreateChampcodes(
    $input: CreateChampcodesInput!
    $condition: ModelChampcodesConditionInput
  ) {
    createChampcodes(input: $input, condition: $condition) {
      id
      code
      name
      createdAt
      updatedAt
    }
  }
`;

export const updateChampcodes = /* GraphQL */ `
  mutation UpdateChampcodes(
    $input: UpdateChampcodesInput!
    $condition: ModelChampcodesConditionInput
  ) {
    updateChampcodes(input: $input, condition: $condition) {
      id
      code
      name
      isActive
      createdAt
      updatedAt
    }
  }
`;
import Signup from './Signup.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement
import AuthLayout from '@/layouts/AuthLayout.vue'

export const route: Route[] = [
    {
      path: '/signup',
      name: 'Signup',
      component: Signup,
      meta: { layout: AuthLayout }
    }
];
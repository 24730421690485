<template>
    <tw-heading 
		title="Venues" 
		:bread-crumb="breadCrumbs"/>
    <div class="my-6">
        <tw-table 
			@view="routeToDetailPage" 
			@sort-query="sortColumn" 
			:config="venuesTableConfig" 
			:data="data" 
			:isLoading="isLoading" 
			:viewable="true"/>
    </div>
</template>

<script setup>

    // #region import statements
    import { ref, onMounted, onBeforeUnmount } from 'vue'
    import { venuesSortedByName } from './queries'
    import api from "@/utilities/api"
    import EventBus from '@/event'
    import { useRouter } from 'vue-router'
    // #endregion

    // #region setup
    const isLoading = ref(false)
    const error = ref(null)
    const data = ref([])
    const nextToken = ref(null)
    const sortDirection = ref('ASC')

    const venuesTableConfig = {
        columns: [
            {header: 'Name', prop: 'name', sort: venuesSortedByName, class: 'w-48 sm:w-64', headerClass: 'w-48 sm:w-64', editable: false}, //Used for testing since there is no name first in test data
            {header: 'City', prop: 'city' , class: 'w-28 sm:w-36', headerClass: 'w-28 sm:w-36', editable: false},
            {header: 'State', prop: 'state', class: 'w-16 sm:w-24', headerClass: 'w-16 sm:w-24', editable: false},
            {header: 'Country', prop: 'country', class: 'w-40 sm:w-52', headerClass: 'w-40 sm:w-52', editable: false},
        ],
        destination: 'Venue Detail',
    }

    //Create 
    const breadCrumbs = ref([])
    breadCrumbs.value = [
        {name: 'Browse all venues'},
    ]
    // #endregion

    // #region infinite scroll setup
    onMounted( () => {
        EventBus.on('infinite-scroll', async () =>{
            const payload = {
                sortDirection: sortDirection.value,
                query: venuesSortedByName
            }
            if(nextToken.value) await sortQuery(payload)
            EventBus.trigger('infinite-scroll-end', null)
        })
    })

    onBeforeUnmount( () => {
        EventBus.off('infinite-scroll', null)
    })
    // #endregion

    // #region query and sort table

    function sortColumn(payload) {
        sortDirection.value = payload.sortDirection
        nextToken.value = payload.nextToken
        data.value = []
        sortQuery(payload)
    }

    //Reruns query when user sorts table
    //Takes in sortDirection and query name to sort by seleced table header
    async function sortQuery(payload){
        isLoading.value = true
        const input = {
            limit: 30,
            type: 'v',
            sortDirection: sortDirection.value, 
            nextToken: nextToken.value
        }
        try{
            const res = await api(payload.query, input)
            const items = res.data.nodeByTargetType.items
            data.value = [...data.value, ...items]
            nextToken.value = res.data.nodeByTargetType.nextToken
        } catch(e){
            error.value = e
            console.log(error.value)
        } finally {
            isLoading.value = false
        }
    }            

    //Trys to run graphql query to retrieve all player info for player list table
    (async () => {
        const payload = {
            sortDirection: sortDirection.value,
            query: venuesSortedByName
        }
        sortQuery(payload)
    })()

    // #endregion

    // #region route user to detail page

	//Go to venue detail when view is clicked
	const router = useRouter()
	function routeToDetailPage(payload){
		router.push({name: venuesTableConfig.destination, params:{id: payload.nodeId}})
	}

	// #endregion

</script>
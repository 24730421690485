export const updatePlayerProfileInfo = /* GraphQL */ `
  mutation UpdateNode(
    $input: UpdateNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    updateNode(input: $input, condition: $condition) {
	  nodeTypeWithId
      targetTypeWithId
      name
	  nameFirst
	  nameLast
      date
      country
      city
	  birthCity
	  birthState
	  birthCountry
      championshipsPlayed
      championshipsWon
      img
      updatedAt
      about
      awards
      citizenshipCountries
      company
      countryCode
      email
      familyMembers
      graduationYear
      holeInOne
      holeInOneDetails
      homeClubCity
      homeClubCountry
      homeClubState
      influencedBy
      instagram
      interests
      juniorParticipant
      juniorProgramName
      leftHanded
      lowestScore
      maidenName
      majorNames
      nickname
      occupation
      partner
      recordOther
      reinstatedAmateur
      reinstatedAmateurDate
      schoolGolfTeamParticipant
      schoolGolfTeamsList
      schoolName
      startAge
      state
      twitter
      usgaFamilyMembers
      playerProfileUpdateAt
      playerProfileConfirmedAt
      playerProfileEditHistory
      usgaChampionshipRecord
    }
  }
`;
import Players from './Players.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/players',
      name: 'Players',
      component: Players,
      meta: {module: 'players'}
    }
];
import { API, graphqlOperation } from 'aws-amplify'

/**
 * Loads data until next tokens are exhausted
 * @param {String} query - GraphQL query to invoke
 * @param {String} nextToken - the current GraphQL query next token
 * @param {Object} input - input variables for GraphQL query
 * @param {String} queryName - name of GraphQL query being invoked
 * @returns array, string
 */
 export default async function async (query:any, nextToken:string|null, input:any, queryName:string){
    let list:any = []
    do {
        input.nextToken = nextToken
        let response:any
        try{
            response = await API.graphql(graphqlOperation(query, input))
        }
        catch(err){
            console.log(err)
            response = err
        }

        list = [ ...list, ...response.data[queryName].items]
        nextToken = response.data[queryName].nextToken
    } while(input.limit > list.length && nextToken)
    return [list, nextToken]
  }
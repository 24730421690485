<template>
	<teleport to="#notification">
        <tw-notification 
        v-if="showNotification" 
        @close="showNotification = false" 
        :title="notificationTitle" 
        :subtitle="notificationSubtitle" 
        :duration="8000" 
        :icon="notificationIcon"/>
    </teleport>
    <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-sm sm:px-10">
                <img class="mx-auto h-12 w-auto" src="../../assets/img/USGA-Logo.png" alt="USGA Logo" />
                <div v-if="!loading && !regId">
                    <div class="mt-6 grid grid-cols-1 gap-3">
						
						<div class="space-y-4" v-if="adminLogin">
							<h2 class="mt-6 text-center text-xl font-medium text-gray-900">Sign in using your USGA work account</h2>
							<button @click="usgaSignIn" class="w-full inline-flex justify-center py-2 px-4 border rounded-sm shadow-sm text-sm font-medium text-white bg-trueGray-800 hover:bg-trueGray-900" style="border-color: #2f2f2f; font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif; ; font-size: 15px">
								<img src="@/assets/img/Microsoft-Logo.svg" alt="Microsoft Azure">
								<span class="ml-2">Sign in with Microsoft</span>
							</button>
						</div>
						<div class="space-y-4" v-else-if="mediaLogin">
							<h2 class="text-center text-xl font-medium text-gray-900">Sign in using your Media account</h2>
							<button @click="mediaSignIn" class="w-full inline-flex justify-center py-2 px-4 border rounded-sm shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50" style="border-color: #8c8c8c; font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif; ; font-size: 15px">
								<span class="ml-2" style="color: #5e5e5e">Sign in</span>
							</button>
							<div class="w-full text-center mt-4">
								<router-link to="/signup" class="text-usgaRed hover:text-usgaDarkRed text-sm">
									<span>Request Media Access</span>
								</router-link>
							</div>
						</div>
						<div class="space-y-4" v-else>
							<h2 class="text-center text-xl font-medium text-gray-900">Sign in using your USGA Player Account</h2>
							<button @click="playerProfileSignIn" class="w-full inline-flex justify-center py-2 px-4 border rounded-sm shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50" style="border-color: #8c8c8c; font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif; ; font-size: 15px">
								<span class="ml-2" style="color: #5e5e5e">Sign in</span>
							</button>
							<div class="pt-8 text-center">
								<div @click="goToMediaLogin" class="text-usgaRed hover:text-usgaDarkRed text-sm cursor-pointer">
									Click here for Media login
								</div>
							</div>
						</div>
                    </div>
                </div>
                <div v-else-if="!loading && regId">
                    <p class="mt-6" >
						Welcome to your USGA Player Profile. Please sign in using your player account information from USGA.org.
					</p>
                    <p class="mt-6" >
						If you have existing information in your Player Profile from a previous year, please update all the appropriate fields to confirm that your information is accurate and current. 
					</p>
                    <p class="mt-6" >
						If you are completing your Player Profile for the first time, please take this opportunity to share as much as you can about your background and your golf career.
					</p>
                    <p class="mt-6" >
						If you have any questions, please contact <a class="link" href="mailto:mediarelations@usga.org">mediarelations@usga.org</a>.
					</p>
                    <div class="mt-6 grid grid-cols-1 gap-3">
                        <div class="space-y-4">
                            <h2 class="text-center text-xl font-medium text-gray-900">Sign in using your USGA Player Account</h2>
                            <button @click="playerProfileSignIn" class="w-full inline-flex justify-center py-2 px-4 border rounded-sm shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50" style="border-color: #8c8c8c; font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif; ; font-size: 15px">
                                <span class="ml-2" style="color: #5e5e5e">Sign in</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-else class="py-32">
                    <tw-loading></tw-loading>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup> 
    // #region import statements
    import { Auth, API } from 'aws-amplify'
    import { useRouter, useRoute} from 'vue-router'
    import { onBeforeMount, ref } from 'vue'
    import { useStore } from 'vuex'
    // #endregion

    // #region initializations
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const loading = ref(false)

    // const props = defineProps([
    //     'regId'
    // ])
    // #endregion

	// #region notification
		const showNotification = ref(false)
		const notificationIcon = ref(0)
		const notificationTitle = ref('')
		const notificationSubtitle = ref('')
	// #endregion

	const regId = ref(route?.query?.rid)
	const adminLogin = ref(route.path == '/admin' ? true : false)
	const mediaLogin = ref(route.path == '/media' ? true : false)

    // #region sign in and redirect

    /**
    * Log in for USGA users
    * @function usgaSignIn
    */
    function usgaSignIn() {
        // save any redirect to store
        store.commit('setLoginRedirect', route.query.redirect)
        Auth.federatedSignIn({
                provider: 'Azure'
        })
    }
	function goToMediaLogin(){
		router.push("/media")
	}

    /**
    * Log in for public users
    * @function mediaSignIn
    */
    function mediaSignIn() {
        // save any redirect to store
        store.commit('setLoginRedirect', route.query.redirect)
        Auth.federatedSignIn({
                provider: 'AzureB2C'
        })
    }

	/**
    * Log in for players
    * @function playerProfileSignIn
    */
    function playerProfileSignIn() {
        // save any registration id to store for access on return
        if (regId.value) {
            store.commit('setRegistrationId', regId.value)
        }
        // save any redirect to store
        store.commit('setLoginRedirect', route.query.redirect)
        Auth.federatedSignIn({
                provider: 'Auth0'
        })
    }

    onBeforeMount( async () => {
        loading.value = true
        try {
            // save user info to store
            const user = await Auth.currentAuthenticatedUser()

            const userAttributes = user.attributes
            store.commit('setUser', userAttributes)
            
            const registrationId = store.state.registrationId
			const registrationIdFromUrl = route.query?.rid
            // navigate to player profile, main, or redirected route
            const redirect = store.state.loginRedirect
            store.commit('setLoginRedirect', null)

            const isPlayerProfile = user.username.indexOf('auth0_auth0') > -1
            if (isPlayerProfile) {
				try {
					// Check for player profile registration
					if (registrationId || registrationIdFromUrl) {
						const apiName = 'playerProfileRegistration'
						const path = '/register'

						const put = {
							body: {
								registrationId: registrationId ?? registrationIdFromUrl,
								sub: userAttributes.sub
							}
						}

						await API.put(apiName, path, put)
					}
						
					router.push({name: 'PlayerProfile', params:{id: userAttributes.sub}})
				}

				catch(e) {
					store.commit('setRegistrationId', null)
					console.log(e.response.data.error)
					router.push({name: 'Login'})
					notificationTitle.value = 'Error'
					notificationSubtitle.value = e.response.data.error
					showNotification.value = true
					notificationIcon.value = 1
					// console.log('signing out')
					// await Auth.signOut()
				}

            }
            else if(redirect) router.push({path: redirect})
            else router.push({name: 'Championships'})
        }
        catch(e) {
            console.log(e)
        }
        finally{
            loading.value = false
        }
        
    });
    // #endregion

</script>
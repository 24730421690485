<template>
  <div class="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
        <div class="flex items-center flex-shrink-0 px-4">
			<img class="h-8 w-auto m-auto" src="@/assets/img/USGA-Logo.png" alt="USGA Logo" />
        </div>
		<div class="px-2 mt-4 max-w-lg w-full lg:max-w-xs">
			<label for="search" class="sr-only">Search</label>
			<div class="relative">
				<div class="absolute inset-y-0 left-0 pl-3 flex items-center ">
					<SearchIcon @click="routeToQuickSearch" class="h-5 w-5 text-usgaDarkGray cursor-pointer" aria-hidden="true"/>
				</div>
				<input @keyup.enter="routeToQuickSearch" v-model="searchText" id="search" name="search" class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm" placeholder="Search..." type="search" />
			</div>
		</div>
        <div class="mt-5 flex-1 flex flex-col">
        <nav class="flex-1 space-y-1">
            <router-link 
                @click="navigate" 
                v-for="item in props.navigation" 
                :key="item.name" 
                :to="item.to" 
                :class="[$route.meta.module == item.module ? 'bg-usgaRed text-white' : 'text-usgaDarkGray hover:bg-usgaRed hover:text-white', 'group flex items-center px-4 py-2 font-medium']">
                <component :is="item.icon" class="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
                {{ item.name }}
            </router-link>
				<div>
					<!-- <h3 class="px-4 text-sm font-semibold text-black uppercase tracking-wider mt-6" id="system-links">System</h3> -->
					<div class="space-y-1 mt-2 font-medium text-usgaDarkGray cursor-pointer" role="group" aria-labelledby="system-links">
						<router-link
							v-if="isAdmin"
							:to="{name: 'ImportData'}"
							:class="[$route.meta.module == 'importdata' ? 'bg-usgaRed text-white' : 'text-usgaDarkGray hover:bg-usgaRed hover:text-white', 'group flex items-center px-4 py-2 font-medium']">
							<UploadIcon class="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
							Import Data
						</router-link>
						<router-link
							v-if="isAdmin"
							:to="{name: 'ImportHistory'}"
							:class="[$route.meta.module == 'importhistory' ? 'bg-usgaRed text-white' : 'text-usgaDarkGray hover:bg-usgaRed hover:text-white', 'group flex items-center px-4 py-2 font-medium']">
							<ClipboardListIcon class="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
							Import History
						</router-link>
						<router-link
							v-if="isAdmin"
							:to="{name: 'SignupRequests'}"
							:class="[$route.meta.module == 'signuprequests' ? 'bg-usgaRed text-white' : 'text-usgaDarkGray hover:bg-usgaRed hover:text-white', 'group flex items-center px-4 py-2 font-medium']">
							<UserAddIcon class="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
							New User Requests
						</router-link>
						<!-- <router-link
							v-if="isAdminReadOnly || isAdmin"
							:to="{name: 'ExportData'}"
							:class="[$route.meta.module == 'exportdata' ? 'bg-usgaRed text-white' : 'text-usgaDarkGray hover:bg-usgaRed hover:text-white', 'group flex items-center px-4 py-2 font-medium']">
							<DocumentDownloadIcon class="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
							Export Data
						</router-link> -->
						<router-link
							v-if="isAdmin"
							:to="{name: 'ManagePlayerProfiles'}"
							:class="[$route.meta.module == 'manageplayerprofiles' ? 'bg-usgaRed text-white' : 'text-usgaDarkGray hover:bg-usgaRed hover:text-white', 'group flex items-center px-4 py-2 font-medium']">
							<InboxInIcon class="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
							Manage Player Profiles
						</router-link>
						<router-link
							v-if="isAdmin"
							:to="{name: 'Settings'}"
							:class="[$route.meta.module == 'settings' ? 'bg-usgaRed text-white' : 'text-usgaDarkGray hover:bg-usgaRed hover:text-white', 'group flex items-center px-4 py-2 font-medium']">
							<CogIcon class="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
							Settings
						</router-link>
					</div>
				</div>
        </nav>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, defineEmits, onBeforeMount, ref } from 'vue'
    import { Auth } from 'aws-amplify'
	import { useRouter } from 'vue-router'

	const props = defineProps([
		'navigation',
	])
	const emit = defineEmits([
		'on-navigate'
	])

	//Search text modeled to input of quick search
	const searchText = ref('')
	const router = useRouter()
	function routeToQuickSearch(){
		router.push({name: 'QuickSearch', params:{searchText: searchText.value}})
	}


	function navigate(){
		emit('on-navigate')
	}

	const isAdmin = ref(false)
	const isAdminReadOnly = ref(false)

	onBeforeMount( async () => {
		try {
			const user = await Auth.currentAuthenticatedUser()
            isAdmin.value = user?.attributes['custom:azureGroup'].indexOf(process.env.VUE_APP_ADMIN_GROUP) > -1
			isAdminReadOnly.value = user?.attributes['custom:azureGroup'].indexOf(process.env.VUE_APP_READ_ONLY_GROUP) > -1
		}
		catch(e) {
			console.log(e)
		}
		
	});
</script>
export const getPlayerByUserId = /* GraphQL */ `
  query NodeByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        updatedAt
        about
        awards
		birthCity
		birthState
		birthCountry
        citizenshipCountries
        city
        company
        country
        countryCode
        date
        email
        familyMembers
        graduationYear
        holeInOne
        holeInOneDetails
        homeClubCity
        homeClubCountry
        homeClubState
        influencedBy
        instagram
        interests
        juniorParticipant
        juniorProgramName
        leftHanded
        lowestScore
        maidenName
        majorNames
        name
		nameFirst
		nameLast
        nickname
        occupation
        partner
        recordOther
        reinstatedAmateur
        reinstatedAmateurDate
        schoolGolfTeamParticipant
        schoolGolfTeamsList
        schoolName
        startAge
        state
        twitter
        usgaFamilyMembers
        playerProfileUpdateAt
        playerProfileConfirmedAt
        playerProfileEditHistory
      }
      nextToken
    }
  }
`;


export const listSystems = /* GraphQL */ `
  query ListSystems(
    $id: ID
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSystems(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        playerRegistrationTemplate
	    playerRegistrationVars
	    playerReminderTemplate
	    playerReminderVars
      }
      nextToken
    }
  }
`;

export const listChampcodes = /* GraphQL */ `
  query ListChampcodes(
    $id: ID
    $filter: ModelChampcodesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChampcodes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        code
        name
		isActive
      }
      nextToken
    }
  }
`;
<template>
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div aria-live="assertive" class="fixed inset-0 flex items-end px-6 py-2 pointer-events-none sm:items-start z-50">
        <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <div v-if="show" class="backdrop-blur-md backdrop-filter bg-opacity-70 max-w-md w-full bg-white shadow-lg border-usgaGray border rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div class="p-2">
                    <div class="ml-4 flex-shrink-0 flex float-right">
                        <button @click="close" class=" rounded-md inline-flex text-black hover:text-usgaRed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-usgaRed">
                        <span class="sr-only">Close</span>
                        <XIcon class="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                    <div class="flex items-center">
                        <div v-if="icon == 0" class="flex-shrink-0">
                            <CheckCircleIcon class="h-10 w-10 text-green-500" aria-hidden="true" />
                        </div>
                        <div v-if="icon == 1" class="flex-shrink-0">
                            <ExclamationCircleIcon class="h-10 w-10 text-usgaRed" aria-hidden="true" />
                        </div>
                        <div class="ml-3 w-0 flex-1">
                            <p class="font-bold text-gray-900">{{ title }}</p>
                            <p class="text-usgaDarkGray">{{ subtitle }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
    props: [
        'title',
        'subtitle',
        'duration',
        'icon',
    ],
    emits: [
        'closeNotificaiton'
    ],
    setup(props, ctx) {
        const show = ref(true);

        setTimeout(() => {
            close()
        }, props.duration)

        function close(){
            show.value = false
            ctx.emit('close')
        }
        
        return {
            show,
            close
        }
    },
})
</script>

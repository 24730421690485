<template>
		<main class="flex-1">
              <tw-heading title="Settings"></tw-heading>
          <div class="sm:mx-6">
            <!-- <div class="pt-10 pb-16"> -->
              <div class="px-4 sm:px-6 md:px-0">
                <div>
                  <!-- Tabs -->
                  <div>
					<div class="sm:hidden">
						<label for="tabs" class="sr-only">Select a tab</label>
						<!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
						<select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-usgaRed focus:outline-none focus:ring-usgaRed sm:text-sm">
							<option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
						</select>
					</div>
					<div class="hidden sm:block">
						<div class="border-b border-gray-200">
						<nav class="-mb-px flex space-x-8" aria-label="Tabs">
							<p @click="changeTab(i)" v-for="(tab, i) in tabs" :key="tab.name" :class="[tab.current ? 'border-usgaRed text-usgaRed ' : 'cursor-pointer border-transparent text-black hover:text-black hover:border-usgaRed ', 'whitespace-nowrap py-4 px-1 border-b-4 font-medium']" :aria-current="tab.current ? 'page' : undefined">{{ tab.name }}</p>
						</nav>
						</div>
					</div>
				</div>

                  <!-- Description list with inline editing -->
                  <div class="card mt-10 divide-y divide-gray-200" v-show="tabs[0].current">
                    <div class="space-y-1 flex justify-between">
						<div>
							<h3 class="text-lg font-medium leading-6 text-gray-900">Player Profiles</h3>
							<p class="max-w-2xl text-sm text-gray-500">Select which SendGrid templates to use below.</p>
						</div>
						<div>

						
							<tw-button 
								v-show="!editing" 
								class="button btn-base btn-red w-full sm:w-24" 
								:rounded="true" 
								@click="edit()">
								Edit
							</tw-button>
							<tw-button 
								v-show="editing" 
								class="button btn-base btn-red "
								:class="{'w-full sm:w-24 lg:mr-4' : !needsTeleported, 'w-auto mr-4 sm:w-24' : needsTeleported}" 
								:rounded="true" 
								@click="saveEdit()">
								Save
							</tw-button>
							<tw-button 
								v-show="editing" 
								:class="{'mt-4 sm:mt-0 w-full sm:w-24' : !needsTeleported, 'w-auto sm:w-24' : needsTeleported}"
								class="button btn-base btn-white " 
								:rounded="true" @click="cancelEdit()">
								Cancel
							</tw-button>
						</div>
                    </div>
                    <div class="mt-6">
                      <dl class="divide-y divide-gray-200">
                        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                          <dt class="text-sm font-medium text-gray-500">New Player Registration</dt>
                          <dd class="mt-1 flex  align-middle text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span v-if="!editing" class="flex-grow">{{settings.playerRegistrationTemplate || '—'}}</span>
							<tw-input v-else v-model="editedSettings.playerRegistrationTemplate"></tw-input>
                            <!-- <span class="ml-4 flex-shrink-0">
                              <button @click="getTemplate" type="button" class="rounded-md bg-white font-medium text-usgaRed hover:text-usgaRed focus:outline-none focus:ring-2 focus:ring-usgaRed focus:ring-offset-2">Get Template</button>
                            </span> -->
                          </dd>
                        </div>
                        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                          <dt class="text-sm font-medium text-gray-500">Player Registration Reminder</dt>
                          <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span v-if="!editing" class="flex-grow">{{settings.playerReminderTemplate || '—'}}</span>
							<tw-input v-else v-model="editedSettings.playerReminderTemplate"></tw-input>
                            <!-- <span class="ml-4 flex-shrink-0">
                              <button type="button" class="rounded-md bg-white font-medium text-usgaRed hover:text-usgaRed focus:outline-none focus:ring-2 focus:ring-usgaRed focus:ring-offset-2">Edit</button>
                            </span> -->
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>

				<div class="card mt-10 mb-6 divide-y divide-gray-200" v-show="tabs[1].current">
                    <div class="space-y-1 flex justify-between">
						<div>
							<h3 class="text-lg font-medium leading-6 text-gray-900">Championship Codes</h3>
							<p class="max-w-2xl text-sm text-gray-500">Add championship codes below. A code cannot be removed once added.</p>
						</div>
						<tw-button class="btn-red btn-base" rounded @click="newChampCode">Add Championship Code</tw-button>
					</div>
					<div class="mt-6">
						<dl class="divide-y divide-gray-200">
							<div class="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5" v-for="champCode in champCodes" :key="champCode">
								<dt class="text-sm font-medium text-gray-500">{{champCode.code}}</dt>
								<dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{champCode.name}}</dd>
								<div class="sm:col-span-1">
									<Switch 
										@update:modelValue="value => updateChampCodeStatus(value, champCode.id)"
										v-model="champCode.isActive" 
										:class="[champCode.isActive ? 'bg-usgaRed' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-usgaRed focus:ring-offset-2']">
										<span class="sr-only">Is Active</span>
										<span aria-hidden="true" :class="[champCode.isActive ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
									</Switch>
								</div>
							</div>
						</dl>
						<div v-show="addChampCode">
							<div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
								<dt class="text-sm font-medium text-gray-500">{{newChampCodeCode}}</dt>
								<dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
									<tw-input v-model="newChampCodeName"></tw-input>
									<tw-button class="btn-red btn-base" rounded @click="confirmNewChampCode">Save</tw-button>
								</dd>
							</div>
						</div>
					</div>
				</div>

                </div>
              </div>
            <!-- </div> -->
          </div>
        </main>
</template>

<script setup>
	import { ref } from 'vue'
	import { listSystems, listChampcodes} from './queries'
	import { createSystem, updateSystem, createChampcodes, updateChampcodes } from './mutations'
    import api from "@/utilities/api"
	import { Switch } from '@headlessui/vue'

	const tabs = ref([
		{ name: 'Email', current: true },
		{ name: 'Championships', current: false },
	])
    function changeTab(i) {
		tabs.value.forEach(tab => {tab.current = false})
		tabs.value[i].current = true
		if(i == 1) loadChampCodes()
    }
	const settings = ref({})
	const champCodes = ref([])
	const editedSettings = ref({})
	const editing = ref(false);
	const addChampCode = ref(false)
	const newChampCodeName = ref("")
	const newChampCodeCode = ref(0)
	function newChampCode() {
		newChampCodeName.value = ""
		newChampCodeCode.value = null
		let champCodeInts = champCodes.value.map( (champCode) => {
			return champCode.code
		})
		if(champCodeInts.length === 0) newChampCodeCode.value = 1
		else newChampCodeCode.value = Math.max(...champCodeInts) + 1
		addChampCode.value = true
	}
	async function confirmNewChampCode() {
		const input = {
			code: newChampCodeCode.value,
			name: newChampCodeName.value
		}

        await api(createChampcodes, { input })
		await loadChampCodes()
		addChampCode.value = false
	}
	function edit(){
		editedSettings.value = settings.value
        editing.value = true
    }
	async function saveEdit(){
		try{
			const input = editedSettings.value
			await api(updateSystem, { input })
		} 
		catch(e){
			console.log(e)
		}
		finally {
			editing.value = false
		}
	}
	function cancelEdit(){
		editedSettings.value = settings.value
        editing.value = false
	}

	async function loadChampCodes(){
		// load system record or create
		const input = {
			limit: 100
		}
		try{
			const listRes = await api(listChampcodes, input)
			const items = listRes.data.listChampcodes.items.sort((a,b) => {return a.code - b.code})
			champCodes.value = items
		} 
		catch(e){
			console.log(e)
		}
	}

	// function getTemplate(){
	// 	let templateId = 'd-b902237b67c144afacf6958edf19731c'
	// 	const apiName = 'emailSetup'
    //     const path = '/template'

    //     const post = {
    //         body: {
    //             templateId: templateId
    //         }
    //     }

    //     let res = API.get(apiName, path, post)
	// 	console.log(res)
	// }

	const needsTeleported = ref(false);
	(async () => {
		// load system record or create
		const input = {
			limit: 1
		}
		try{
			const listRes = await api(listSystems, input)
			const items = listRes.data.listSystems.items
			if(items.length < 1){
				const input = {}
				const createRes = await api(createSystem, { input })
				settings.value = createRes.data.createSystem
				delete settings.value.createdAt
				delete settings.value.updatedAt
			}
			else{
				settings.value = items[0]
			}
			if(settings.value.playerRegistrationVars === null) settings.value.playerRegistrationVars = "{}"
			if(settings.value.playerReminderVars === null) settings.value.playerReminderVars = "{}"
		} 
		catch(e){
			console.log(e)
		}
	})()

	async function updateChampCodeStatus(value, champCodeID){
		const input = {
			id: champCodeID,
			isActive: value
		}

		try{
			await api(updateChampcodes, { input })
		}
		catch(error){
			console.log('Error:', error)
		}
	}

</script>

import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import { Nav } from '@/types' // must be imported since file contains an export statement

export const nav: Nav = { 
    name: 'Advanced Search', 
    to: {name: 'AdvancedSearch'}, 
    icon: MagnifyingGlassIcon,
    position: 3,
    show: true,
    module: 'search'
}
<template>
    <tw-heading title="New User Requests" ></tw-heading>

    <!-- <SwitchGroup as="div" class="flex items-center justify-end mr-6">
        <SwitchLabel as="span" class="ml-6 mr-3">
            <span class="text-sm font-medium text-gray-900">Show Archived </span>
        </SwitchLabel>
        <Switch v-model="enabled" :class="[enabled ? 'bg-usgaRed' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-usgaRed']">
            <span aria-hidden="true" :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
        </Switch>
    </SwitchGroup> -->
	<div class="mx-6" >
		<div class="sm:hidden">
			<label for="tabs" class="sr-only">Select a tab</label>
			<!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
			<select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-usgaRed focus:outline-none focus:ring-usgaRed sm:text-sm">
				<option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
			</select>
		</div>
		<div class="hidden sm:block">
			<div class="border-b border-gray-200">
			<nav class="-mb-px flex space-x-8" aria-label="Tabs">
				<p @click="changeTab(i)" v-for="(tab, i) in tabs" :key="tab.name" :class="[tab.current ? 'border-usgaRed text-usgaRed ' : 'cursor-pointer border-transparent text-black hover:text-black hover:border-usgaRed ', 'whitespace-nowrap py-4 px-1 border-b-4 font-medium']" :aria-current="tab.current ? 'page' : undefined">{{ tab.name }}</p>
			</nav>
			</div>
		</div>
	</div>
    <div
		class="my-6">
        <tw-table 
			@sort-query="sortColumn" 
			:config="tableConfig" 
			:data="dataPending" 
			:isLoading="isLoading" 
			:viewable="false"
			v-show="tabs[0].current"
			@setArchive="setArchive" 
			:archive="true"/>
        <tw-table 
			@sort-query="sortColumn" 
			:config="tableConfig" 
			:data="dataArchived" 
			:isLoading="isLoading" 
			:viewable="false"
			v-show="tabs[1].current"
			@setArchive="setArchive" 
			:archive="true"/>
    </div>
</template>

<script setup>
    // #region import statements
    import { ref, onMounted, onBeforeUnmount } from 'vue'
    import { signupByArchived } from './queries'
    import { updateRequestInfo } from './mutations.ts'
    import api from "@/utilities/api"
    import EventBus from '@/event'
    // #endregion

    // #region setup
    const nextToken = ref(null)
    const dataNotArchived = ref([])
    const dataPending = ref([])
    const dataArchived = ref([])
    const isLoading = ref(false)
	
    const tableConfig = {
        columns: [
            {header: 'Name', prop: 'name', class: 'w-32 sm:w-48', headerClass: 'w-32 sm:w-48', editable: false,},
            {header: 'Email', prop: 'email', class: 'w-32 sm:w-48', headerClass: 'w-32 sm:w-48', editable: false,},
            {header: 'Title', prop: 'title', class: 'w-32 sm:w-48', headerClass: 'w-32 sm:w-48', editable: false,},
            {header: 'Company', prop: 'company', class: 'w-32 sm:w-48', headerClass: 'w-32 sm:w-48', editable: false,},
            {header: 'Created At', sort: signupByArchived, prop: 'createdAt', class: 'w-40 sm:w-48', headerClass: 'w-40 sm:w-48', editable: false,},
        ]
    }

     // #region toggle tab control
	const tabs = ref([
		{ name: 'Pending', current: true },
		{ name: 'Archived', current: false }
	])
    function changeTab(i) {
		tabs.value.forEach(tab => {tab.current = false})
		tabs.value[i].current = true
    }
	// #endregion

    // #endregion

    // #region infinite scroll setup
    onMounted(() => {
        EventBus.on('infinite-scroll', async() => {
            if(nextToken.value) await getRequests()
            EventBus.trigger('infinite-scroll-end', null)
        })
    })

    onBeforeUnmount( () => {
        EventBus.off('infinite-scroll', null)
    })
    // #endregion

    // #region Query to get all requests.
        async function getRequests () {
            isLoading.value = true
            const input = {
				archived: 0,
                limit: 30, 
                nextToken: nextToken.value,
				sortDirection: 'DESC'
            }
            try {
                const res = await api(signupByArchived, input)
                const items = res.data.signupByArchived.items
                nextToken.value = res.data.signupByArchived.nextToken
                dataPending.value = [...items]
            }
			catch(e) {
                console.log(e)
            }
			finally {
                isLoading.value = false
            }
        }

        async function getArchivedRequests () {
            isLoading.value = true
            const input = {
				archived: 1,
                limit: 30, 
                nextToken: nextToken.value,
				sortDirection: 'DESC'
            }
            try {
                const res = await api(signupByArchived, input)
                const items = res.data.signupByArchived.items
                nextToken.value = res.data.signupByArchived.nextToken
                dataArchived.value = [...items]
            }
			catch(e) {
                console.log(e)
            }
			finally {
                isLoading.value = false
            }
        }

        (async () => {
            getRequests()
			getArchivedRequests()
        })()

    // #endregion

    // #region set archive when button is clicked
    async function setArchive(payload) {
        const input = {
            'id': payload.id,
            'name': payload.name,
            'archived': payload.archive,
			'createdAt': payload.createdAt
        }

        try {
			await api(updateRequestInfo, { input })
        }
		catch(e) {
            console.log(e)
        }
		finally {
            nextToken.value = null
            getRequests()
            getArchivedRequests()
        }
    }
    // @endregion

	/**
	 * Sorts column of table based on header clicked
	 * @function sortColumn
	 * @param {Object} payload - Payload object that contains sort direction and query 
	 */
	function sortColumn(payload) {
		nextToken.value = null
		dataPending.value = []
		dataNotArchived.value = []
		sortQuery(payload)
	}

	//Reruns query when user sorts table
	//Takes in sortDirection and query name to sort by seleced table header
	async function sortQuery(payload) {
		isLoading.value = true
		const input = {
			archived: 0,
			limit: 30, 
			nextToken: null,
			sortDirection: payload.sortDirection
		}
		try{
			const res = await api(payload.query, input)
			const items = res.data.signupByArchived.items
			nextToken.value = res.data.signupByArchived.nextToken
			dataPending.value = items
		} catch(e) {
			console.log(e)
		} finally {
			isLoading.value = false
		}
	}
</script>

<template>
    <div>
        <!-- :class in div removes margin if its the first or last column to remove uneeded borders -->
        <div class="grid grid-cols-10 h-12 w-52 mx-6"  
            :class="[
            {'mr-6 ml-0' : columnIndex == 0},
            {'mr-0 ml-6' : columnIndex == (columnCount - 1) && columnIndex != 0}
            ]">
            <!-- :class in div sets bg of entry to gray if they are the loser. Also rounds border based on which entry it is in the matchup -->
            <div class="text-usgaLightGray col-span-2 bg-usgaBlue border-r-2 font-medium text-2xl h-12 flex justify-center items-center" 
                :class="[
                    {'bg-usgaLightGray': !winner, 'text-usgaDarkGray': !winner}, 
                    (entryIndex == 0) ? ('rounded-tl-sm') : ('rounded-bl-sm'),
                ]">
				{{ rank }}
            </div>
            <!-- :class in div sets bg of entry to gray if they are the loser. Also rounds border based on which entry it is in the matchup -->
            <div class="col-span-8 bg-white px-2 py-2 flex justify-left items-center h-12" 
                :class="[
                    {'bg-usgaLightGray': !winner},
                    (entryIndex == 0) ? ('rounded-tr-sm') : ('rounded-br-sm'),
                ]">
                <div class="text-sm whitespace-pre" :class="{'text-usgaDarkGray': !winner}">
                    <div>{{ players.split(' / ').join('\n') }}</div>
                </div>
                <div v-if="winner">{{ score }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: [
        'players',
        'rank',
        'winner',
        'score',
        'entryIndex',
        'columnIndex',
        'columnCount',
    ],
    
    setup() {
        return {

        }
    },
})
</script>
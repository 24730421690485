<template>
    <div>
        <tw-heading title="Saved Searches" :subtitle="subtitle"></tw-heading>
    </div>

    <div class="mx-6">
        <ul role="list" class="mt-6 focus-within:border-t divide-y divide-gray-200">
            <li class="" v-for="(item, itemIdx) in savedSearches" :key="itemIdx">
                <div class="relative group py-4 flex items-start space-x-3">
                    <div class="flex-shrink-0">
                        <span :class="[item.iconColor, 'inline-flex items-center justify-center h-10 w-10 rounded-lg']">
                            <component :is="item.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                    </div>
                    <div class="min-w-0 flex-1">
                        <div class="text-sm font-medium text-gray-900">
                            <router-link :to="{ name: 'AdvancedSearch', params: { item: JSON.stringify(item) } }">
                                <span class="absolute inset-0" aria-hidden="true" />
                                {{ item.name }}
                            </router-link>
                            <router-view></router-view>
                        </div>
                        <p class="text-sm text-gray-500">{{ item.description }}</p>
                    </div>
                    <div class="flex-shrink-0 self-center">
                        <ChevronRightIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    </div>
                </div>
            </li>
            <li></li>
        </ul>
    </div>
</template>

<script setup>

	// #region import statements
	import {
		FlagIcon,
		UsersIcon,
		MapPinIcon as LocationMarkerIcon 
	} from '@heroicons/vue/24/outline'

	import { ref } from 'vue'
	import { listSavedSearchByUser } from './queries.ts'
	import api from "@/utilities/api"
	// #endregion

	const savedSearches = ref(null)

	const subtitle = {
		description: 'Choose a starting point for a new search or select a recent saved search',
	};  

	(async () => {
		getSavedSearches()
	})()

    
    async function getSavedSearches() {
        savedSearches.value = await api(listSavedSearchByUser)
        savedSearches.value = savedSearches.value.data.listSearches.items
        savedSearches.value = savedSearches.value.map(function(obj) {
            var o = {
                ...obj,
                criteria: JSON.parse(obj.criteria)
            }
            if (obj.type == 'p'){
                o['iconColor'] = 'bg-usgaBlue'
                o['icon'] = UsersIcon
            }else if (obj.type == 'v'){
                o['iconColor'] = 'bg-usgaDarkGray'
                o['icon'] = LocationMarkerIcon
            }else if (obj.type == 'c'){
                o['iconColor'] = 'bg-usgaRed'
                o['icon'] = FlagIcon
            }
            return o
        })
    }

</script>
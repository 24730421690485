import SignupRequests from './SignupRequests.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/signup-requests',
      name: 'SignupRequests',
      component: SignupRequests,
      meta: { module: 'signuprequests' }
    }
];
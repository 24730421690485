<template>
    <div class="card">
        <h1 class="text-2xl mb-8">Change History</h1> 

        <div v-for="(history, i) in editHistory" :key="i" class="flex">
            <div class="mr-4">
                <div class=" rounded-full bg-usgaLightGray mt-2 p-2">
                    <PencilIcon v-if="history.type == 'edited'" class="h-6 w-6 text-usgaDarkGray"></PencilIcon>
                    <SolidCheckCircleIcon v-if="history.type == 'confirmed'" class="h-6 w-6 text-usgaDarkGray"></SolidCheckCircleIcon>
                    <UserCircleIcon v-if="history.type == 'created'" class="h-6 w-6 text-usgaDarkGray"></UserCircleIcon>
                </div>
                <div v-if="history.type != 'created'" class="mx-auto h-12 w-0 border-l-2 mt-2 border-usgaGray"></div>
            </div> 
            <div v-if="history.type == 'edited'">
                <p class=" ">{{history.user}} edited fields</p>
                <p class=" text-usgaDarkGray">{{(new Date(history.time)).toLocaleString()}}</p>
                <!-- Need to v-for over fields obj and list out keys -->
                <div class="flex">
                    <p v-for="(field, i) in history.fields" :key="i" class="capitalize">{{Object.keys(field)[0]}}{{i+1 != history.fields.length ? ', ': ''}}</p>
                </div>
            </div>  
            <div v-if="history.type == 'confirmed'">
                <p class=" ">{{history.user}} confirmed their information is up to date</p>
                <p class=" text-usgaDarkGray">{{(new Date(history.time)).toLocaleString()}}</p>
            </div> 
            <div v-if="history.type == 'created'">
                <p class=" ">{{history.user}} created player profile</p>
                <p class=" text-usgaDarkGray">{{(new Date(history.time)).toLocaleString()}}</p>
            </div>          
        </div> 
    </div>
</template>

<script setup>
    // #region IMPORT STATEMENTS

    // import { useStore } from 'vuex'
    import { ref, onBeforeMount, defineProps } from 'vue'
    import { getEditHistory } from './queries.ts'
    import api from "@/utilities/api"
    // #endregion


    // #region VARIABLES

    const editHistory = ref([])

    const props = defineProps([
        'userId'
    ])

    // #endregion

    
    // #region MOUNTED

    onBeforeMount( async () => {
        //Run query to get history
        try {
            const queryInput = {
                nodeTypeWithId: props.userId,
                targetTypeWithId: props.userId
            }
            const queryRes = await api(getEditHistory, queryInput)
            const res = queryRes.data.getNode
            editHistory.value = JSON.parse(res.playerProfileEditHistory)
            editHistory.value.sort((a,b) => (a.time < b.time) ? 1 : ((b.time < a.time) ? -1 : 0))
        } 
		catch(e) {
            console.log(e)
        }
    })

    // #endregion


</script>
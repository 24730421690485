import { createRouter, createWebHistory } from 'vue-router'
import { Route } from '@/types'; // must be imported since file contains an export statement
import { Auth } from 'aws-amplify'
import { TabPanel } from '@headlessui/vue';

// Import all of the resource routes files
function loadRoutes() {
    const context = require.context('@/modules', true, /routes.ts$/i)
    return context.keys()
        .map(context) // import module
        .map((m: any) => m.route) // get `default` export from each resolved module
}

// Define the routes
const routes: Route[] = []
const resourceRoutes = loadRoutes();

resourceRoutes.forEach((moduleRoutes) => {
    moduleRoutes.forEach((route: Route) => {
        routes.push(route)
    })
});

// Create the router instance and pass the `routes` option
export const router = createRouter({
    history: createWebHistory(), // use history mode
    routes // short for `routes: routes`,
})

router.beforeEach(async (to: any, from: any, next: any) => {
    const user = await authenticate()

	// check for player profile session
	const groups = user?.signInUserSession?.accessToken?.payload["cognito:groups"]
	const isPlayerProfile = groups?.includes('auth0')

	// prevent player profile sessions from accessing other routes
	if(isPlayerProfile && to.name !== 'PlayerProfile' && to.name !== 'Login') {
		next({ name: 'PlayerProfile' })
	}
	// prevent unathenticated users from accessing other routes besides login or signup
    else if (to.name !== 'Login' && to.name !== 'AdminLogin' && to.name !== 'MediaLogin' && to.name !== 'Signup' && !user) {
		next({ name: 'Login', query: { redirect: to.fullPath } })
	}
	// let user through to requested route
    else next()
});


async function authenticate(){
    try {
        const user = await Auth.currentAuthenticatedUser()
        return user
    }
    catch(e) {
        return false
    }
}
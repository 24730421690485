export const getEditHistory = /* GraphQL */ `
  query GetNode($nodeTypeWithId: String!, $targetTypeWithId: String!) {
    getNode(
      nodeTypeWithId: $nodeTypeWithId
      targetTypeWithId: $targetTypeWithId
    ) {
      nodeTypeWithId
      targetTypeWithId
      playerProfileEditHistory
    }
  }
`;


import { API, graphqlOperation } from 'aws-amplify'

/**
 * Loads data until next tokens are exhausted
 * @param {String} query GraphQL query to invoke
 * @param {Object} input input variables for GraphQL query
 * @param {String} queryName name of GraphQL query being invoked
 */
 export default async function async (query, input, queryName){
    let nextToken = null
    let list = []
    do{
        input.nextToken = nextToken
        let response
        try{
            response = await API.graphql(graphqlOperation(query, input))
        }
        catch(err){
            console.log(err)
            response = err
        }

        list = [ ...list, ...response.data[queryName].items]
        nextToken = response.data[queryName].nextToken
    }while(nextToken)
    return list
  }
import ImportData from './ImportData.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/import-data',
      name: 'ImportData',
      component: ImportData,
      meta: {module: 'importdata'}
    }
];
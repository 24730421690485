import Search from './Search.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/advanced-search',
      name: 'AdvancedSearch',
      component: Search,
      props: true,
      meta: {module: 'search'}
    }
];
export const listChampionshipsByPlayer = /* GraphQL */ `
  query ListNodes(
    $nodeTypeWithId: String
    $targetTypeWithId: ModelStringKeyConditionInput
    $filter: ModelNodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNodes(
      nodeTypeWithId: $nodeTypeWithId
      targetTypeWithId: $targetTypeWithId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        name
        date
        fairwaysHit
        greens
        putts
        rank
        total
		partners
      }
      nextToken
    }
  }
`;

export const getChampionshipRoundTypes = /* GraphQL */ `
  query GetNode($nodeTypeWithId: String!, $targetTypeWithId: String!) {
    getNode(
      nodeTypeWithId: $nodeTypeWithId
      targetTypeWithId: $targetTypeWithId
    ) {
      nodeTypeWithId
      targetTypeWithId
	    roundTypes
    }
  }
`;
export const listPlayersRoundsByChampionship = /* GraphQL */ `
  query ListNodes(
    $nodeTypeWithId: String
    $targetTypeWithId: ModelStringKeyConditionInput
    $filter: ModelNodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNodes(
      nodeTypeWithId: $nodeTypeWithId
      targetTypeWithId: $targetTypeWithId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
		partners
		opponents
        parIn
        parOut
        parTotal
		playerName
        strIn
        strOut
        strTotal
        strIn2
        strOut2
        strTotal2
        ydsIn
        ydsOut
        ydsTotal
		location
		rank
		type
        par1
        par2
        par3
        par4
        par5
        par6
        par7
        par8
        par9
        par10
        par11
        par12
        par13
        par14
        par15
        par16
        par17
        par18
		par19
        par20
        par21
        par22
        par23
        par24
        par25
        par26
        par27
        par28
        par29
        par30
        par31
        par32
        par33
        par34
        par35
        par36
        str1
        str2
        str3
        str4
        str5
        str6
        str7
        str8
        str9
        str10
        str11
        str12
        str13
        str14
        str15
        str16
        str17
        str18
        str19
        str20
        str21
        str22
        str23
        str24
        str25
        str26
        str27
        str28
        str29
        str30
        str31
        str32
        str33
        str34
        str35
        str36
        yds1
        yds2
        yds3
        yds4
        yds5
        yds6
        yds7
        yds8
        yds9
        yds10
        yds11
        yds12
        yds13
        yds14
        yds15
        yds16
        yds17
        yds18
		yds19
        yds20
        yds21
        yds22
        yds23
        yds24
        yds25
        yds26
        yds27
        yds28
        yds29
        yds30
        yds31
        yds32
        yds33
        yds34
        yds35
        yds36
        scr1
        scr2
        scr3
        scr4
        scr5
        scr6
        scr7
        scr8
        scr9
        scr10
        scr11
        scr12
        scr13
        scr14
        scr15
        scr16
        scr17
        scr18
        scr19
        scr20
        scr21
        scr22
        scr23
        scr24
        scr25
        scr26
        scr27
        scr28
        scr29
        scr30
        scr31
        scr32
        scr33
        scr34
        scr35
        scr36
        up1
        up2
        up3
        up4
        up5
        up6
        up7
        up8
        up9
        up10
        up11
        up12
        up13
        up14
        up15
        up16
        up17
        up18
		up19
        up20
        up21
        up22
        up23
        up24
        up25
        up26
        up27
        up28
        up29
        up30
        up31
        up32
        up33
        up34
        up35
        up36		
        round
        position
      }
      nextToken
    }
  }
`;

export const getPlayerName = /* GraphQL */ `
  query GetNode($nodeTypeWithId: String!, $targetTypeWithId: String!) {
    getNode(
      nodeTypeWithId: $nodeTypeWithId
      targetTypeWithId: $targetTypeWithId
    ) {
      nodeTypeWithId
      targetTypeWithId
      name
	  nameFirst
	  nameLast
      date
      country
      city
	  birthCity
	  birthState
	  birthCountry
      championshipsPlayed
      championshipsWon
      img
      updatedAt
      about
      awards
      citizenshipCountries
      company
      countryCode
      email
      familyMembers
      graduationYear
      holeInOne
      holeInOneDetails
      homeClubCity
      homeClubCountry
      homeClubState
      influencedBy
      instagram
      interests
      juniorParticipant
      juniorProgramName
      leftHanded
      lowestScore
      maidenName
      majorNames
      nickname
      occupation
      partner
      recordOther
      reinstatedAmateur
      reinstatedAmateurDate
      schoolGolfTeamParticipant
      schoolGolfTeamsList
      schoolName
      startAge
      state
      twitter
      usgaFamilyMembers
      playerProfileUpdateAt
      playerProfileConfirmedAt
      playerProfileEditHistory
      usgaChampionshipRecord
    }
  }
`;

export const getPlayersForSelect = /* GraphQL */ `
  query NodeByTargetType(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByTargetType(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        type
        city
        country
        name
      }
      nextToken
    }
  }
`;

export const emailByPlayer = /* GraphQL */ `
  query EmailByPlayer(
	$playerId: String!
	$createdAt: ModelStringKeyConditionInput
	$sortDirection: ModelSortDirection
	$filter: ModelemailFilterInput
	$limit: Int
	$nextToken: String
  ) {
	emailByPlayer(
	  playerId: $playerId
	  createdAt: $createdAt
	  sortDirection: $sortDirection
	  filter: $filter
	  limit: $limit
	  nextToken: $nextToken
	) {
	  items {
		id
		template
		status
		sentAt
		to
		unsubscribes
		spamReports
		clicks
		opens
		playerId
		playerName
		championshipName
		registrationUrl
		createdAt
		updatedAt
		__typename
	  }
	  nextToken
	  __typename
	}
  }
`;


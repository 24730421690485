<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" static class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false" :open="sidebarOpen">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-100">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <SidebarContent
				@on-navigate="sidebarOpen = false"
				:navigation="navigation">
			</SidebarContent>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden bg-usgaLightGray md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <SidebarContent :navigation="navigation" ></SidebarContent>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden" >
      <div class="relative z-10 flex-shrink-0 h-16 flex bg-white shadow ribbon">
          <div class="flex-shrink-0 flex items-center">
            <button type="button" class="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-coolGray-500 md:hidden" @click="sidebarOpen = true">
                <span class="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class=" transition-all duration-300 my-auto items-center px-2 sm:px-6 text-2xl font-bold text-gray-900 sm:text-3xl sm:truncate opacity-0" id="ribbon-heading">
              {{ribbonHeadingText}}
          </div>
        <div class="flex-1 flex items-center justify-end pl-2 pr-6 lg:ml-6 lg:justify-end">
          <div class="ml-4 relative flex-shrink-0">
            <!-- Profile dropdown -->
            <Menu as="div" class="ml-3 relative">
              <div>
                <MenuButton class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-usgaRed">
                  <span class="sr-only">Open user menu</span>
                    <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-usgaDarkGray">
                        <span class="uppercase font-medium leading-none text-white">{{initial}}</span>
                    </span>
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="origin-top-right absolute right-0 mt-2 min-w-min rounded-sm shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem>
						<div>
							<div class="px-4 pt-2 text-usgaBlue font-bold ">{{userName}}</div>
							<div class="px-4 pt-1 pb-3 text-usgaBlue text-base border-b">{{userEmail}}</div>
						</div>
                  </MenuItem>
                  <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                    <a :href="item.href" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">{{ item.name }}</a>
                  </MenuItem>
                  <MenuItem>
                    <div class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100" @click="signOut">Sign Out</div>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <main class="flex-1 relative overflow-x-hidden overflow-y-auto focus:outline-none bg-white" id="content">
            <!-- Main content -->
            <div class="min-h-full">
                <slot></slot>
            </div>
      </main>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue'
import { useStore} from 'vuex'
import SidebarContent from '@/components/Sidebar/SidebarContent.vue'
import EventBus from '@/event'
import  { Auth } from 'aws-amplify'

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  Bars3BottomLeftIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'

const userNavigation = [
]

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    Bars3BottomLeftIcon,
    XMarkIcon,
    SidebarContent
  },
  setup() {
    const ribbonHeadingText = ref('')
    const contentRef = ref(null)
    const scrollingDisabled = ref(false)    

    const store = useStore()
    const userEmail = store.state.user.email?? ""
	const userName = store.state.user.name?? ""
    const initial = userName.substring(0,1)
    async function handleScroll(){
        let pageHeading = document.getElementById("page-heading")
        let ribbonHeading = document.getElementById("ribbon-heading")
        let content = document.getElementById("content")
        let totalHeight = 0
        let ribbons = document.querySelectorAll('.z-10.shadow');

        ribbons?.forEach((ribbon) => {
            totalHeight+= ribbon.clientHeight;
        })
        totalHeight += pageHeading?.clientHeight

        // infinite scroll
        if(content.clientHeight + content.scrollTop >= content.scrollHeight && !scrollingDisabled.value){
            scrollingDisabled.value = true
            EventBus.trigger('infinite-scroll', null)
        }

        // add ribbon heading
        if(content.scrollTop >= totalHeight) {
            ribbonHeadingText.value = pageHeading.innerHTML
            ribbonHeading.classList.remove("opacity-0")
        }
        else {
            ribbonHeading.classList.add("opacity-0")
        }
    }

    onMounted(() => {
        document.addEventListener('scroll', () => {
            handleScroll()
        }, {
            capture: true,
            passive: true
        })

        EventBus.on('infinite-scroll-end', () =>{
             scrollingDisabled.value = false
        })
    })
        
    onBeforeUnmount(() => {
        document.removeEventListener('scroll', handleScroll)
        EventBus.off('infinite-scroll-end', null)
    })

    const sidebarOpen = ref(false)

    //Load file path of all modules named Nav.vue
    const moduleContext = require.context('@/modules', true, /Nav.ts$/i)

    //Import each component
    const modules = moduleContext.keys()
        .map(moduleContext)
        .map(m => m.nav) 

    //Sort them based on the position data prop
    const navigation = modules.filter(m =>{return m.show}).sort((a,b)=>{
        let aPos = a.position
        let bPos = b.position
        if(aPos < bPos){
            return -1
        }else if(aPos > bPos){
            return 1
        }else{
            return 0
        }
    })

    async function signOut() {
        try {
            // clear user in store
            store.commit('setUser', {})
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return {
		navigation,
		userNavigation,
		sidebarOpen,
		ribbonHeadingText,
		contentRef,
		handleScroll,
		signOut,
		userEmail,
		initial,
		userName
    }
  }
})
</script>
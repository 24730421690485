export const signupByArchived = /* GraphQL */ `
  query SignupByArchived(
    $archived: Int!
    $createdAtName: ModelsignupByArchivedCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelsignupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    signupByArchived(
      archived: $archived
      createdAtName: $createdAtName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        company
        title
        archived
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
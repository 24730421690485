/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import { store } from './store/index'
import { router } from './router/index'
import './assets/css/index.css'
import Amplify from 'aws-amplify'
import  { Auth } from 'aws-amplify'
import aws_exports from './aws-exports.js'

// import global components
import TwButton from '@/components/Buttons/TwButton.vue'
import TwDropdown from '@/components/Dropdowns/TwDropdown.vue'
import TwMultiselect from '@/components/Dropdowns/TwMultiselect.vue'
import Input from '@/components/InputGroups/Input.vue'
import Table from '@/components/Tables/Table.vue'
import Heading from '@/components/Headings/Heading.vue'
import Scorecard from '@/components/Scorecards/Scorecard.vue'
import Avatar from '@/components/Avatars/Avatar.vue'
import BracketEntry from '@/components/Brackets/BracketEntry.vue'
import BracketMatchup from '@/components/Brackets/BracketMatchup.vue'
import Bracket from '@/components/Brackets/Bracket.vue'
import Loading from '@/components/Loading/Loading.vue'
import FullScreenLoading from '@/components/Loading/FullScreenLoading.vue'
import SelectMenu from '@/components/SelectMenus/SelectMenu.vue'
import Notification from '@/components/Notifications/Notification.vue'
import SearchField from '@/components/SearchFields/SearchField.vue'
import History from '@/components/History/History.vue'

import _TwTable from '@/components/_Tables/TwTable.vue'
import TwTableColumn from '@/components/_Tables/TwTableColumn.vue'
import TwTableDropdown from '@/components/_Tables/TwTableDropdown.vue'
import TwTableHeader from '@/components/_Tables/TwTableHeader.vue'
import TwTableRow from '@/components/_Tables/TwTableRow.vue'



// import icons from Tailwind's Hero Icons
import {
	UserIcon,
	CalendarIcon,
	HomeIcon,
	EllipsisVerticalIcon,
	DocumentChartBarIcon,
	ArrowsRightLeftIcon,
	CheckCircleIcon as SolidCheckCircleIcon,
	FlagIcon as SolidFlagIcon,
	MapPinIcon as SolidLocationMarkerIcon,
	EllipsisHorizontalIcon as SolidDotsHorizontalIcon,
	PencilIcon,
	UserCircleIcon,
	MagnifyingGlassIcon,
	ChevronDownIcon as SolidChevronDownIcon,
	XMarkIcon as SolidXIcon,
	CheckIcon,
	ChevronRightIcon as SolidChevronRightIcon
} from '@heroicons/vue/24/solid'
import {
	InboxArrowDownIcon,
	CogIcon,
	UserPlusIcon,
	ClipboardDocumentListIcon,
	ArrowUpTrayIcon,
	ExclamationCircleIcon,
	ArrowSmallUpIcon,
	ArrowSmallDownIcon,
	ChevronRightIcon,
	ChevronUpIcon,
	EllipsisHorizontalIcon,
	ArrowUturnLeftIcon,
	ChevronLeftIcon,
	DocumentPlusIcon,
	XMarkIcon,
	ArrowSmallRightIcon,
	ArrowSmallLeftIcon,
	CheckCircleIcon,
	XCircleIcon,
	UsersIcon,
	FlagIcon,
	MapPinIcon,
	Square2StackIcon,
	PlusSmallIcon,
	ChevronDownIcon,
	DocumentArrowDownIcon,
	ArrowRightCircleIcon,
	ArrowLeftCircleIcon,
} from '@heroicons/vue/24/outline'

import {
	ChevronDownIcon as ChevronDownIconMini,
	FunnelIcon as FunnelIconMini
} from '@heroicons/vue/20/solid'

Amplify.configure(aws_exports)

Amplify.configure({
	aws_appsync_graphqlEndpoint: process.env.VUE_APP_GRAPHQL_DOMAIN
});


Auth.configure({
    region: "us-east-1",
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID,
    oauth: {
        domain: process.env.VUE_APP_OAUTH_DOMAIN,
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: "code"
    }
});

const app = createApp(App)

// set up global filters
app.config.globalProperties.$filters = {
	date(d: String) {
	  	if(!d) return ''
		let test = new Date(d.replace('-','/'))
		if(isNaN(test.getTime())) return 'Invalid Date'
		return test.toLocaleDateString()
	}
}

// Register icons globally
app.component("ExclamationCircleIcon", ExclamationCircleIcon)
app.component("SolidXIcon", SolidXIcon)
app.component("CheckIcon", CheckIcon)
app.component('ChevronDownIconMini', ChevronDownIconMini)
app.component('FunnelIconMini', FunnelIconMini)
app.component("SolidChevronDownIcon", SolidChevronDownIcon)
app.component("SolidChevronRightIcon", SolidChevronRightIcon)
app.component("InboxInIcon", InboxArrowDownIcon)
app.component("CogIcon", CogIcon)
app.component("UserAddIcon", UserPlusIcon)
app.component("ClipboardListIcon", ClipboardDocumentListIcon)
app.component("UploadIcon", ArrowUpTrayIcon)
app.component("DotsVerticalIcon", EllipsisVerticalIcon)
app.component('HomeIcon', HomeIcon)
app.component('SearchIcon', MagnifyingGlassIcon)
app.component('ArrowSmUpIcon', ArrowSmallUpIcon)
app.component('ArrowSmDownIcon', ArrowSmallDownIcon)
app.component('ArrowSmRightIcon', ArrowSmallRightIcon)
app.component('ArrowSmLeftIcon', ArrowSmallLeftIcon)
app.component('SolidCheckCircleIcon', SolidCheckCircleIcon)
app.component('ChevronRightIcon', ChevronRightIcon)
app.component('CheckCircleIcon', CheckCircleIcon)
app.component('DotsHorizontalIcon', EllipsisHorizontalIcon)
app.component('ReplyIcon', ArrowUturnLeftIcon)
app.component('ChevronLeftIcon', ChevronLeftIcon)
app.component('CalendarIcon', CalendarIcon)
app.component('DocumentReportIcon', DocumentChartBarIcon)
app.component('DocumentAddIcon', DocumentPlusIcon)
app.component('XIcon', XMarkIcon)
app.component('SwitchHorizontalIcon', ArrowsRightLeftIcon)
app.component('XCircleIcon', XCircleIcon)
app.component('UsersIcon', UsersIcon)
app.component('FlagIcon', FlagIcon)
app.component('LocationMarkerIcon', MapPinIcon)
app.component('DuplicateIcon', Square2StackIcon)
app.component('PlusSmIcon', PlusSmallIcon)
app.component('ChevronDownIcon', ChevronDownIcon)
app.component('ChevronUpIcon', ChevronUpIcon)
app.component('DocumentDownloadIcon', DocumentArrowDownIcon)
app.component('UserIcon', UserIcon)
app.component('SolidFlagIcon', SolidFlagIcon)
app.component('SolidLocationMarkerIcon', SolidLocationMarkerIcon)
app.component('SolidDotsHorizontalIcon', SolidDotsHorizontalIcon)
app.component('PencilIcon', PencilIcon)
app.component('UserCircleIcon', UserCircleIcon)
app.component('ArrowCircleRightIcon', ArrowRightCircleIcon)
app.component('ArrowCircleLeftIcon', ArrowLeftCircleIcon)
// In another component use, <HomeIcon/> directly in the template

// Register global components
app.component("tw-input", Input)
app.component("tw-button", TwButton)
app.component("tw-dropdown", TwDropdown)
app.component("tw-multiselect", TwMultiselect)
app.component("tw-table", Table)
app.component("tw-heading", Heading)
app.component("tw-scorecard", Scorecard)
app.component("tw-avatar", Avatar)
app.component("tw-bracketentry", BracketEntry)
app.component("tw-bracketmatchup", BracketMatchup)
app.component("tw-bracket", Bracket)
app.component("tw-loading", Loading)
app.component("tw-full-loading", FullScreenLoading)
app.component("tw-selectmenu", SelectMenu)
app.component("tw-notification", Notification)
app.component("tw-searchfield", SearchField)
app.component("tw-history", History)

app.component("new-tw-table", _TwTable)
app.component("tw-table-column", TwTableColumn)
app.component("tw-table-dropdown", TwTableDropdown)
app.component("tw-table-header", TwTableHeader)
app.component("tw-table-row", TwTableRow)

app.use(store)
app.use(router)
router.isReady().then(() => app.mount('#app'))
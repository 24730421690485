export const updatePlayerProfileInfo = /* GraphQL */ `
  mutation UpdateNode(
    $input: UpdateNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    updateNode(input: $input, condition: $condition) {
      nodeTypeWithId
      targetTypeWithId
    }
  }
`;
export const updateEntryInfo = /* GraphQL */ `
  mutation UpdateNode(
    $input: UpdateNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    updateNode(input: $input, condition: $condition) {
        name
        position
        strTotal
        fairwaysHit
        greens
        putts
    }
  }
`;
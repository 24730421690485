import ImportHistory from './ImportHistory.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/import-history',
      name: 'ImportHistory',
      component: ImportHistory,
      meta: {module: 'importhistory'},
      props: true
    }
];
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["button", { 'rounded-full': $props.rounded }])
  }, [
    (_ctx.$slots.icon)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["btn-icon", {'mx-0': !_ctx.$slots.default}]),
          "aria-hidden": "true"
        }, [
          _renderSlot(_ctx.$slots, "icon")
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots.icon_right)
      ? (_openBlock(), _createElementBlock("span", {
          key: 2,
          class: _normalizeClass(["btn-icon btn-icon-right", {'mx-0': !_ctx.$slots.default}]),
          "aria-hidden": "true"
        }, [
          _renderSlot(_ctx.$slots, "icon_right")
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}
import PlayerDetail from './PlayerDetail.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/players/player-detail/:id',
      name: 'Player Detail',
      component: PlayerDetail,
      meta: {module: 'players'},
      props: true
    }
];
<template>
    <div v-if="!loading" class="md:flex md:items-center md:justify-between px-6">
        <div class="min-w-0 py-2">
            <div class="flex align-top">
                <slot></slot>
                <div>
                    <div class="flex items-center">
                        <h2 id="page-heading" class="text-2xl font-bold text-gray-900 sm:text-3xl sm:truncate">{{ playerInfo.name || '' }}</h2>
                        <span class="ml-2">{{flag(playerInfo.countryCode)}}</span>
                    </div>
                    <span v-if="playerInfo">
                        <h3 v-if="playerInfo.city || playerInfo.country" class="text-sm font-medium text-gray-600 sm:truncate">{{ playerInfo.city }}{{', ' + playerInfo.state }}{{', ' + playerInfo.country }}</h3>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="animate-pulse px-6 py-2 max-w-sm w-full">
        <div class="grid grid-cols-3 gap-4 mt-5 mb-2">
            <div v-for="i in 3" :key="i" class="h-2 bg-usgaGray rounded col-span-1"></div>
        </div>
        <div class="flex space-x-4 mt-5">
            <div class="rounded-full bg-usgaGray h-14 w-14"></div>
            <div class="flex-1 space-y-6 py-4">
                <div class="space-y-4">
                    <div class="grid grid-cols-4 gap-4">
                        <div v-for="i in 2" :key="i" class="h-2 bg-usgaGray rounded col-span-2"></div>
                    </div>
                    <div class="h-2 bg-usgaGray rounded w-3/4"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { defineProps} from 'vue'

    var flag = require('emoji-flag')
	// eslint-disable-next-line no-unused-vars
    const props = defineProps([
        'playerInfo',
        'loading'
    ])
</script>
import { BookmarkSquareIcon } from '@heroicons/vue/24/outline'
import { Nav } from '@/types' // must be imported since file contains an export statement

export const nav: Nav = { 
    name: 'Saved Searches', 
    to: {name: 'SavedSearches'}, 
    icon: BookmarkSquareIcon,
    position: 4,
    show: true,
    module: 'savedSearches'
}
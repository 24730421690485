export const listChampionshipsByVenue = /* GraphQL */ `
  query ListNodes(
    $nodeTypeWithId: String
    $targetTypeWithId: ModelStringKeyConditionInput
    $filter: ModelNodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNodes(
      nodeTypeWithId: $nodeTypeWithId
      targetTypeWithId: $targetTypeWithId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        name
		dates
        winner
      }
      nextToken
    }
  }
`;
export const getVenueName = /* GraphQL */ `
  query GetNode($nodeTypeWithId: String!, $targetTypeWithId: String!) {
    getNode(
      nodeTypeWithId: $nodeTypeWithId
      targetTypeWithId: $targetTypeWithId
    ) {
      nodeTypeWithId
      targetTypeWithId
      name
      city
      state
      country
    }
  }
`;
<template>
    <div v-show="scorecardLoaded" class="px-4 pb-4 bg-usgaLightGray shadow-inner">
        <div class="w-full max-w-7xl lg:m-auto">
			<div class="pb-4 flex justify-between align-middle">
				<div class="sm:hidden">
					<label for="tabs" class="sr-only">Select a tab</label>
					<select id="tabs" name="tabs" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-usgaRed focus:border-usgaRed sm:text-sm rounded-md">
						<option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
					</select>
				</div>
				<div class="hidden sm:block">
					<div class="">
						<nav class="-mb-px flex space-x-8" aria-label="Tabs">
						<a @click="changeRound(i)" v-for="(tab, i) in tabs" :key="tab.name" class="cursor-pointer" :class="[tab.current ? 'border-usgaRed text-usgaRed' : 'border-transparent text-usgaDarkGray hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap pt-4 pb-1 px-1 border-b-2 font-medium text-sm']" :aria-current="tab.current ? 'page' : undefined">
							{{ tab.name }}
						</a>
						</nav>
					</div>
					<div class="link text-sm mt-4" v-for="venue in roundData[selectedRound]?.location" :key="venue" >
						<router-link :to="{name: 'Venue Detail', params: {id: JSON.parse(venue).id}}">{{ JSON.parse(venue).name }} -></router-link>
					</div>
				</div>
				<div v-if="context !== 'player'" class="pt-4">
					<span 
						v-for="(player, i) in playersList" 
						:key="i"
						@click="goToRoute(player.id)"
						class="ml-4 text-usgaRed text-sm hover:text-usgaDarkRed hover:underline cursor-pointer font-medium">
						View {{ playersList.length>1 ? player.name : viewRelatedText }}
						<span aria-hidden="true"> &rarr;</span>
					</span>
				</div>
				<div v-else-if="context == 'player'" class="pt-4">
					<span 
						@click="goToRoute(null)"
						class="ml-4 text-usgaRed text-sm hover:text-usgaDarkRed hover:underline cursor-pointer font-medium">
						View {{ viewRelatedText }}
						<span aria-hidden="true"> &rarr;</span>
					</span>
				</div>
			</div>
			<div class="w-full max-w-7xl lg:m-auto overflow-x-scroll">
				<table class="table-auto min-w-full divide-y divide-gray-300"> 
					<tbody class="divide-y divide-usgaLightGray bg-white">
						<tr v-for="(stat, i) in stats" :key="i">
							<td 
								v-if="stat.rowSpan != 0"
								:rowSpan="stat.rowSpan"
								colspan="1" 
								class=" bg-usgaGray p-2 text-left text-sm font-semibold uppercase border-usgaLightGray w-52"
								:class="{'border-b': stat.fieldPrefix != 'up' && stat.fieldPrefix != 'o0str'}">
								{{ stat.label }}
							</td>
							<td  
								colspan="1" 
								v-for="(col, i) in holeColumns" 
								:key="i" 
								class="w-auto uppercase border-2 text-sm border-usgaLightGray p-2 pt-1 text-center h-8">
								<span v-if="stat.fieldPrefix != 'str'">
									{{ roundData[selectedRound][stat.fieldPrefix + holeColumns[i]]}} 
								</span>
								<span v-else>
									<span 
										v-show="!editScores"
										:class="{
											'bogey-border': ((defaultScores[selectedRound]['scr' + col] == 1) && stat.label === 'Round'), 
											'bogey-border outline': ((defaultScores[selectedRound]['scr' + col] > 1) && stat.label === 'Round'), 
											'birdie-border': ((defaultScores[selectedRound]['scr' + col] == -1) && stat.label === 'Round'),
											'birdie-border outline': ((defaultScores[selectedRound]['scr' + col] < -1) && stat.label === 'Round')
										}">
										{{ updatedScores[selectedRound][stat.fieldPrefix + holeColumns[i]]}}
									</span>
									<tw-input v-show="editScores" v-model.number="updatedScores[selectedRound][stat.fieldPrefix + holeColumns[i]]" class="small h-6 text-center rounded-sm px-0" />  
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
            <div class="mt-4 flex justify-between">
                <div v-if="!roundData[selectedRound]?.type.includes('m')">
                    <span class="birdie-border outline mx-2"><span class="invisible text-sm">0</span></span> Eagle or better 
                    <span class="birdie-border mx-2"><span class="invisible text-sm">0</span></span> Birdie 
                    <span class="bogey-border mx-2"><span class="invisible text-sm">0</span></span> Bogey 
                    <span class="bogey-border outline mx-2"><span class="invisible text-sm">0</span></span> Bogey or worse 
                </div>
				<div v-else>
					<span></span> 
				</div>
                <div v-if="canEdit">
                    <tw-button @click="editScores = true" v-show="!editScores" class="button btn-base btn-red" :rounded="true">Edit Scores</tw-button>
                    <tw-button @click="editSave" v-show="editScores" class="w-24 button btn-base btn-red mr-4" :rounded="true">Save</tw-button>
                    <tw-button @click="editCancel" v-show="editScores" class="w-24 button btn-base btn-white" :rounded="true">Cancel</tw-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    // #region imports
    import { defineProps, ref, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { listPlayersRoundsByChampionship } from '@/modules/players/playerDetail/queries'
    import { updateRoundScores } from './mutations'
    import api from "@/utilities/api"
    // #endregion

    // #region setup
    const props = defineProps([
        'context',
        'playerId',
        'targetId',
        'canEdit',
		'roundTypes'
    ])

    const router = useRouter()

    // Set up row headers and field prefixes to display data score data from query
    const stats = ref([
        {fieldPrefix: 'hole', label: 'Hole', rowSpan: 1},
        {fieldPrefix: 'par',  label: 'Par', rowSpan: 1},
        {fieldPrefix: 'yds',  label: 'Yards', rowSpan: 1},
        {fieldPrefix: 'str',  label: 'Round', rowSpan: 1},
    ])

    //Used to append to field prefixes to get info from query
    const holeColumns = ref([])
    // #endregion
	let teamEventRounds = ['r32', 'r16', 'quarter', 'semi', 'final']
	let matchPlayRounds = ['r64', ...teamEventRounds]
    // #region load scorecard data
    const scorecardLoaded = ref(false)
    const roundData = ref([])
    const updatedScores = ref([])
    const defaultScores = ref([])
    const tabs = ref([])
	let additionalRows = [];
	const playersList = ref({});

    (async () => {
        // determine ids from context of where the componenet is being viewed
        try {
			for(const roundType of props.roundTypes) {

				const input = {
					nodeTypeWithId: props.playerId,
					targetTypeWithId:{
						beginsWith: `${roundType}-${props.targetId}`
					},
					sortDirection: 'ASC'
				}
				let res = await api(listPlayersRoundsByChampionship, input)
				res = res.data.listNodes.items.sort((a, b) => {
					return a.position - b.position
				})
				res = res.map((item) => {
					// eslint-disable-next-line no-unused-vars
					let cleanedItem = Object.fromEntries(Object.entries(item).filter(([_, v]) => v != null))
					if(roundType === 'rtm' || roundType === 'rm') {
						return {
							...cleanedItem, 
							// add header values to display in table
							hole1: '1', hole2: '2', hole3: '3', hole4: '4', hole5: '5', hole6: '6', hole7: '7', hole8: '8', hole9: '9', hole10: '10', hole11: '11', hole12: '12', hole13: '13', hole14: '14', hole15: '15', hole16: '16', hole17: '17', hole18: '18', hole19: '19', hole20: '20', hole21: '21', hole22: '22', hole23: '23', hole24: '24', hole25: '25', hole26: '26', hole27: '27', hole28: '28', hole29: '29', hole30: '30', hole31: '31', hole32: '32', hole33: '33', hole34: '34', hole35: '35', hole36: '36'
						}
					}
					else {
						return {
							...cleanedItem, 
							// add header values to display in table
							hole1: '1', hole2: '2', hole3: '3', hole4: '4', hole5: '5', hole6: '6', hole7: '7', hole8: '8', hole9: '9', hole10: '10', hole11: '11', hole12: '12', hole13: '13', hole14: '14', hole15: '15', hole16: '16', hole17: '17', hole18: '18', holeOut: 'Out', holeIn: 'In', hole19: '19', hole20: '20', hole21: '21', hole22: '22', hole23: '23', hole24: '24', hole25: '25', hole26: '26', hole27: '27', hole28: '28', hole29: '29', hole30: '30', hole31: '31', hole32: '32', hole33: '33', hole34: '34', hole35: '35', hole36: '36', holeOut2: 'Out', holeIn2: 'In', holeTotal: 'Total', holeTotal2: 'Total',
						}
					}
				})

				// also get indivdual player stats if round type is stroke play round of team event
				if(roundType === 'rts'){
					additionalRows = []
					// res[0].partners.forEach(async (playerId, i) => {
					for(const [i, playerId] of res[0]?.partners?.entries() || []) {
						const input = {
							nodeTypeWithId: playerId,
							targetTypeWithId:{
								beginsWith: `${roundType}-${props.targetId}`
							},
							sortDirection: 'ASC'
						}
						let playerRoundData = await api(listPlayersRoundsByChampionship, input)
						playerRoundData = playerRoundData.data.listNodes.items

						// update stats object to include individual player data
						additionalRows.push({fieldPrefix: 'p' + i + 'str', label: playerRoundData[0].playerName, rowSpan: 1}) 

						playerRoundData.forEach((round, j) => {
							// eslint-disable-next-line no-unused-vars
							let cleanedRound = Object.fromEntries(Object.entries(round).filter(([_, v]) => v != null))
							// add invidual player data to round data
							for (const [key, value] of Object.entries(cleanedRound)) {
								res[j]['p' + i + key] = value
							}
							
						})
					}
					for(let round of res){
						round.additionalRows = additionalRows
					}
				}

				// also get oppponent stats if round type is match play
				else if(roundType === 'rtm') {
					for(let r=0; r<res.length; r++) {
						additionalRows = []
						for(const [i, playerId] of res[r].opponents.entries()) {
							const input = {
								nodeTypeWithId: 'pp-' + playerId,
								targetTypeWithId:{
									beginsWith: `${roundType}-${props.targetId}${teamEventRounds[res[r].position - 4]}`
								},
								sortDirection: 'ASC'
							}
							let playerRoundData = await api(listPlayersRoundsByChampionship, input)
							playerRoundData = playerRoundData.data.listNodes.items
	
							// update stats object to include individual player data
							additionalRows.push({fieldPrefix: 'o' + i + 'str', label: playerRoundData[0].playerName, rowSpan: 2}) 
							additionalRows.push({fieldPrefix: 'o0up', label: '', rowSpan: 0})
							// eslint-disable-next-line no-unused-vars
							let cleanedRound = Object.fromEntries(Object.entries(playerRoundData[0]).filter(([_, v]) => v != null))
							// add invidual player data to round data
							for (const [key, value] of Object.entries(cleanedRound)) {
								res[r]['o' + i + key] = value
							}	
						}
						res[r].additionalRows = additionalRows
					}

					// add in partner data if only match play rounds have been imported, for navigating to player detail record
					if(res[0]?.type === 'rtm') {
						for(const [i, playerId] of res[0]?.partners?.entries() || []) {
							res[0]['p' + i + 'nodeTypeWithId'] = playerId
							res[0]['p' + i + 'playerName'] = res[0].playerName.split('/')[i].trim()
						}
					}
				}

				else if(roundType === 'rm') {
					for(let r=0; r<res.length; r++) {
						additionalRows = []
						for(const [i, playerId] of res[r].opponents.entries()) {
							const input = {
								nodeTypeWithId: playerId,
								targetTypeWithId:{
									beginsWith: `${roundType}-${props.targetId}${matchPlayRounds[res[r].position - 3]}`
								},
								sortDirection: 'ASC'
							}
							let playerRoundData = await api(listPlayersRoundsByChampionship, input)
							playerRoundData = playerRoundData.data.listNodes.items

							// update stats object to include individual player data
							additionalRows.push({fieldPrefix: 'o' + i + 'str', label: playerRoundData[0].playerName, rowSpan: 2}) 
							additionalRows.push({fieldPrefix: 'o0up', label: '', rowSpan: 0})
							// eslint-disable-next-line no-unused-vars
							let cleanedRound = Object.fromEntries(Object.entries(playerRoundData[0]).filter(([_, v]) => v != null))
							// add invidual player data to round data
							for (const [key, value] of Object.entries(cleanedRound)) {
								res[r]['o' + i + key] = value
							}
						}
						res[r].additionalRows = additionalRows
					}
				}

				for(let round of res){
					roundData.value.push(round)
				}
			}

			// Set up round tabs
            tabs.value = [] // clear out tabs between viewing different scorecards
            roundData.value.forEach(round => {
				let tabName = round.round + " (" + round.rank + ")"
                tabs.value.push({name: tabName , current: false})
            })

            if(tabs.value[0]) tabs.value[0].current = true 
            scorecardLoaded.value = true

			// add addtional rows to table if needed for round
			if(roundData.value[0]?.additionalRows?.length){
				if(roundData.value[0].type === 'rtm' || roundData.value[0].type === 'rm') {
					//rename 'Round' label
					stats.value = [
						{fieldPrefix: 'hole', label: 'Hole', rowSpan: 1},
						{fieldPrefix: 'par',  label: 'Par', rowSpan: 1},
						{fieldPrefix: 'yds',  label: 'Yards', rowSpan: 1},
						{fieldPrefix: 'up',  label: roundData.value[0].playerName, rowSpan: 2},
						{fieldPrefix: 'str', label: '', rowSpan: 0}]
				}
				// update stats object to include individual player data
				stats.value = [...stats.value, ...roundData.value[0].additionalRows]
			}

			// set number of columns
			holeColumns.value = []

			// determine what columns to display
			generateScorecardColumns(0)

            // Set up updated scores to allow user to edit
            defaultScores.value = roundData.value.map(obj => {
                var temp = {}
                for (var i = 1; i < 37; i++){
                    temp['str'+i] = obj['str'+i]
                    temp['scr'+i] = obj['scr'+i]
                }
                temp['strTotal'] = obj['strTotal']
                temp['strTotal2'] = obj['strTotal2']
                temp['strIn'] = obj['strIn']
                temp['strIn2'] = obj['strIn2']
                temp['strOut'] = obj['strOut']
                temp['strOut2'] = obj['strOut2']
                return temp
            })
        
            updatedScores.value = defaultScores.value.map(obj => ({
                ...obj
            }))
		createPlayersList();
        }
        catch(e) {
            console.log(e)
        }
    })()
    // #endregion

    // #region scorecard interactions
    const selectedRound = ref(0)

    /**
     * Changes the round being viewed of the current scorecard
     * @function changeRound
     * @param {Number} i - index of round tab array
     */
    function changeRound(i){
        tabs.value = tabs.value.map((tab) => ({
            ...tab,
            current: false,
        }))
        tabs.value[i].current = true
        selectedRound.value = i

		// determine what columns to display
		generateScorecardColumns(i)
		
		// add addtional rows to table if needed for round
		stats.value = [
			{fieldPrefix: 'hole', label: 'Hole', rowSpan: 1},
			{fieldPrefix: 'par',  label: 'Par', rowSpan: 1},
			{fieldPrefix: 'yds',  label: 'Yards', rowSpan: 1},
			{fieldPrefix: 'str',  label: 'Round', rowSpan: 1}
		]
		if(roundData.value[i]?.additionalRows?.length){
			if(roundData.value[i].type === 'rtm' || roundData.value[i].type === 'rm') {
				//rename 'Round' label
				stats.value = [
					{fieldPrefix: 'hole', label: 'Hole', rowSpan: 1},
					{fieldPrefix: 'par',  label: 'Par', rowSpan: 1},
					{fieldPrefix: 'yds',  label: 'Yards', rowSpan: 1},
					{fieldPrefix: 'up',  label: roundData.value[i].playerName, rowSpan: 2},
					{fieldPrefix: 'str', label: '', rowSpan: 0}]
			}
			stats.value = [...stats.value, ...roundData.value[i].additionalRows]
		}

        editCancel()
    }

	function generateScorecardColumns(i){
		holeColumns.value = []
		let tempHoleColumns = []
		const re = /^str([0-9]{1,2}|In|Out|Total|In2|Out2|Total2)$/
		if(roundData.value.length) {
			for (let [key,value] of Object.entries(roundData.value[i])) {
				let arr = key.match(re)
				if(arr && ( value || (Number(arr[1]) < 19))) { // include 18 holes by default
					let position = null
					if(arr[1] === 'Out'){
						position = '9a'
					}
					else if(arr[1] === 'In'){
						position = '18a'
					}
					else if(arr[1] === 'Total'){
						position = '18b'
					}
					else if(arr[1] === 'Out2'){
						position = '27a'
					}
					else if(arr[1] === 'In2'){
						position = '36a'
					}
					else if(arr[1] === 'Total2'){
						position = '36b'
					}
					else{
						position = Number(arr[1])
					}
					tempHoleColumns.push({value: arr[1], position: position})
				}
			}
		}
		// put them in the right order
		tempHoleColumns.sort(function(a, b){return ('' + a.position).localeCompare(b.position, undefined,{numeric: true})})
		holeColumns.value = tempHoleColumns.map(hole => {return hole.value})
	}

    /**
     * Router link text to show in the scorecard
     * @yields {String}
     */
    
    
    const viewRelatedText = computed(() => {
        if(props.context == 'player') return 'Championship'
        else return 'Player'
    })

    /**
	* Creates a list of players.
     */
    function createPlayersList(){
		playersList.value = [];
		if(roundData.value.length === 0) {
			// entry is created before round data has been imported (from player profile sync)
			let id = props.context == 'player' ? 'c-' + props.targetId : props.playerId
			// format player id if team event
			if(props.roundTypes.includes('rts') && props.context != 'player') {
				id = props.playerId.substring(1)
			}
			playersList.value.push({"id": id})
		}
		else if(props.playerId.startsWith('pp')) {
			// entry has two players
			playersList.value.push({"name": roundData.value[0].p0playerName, "id": roundData.value[0].p0nodeTypeWithId});
			playersList.value.push({"name": roundData.value[0].p1playerName, "id": roundData.value[0].p1nodeTypeWithId});
		}
		else {
			// entry has one player
			playersList.value.push({"id": roundData.value[0].nodeTypeWithId})
		}
    }
    

    /**
     * Navigates to the championship detail for the current scorecard
     * @function goToChampionshipRoute
     */
    function goToRoute(routeID){
		let name = props.context == 'player' ? 'Championship Detail' : 'Player Detail'
		let id = props.context == 'player' ? 'c-' + props.targetId : routeID
		router.push({name: name, params:{id: id}})
    }
    // #endregion
        
    // #region scorecard editing
    const editScores = ref(false)

    /**
     * Cancels the current edit operation
     * @function editCancel
     */
    function editCancel() {
        //Reset updated values to current values of info
        updatedScores.value = defaultScores.value.map(obj => ({
            ...obj
        }))
        editScores.value = false
    }

    /**
     * Saves the current edits to the scorecard
     * @function editSave
     */
    async function editSave() {
        const tempInput = {
            nodeTypeWithId: roundData.value[selectedRound.value]['nodeTypeWithId'],
            targetTypeWithId: roundData.value[selectedRound.value]['targetTypeWithId'],
        }

        for (var i = 1; i <= 36; i++) {
            updatedScores.value[selectedRound.value]['scr'+i] = updatedScores.value[selectedRound.value]['str'+i] - roundData.value[selectedRound.value]['par'+i]
        }
		//re-total scores for stroke play
		if(roundData.value[selectedRound.value].type == 'rms' || roundData.value[selectedRound.value].type == 'rts' || roundData.value[selectedRound.value].type == 'rs'){
			var total = 0
			for (i = 1; i <= 36; i++) {
				let score = updatedScores.value[selectedRound.value]['str'+i] != undefined ? updatedScores.value[selectedRound.value]['str'+i] : 0
				total+=Number(score)
			}
			updatedScores.value[selectedRound.value].strTotal = total
		}

        const input = {...updatedScores.value[selectedRound.value], ...tempInput}

        try {
            const res = await api(updateRoundScores, { input })

            console.log(res)

        }
        catch(e) {
            console.log(e)
        }
        finally {
            //Update default scores to updated ones
            defaultScores.value = updatedScores.value

            //Show edit button and remove inputs
            editScores.value = false
        }
    }
    // #endregion
</script>

<style scoped>
    .outline {
        outline: 1px solid black;
        outline-offset: 2px;
    }

    .bogey-border{
        border-width: 1px;
        border-color: black;
        padding: 0px 7px 4px 7px;
    }

    .birdie-border{
        border-width: 1px;
        border-color: black;
        padding: 0px 7px 4px 7px;
        border-radius: 50%;
    }
    .eagle-key{
        padding: 1px 2.25px;
        font-size: 9px;
    }
    .birdie-key{
        padding: 5px 6.25px;
        font-size: 9px;
    }
    .bogey-key{
        font-size: 9px;
        padding: 4px 6px;
    }
    .double-bogey-key{
        font-size: 9px;
        padding: 2px 3px;
    }   
    /* Removes arrows and unecessary padding from inputs for scores */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
        padding: 0px 0px !important;
        line-height: 0px !important;
    } 

    
    /* div.zoom {
        zoom: .85;
    } */
</style>
<style>
.small  div  input{
		font-feature-settings: "lnum";
        margin-top: -2px;
		text-align: center;
        padding: 3px 0px 0px 0px !important;
    }
</style>
/**
 * Formats entered date
 * validateDate
 */
 function validateDate(date){
	try{
		let test = new Date(date)
		if(isNaN(test.getTime())) throw 'Invalid Date Thrown'
		return (true)
	}
	catch(e){
		console.log(e)
		return false
	}
}


exports.validateDate = validateDate
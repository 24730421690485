<template>
    <tw-heading v-if="venueData" :title="venueData.name" :subtitle="subtitle" :bread-crumb="breadCrumbs" :loading="isLoadingDetail" ref="heading"></tw-heading> 
    <div v-if="canEdit" class="px-6 my-4 sm:float-right sm:-mt-16 md:float-none md:mt-4 lg:float-right lg:-mt-16">
        <tw-button v-show="!editVenueData" class="button btn-base btn-red   w-full sm:w-auto md:w-full lg:w-auto" :rounded="true" @click="edit">Edit Venue</tw-button>
        <tw-button v-show="editVenueData"  class="button btn-base btn-red   w-full mr-0 sm:mr-4 sm:w-24 md:w-full md:mr-0 lg:mr-4 lg:w-24" :rounded="true" @click="editSave">Save</tw-button>
        <tw-button v-show="editVenueData"  class="button btn-base btn-white w-full mt-4 sm:mt-0 sm:w-24 md:w-full md:mt-4 lg:mt-0 lg:w-24" :rounded="true" @click="editCancel">Cancel</tw-button>
    </div>
    <div class="grid grid-cols-4 px-6 content-center gap-4 text-gray-500 grid-flow-row">
        <div class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-1 mt-2">
            <dt class="field-label">City</dt>  
            <div v-show="!isLoadingDetail && !editVenueData" class="text-gray-900 mt-1">
                {{ venueData.city || '—'}}
            </div>
            <div v-show="isLoadingDetail && !editVenueData" class="animate-pulse mt-2">
                <div class="h-2 bg-usgaGray rounded"></div>
            </div>
            <div v-if="editVenueData" class=" -mb-3.5">
				<label for="city" class="sr-only">City</label>
				<tw-input v-model="updatedCity" type="text" id="city"></tw-input>
            </div> 
        </div>
        <div class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-1 mt-2">
            <dt class="field-label">State</dt>  
            <div v-show="!isLoadingDetail && !editVenueData" class="text-gray-900 mt-1">
                {{ venueData.state || '—' }}
            </div>
            <div v-show="isLoadingDetail && !editVenueData" class="animate-pulse mt-2">
                <div class="h-2 bg-usgaGray rounded"></div>
            </div>
            <div v-if="editVenueData" class="-mb-3.5">
				<label for="state" class="sr-only">State</label>
				<tw-input v-model="updatedState" type="text" id="state"></tw-input>
			</div> 
        </div>

        <div class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-1 mt-2">
            <dt class="field-label">Country</dt>  
            <div v-show="!isLoadingDetail && !editVenueData" class="text-gray-900 mt-1">
                {{ venueData.country || '—' }}
            </div>
            <div v-show="isLoadingDetail && !editVenueData" class="animate-pulse mt-2">
                <div class="h-2 bg-usgaGray rounded"></div>
            </div>
            <div v-if="editVenueData" class=" -mb-3.5">
				<label for="country" class="sr-only">Country</label>
				<select 
					v-model="updatedCountry"
					@change="updateCountry" 
					name="country" 
					id="country" 
					class="border-gray-300 focus:ring-usgaRed focus:border-usgaRed shadow-sm rounded-sm text-sm mt-1">
					<option 
						v-for="country in countries" 
						:key="country" 
						:value="country.name">
						{{country.name}}
					</option>
				</select>
            </div>
        </div>
    </div>
    
    <div class="my-6">
        <tw-table
            @view="routeToChampionshipDetails" 
            @sort-query="sortColumn"
            :config="venueChampionshipsTableConfig"
            :data="data"
            title="Championships"
            :editable="true"
			placeholder="No Championships"
            :isLoading="isLoadingList"
            :canEdit="canEdit" 
            :viewable="true"/>
    </div>
</template>

<script setup>

    // #region import statements
    import { defineProps, ref, onMounted, onBeforeUnmount, onBeforeMount, } from 'vue'
    import { listChampionshipsByVenue, getVenueName } from './queries.ts'
    import { useRouter, onBeforeRouteLeave } from 'vue-router';
	import formatDateListToRange from "@/utilities/formatDateListToRange.js"
    import EventBus from '@/event'
    import api from "@/utilities/api"
    import { updateVenueInfo, updateChampionshipInfo } from './mutations';
    import { Auth } from 'aws-amplify'
	import countries from "@/utilities/countries.json"
    // #endregion

    // #region setup
    defineProps([
        'id'
    ])
    const venueData = ref([])
    const subtitle = ref({})
    const editVenueData = ref(false)
    const heading = ref(null)
    
    //Sets up table headers
    const venueChampionshipsTableConfig = {
        columns: [
            {header: 'Name', prop: 'name', sort: listChampionshipsByVenue, class: 'w-52 sm:w-64', headerClass: 'w-52 sm:w-64', editable: false},
			{header: 'Champion', prop: 'winner', class: 'w-52 sm:w-64', headerClass: 'w-52 sm:w-64', editable: false},
            {header: 'Dates', prop: 'dates', class: 'w-52 sm:w-64', headerClass: 'w-52 sm:w-64', editable: true},
        ],
        destination: 'Championship Detail',
    }

    //Get current route to use in breadcrumbs
    const router = useRouter()
    // const currentRoute = route.currentRoute.value.href
    const venueId = router.currentRoute.value.params.id

    const breadCrumbs = ref([])
    breadCrumbs.value = [
        {name: 'Venues', route: {name: 'Venues'}},
        {name: 'Venue Detail'},
    ]

    //Variables used in getting championships
    const isLoadingDetail = ref(false)
    const isLoadingList = ref(false)
    const error = ref(null)
    const data = ref([]);
    const nextToken = ref(null)
    const sortDirection = ref('ASC')

    // #endregion

    // #region user authentication
    const canEdit = ref(false)

    onBeforeMount( async () => {
        try {
            const user = await Auth.currentAuthenticatedUser()
            canEdit.value = user?.attributes['custom:azureGroup'].indexOf(process.env.VUE_APP_ADMIN_GROUP) > -1
        }
        catch(e) {
            console.log(e)
        }
        
    });
    // #endregion

    // #region infinite scroll setup
    onMounted( () => {
        EventBus.on('infinite-scroll', async () => {
            const payload = {
                sortDirection: sortDirection.value,
                query: listChampionshipsByVenue
            }
            if(nextToken.value) await getChampionships(payload)
            EventBus.trigger('infinite-scroll-end', null)
        })
    })

    onBeforeUnmount( () => {
        EventBus.off('infinite-scroll', null)
    })

    // #endregion

    // #region query and sort table
    function sortColumn(payload) {
        sortDirection.value = payload.sortDirection
        nextToken.value = null
        data.value = []
        getChampionships(payload)
    }

    /**
     * Routes user to detail page of selected championship
     * @function routeToChampionshipDetails
     * @param {Object} payload - Payload object that contains sort direction and query 
     */
    function routeToChampionshipDetails(payload){
        router.push({name: venueChampionshipsTableConfig.destination, params:{id: payload.targetId}})
    }

    //Gets all championship data for current venue
    async function getChampionships(payload){
        isLoadingList.value = true

        //Query setup to get championships based on given venue
        const input = {
            limit: 30,
            nodeTypeWithId: venueId,
            targetTypeWithId:{
                beginsWith: 'c'
            },
            sortDirection: sortDirection.value, 
            nextToken: nextToken.value,
        }
        try{
            const res = await api(payload.query, input)
            const items = res.data.listNodes.items
			items.map((item) => {
				let dates = formatDateListToRange(item.dates)
				item.dates = dates
				return
			}) 
            data.value = [...data.value, ...items]
            nextToken.value = res.data.listNodes.nextToken
        } catch(e){
            error.value = e
            console.log(error.value)
        } finally {
            isLoadingList.value = false
        }
    }  

    (async () => {
        isLoadingDetail.value = true
        isLoadingList.value = true
        //Runs query to get venue name using id
        const input = {
            nodeTypeWithId: venueId,
            targetTypeWithId: venueId,
        }
        try{
            const res = await api(getVenueName, input)
            venueData.value = res.data.getNode
            subtitle.value = {
                city: venueData.value.city,
                country: venueData.value.country,
                state: venueData.value.state
            }
        } catch(e){
            error.value = e
            console.log(error.value)
        }

        //Set default values of updated fields
        updatedCity.value = venueData.value.city
        updatedState.value = venueData.value.state
        updatedCountry.value = venueData.value.country

        //Populates table on inital loading of page
        const payload = {
            sortDirection: sortDirection.value,
            query: listChampionshipsByVenue,
        }
        await getChampionships(payload)
        isLoadingDetail.value = false
    })()
    // #endregion

    // #region edit
            const updatedCity = ref('')
            const updatedState = ref('')
            const updatedCountry = ref('')
            function editCancel(){
                updatedCity.value = subtitle.value.city
                updatedState.value = subtitle.value.state
                updatedCountry.value = subtitle.value.country

                editVenueData.value = false
            }

            async function editSave() {

				// TO-DO make venue name editable

				// TO-DO update name of venue on championships using lambda
				
                const input = {
                    nodeTypeWithId: venueData.value.nodeTypeWithId,
                    targetTypeWithId: venueData.value.targetTypeWithId,
                    type: 'v',
                    city: updatedCity.value,
                    state: updatedState.value,
                    country: updatedCountry.value,
                }
                try{
                    const res = await api(updateVenueInfo, { input })

                    venueData.value.city = res.data.updateNode.city
                    venueData.value.state = res.data.updateNode.state
                    venueData.value.country = res.data.updateNode.country

					// loop through championships and update location fields
					data.value.forEach(async championship => {
						let input = {
							nodeTypeWithId: championship.targetTypeWithId,
							targetTypeWithId: championship.targetTypeWithId,
							city: updatedCity.value,
							state: updatedState.value,
							country: updatedCountry.value,
						}
						await api(updateChampionshipInfo, { input })
					})
                }catch(e){
                    console.log(e)
                }finally{
                    editVenueData.value = false
                }
            }

            function edit(){
                editVenueData.value = true
                heading.value.setInputs()
            }

            //Check if user is editing and ask if they want to save changes or not.
            onBeforeRouteLeave ((to, from, next) => {
                if (editVenueData.value){
                    const answer = window.confirm('Do you want to leave without saving your changes?')

                    if (answer) {
                        next()
                    }else{
                        next(false)
                    }
                }else{
                    next()
                }
            })
            // #endregion

</script>
// export const listImportHistory = /* GraphQL */ `
//   query ImportByType(
//       $type: String!
//       $name: ModelStringKeyConditionInput
//       $sortDirection: ModelSortDirection
//       $filter: ModelimportFilterInput
//       $limit: Int
//       $nextToken: String
//     ) {
//     importByType(
//       type: $type
//       name: $name
//       sortDirection: $sortDirection
//       filter: $filter
//       limit: $limit
//       nextToken: $nextToken
//     ) {
//       items {
//         id
//         type
//         name
//         user
//         status
//         createdAt
//         file
//         championship
//         venue
//         revertedAt
//       }
//       nextToken
//     }
//   }
// `;

export const importByTypeSortedByCreatedAt = /* GraphQL */ `
  query ImportByTypeSortedByCreatedAt(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelimportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    importByTypeSortedByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        user
        status
        createdAt
        file
        championship
        venue
        revertedAt
      }
      nextToken
    }
  }
`;
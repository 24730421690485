<template>
    <div>
        <tw-heading :title="heading" :subtitle="subtitle"></tw-heading>
    </div>

    <div v-if="searchStep == 1">
        <div class="text-lg mx-6 mt-10 mb-6">Start a new search</div>
            
        <div class="grid grid-cols-3 gap-12 mx-6">
            <div class="card relative flex items-center space-x-4 hover:bg-usgaLightGray focus-within:ring-2 focus-within:ring-usgaBlue">
                <div class="bg-usgaBlue flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg">
                    <UsersIcon class="h-6 w-6 text-white" aria-hidden="true" /> 
                </div>
                <div>
                    <h3 class="text-2xl font-medium text-gray-900">
                    <a href="#" class="focus:outline-none" @click="setSearch(0)">
                        <span class="absolute inset-0" aria-hidden="true" />
                        Players<span aria-hidden="true"> &rarr;</span>
                    </a>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Search by name, stats, scores, and more</p>
                </div>
            </div>
            <div class="card relative flex items-center space-x-4 hover:bg-usgaLightGray focus-within:ring-2 focus-within:ring-usgaGray">
                <div class="bg-usgaDarkGray flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg">
                    <LocationMarkerIcon class="h-6 w-6 text-white" aria-hidden="true" /> 
                </div>
                <div>
                    <h3 class="text-2xl font-medium text-gray-900">
                    <a href="#" class="focus:outline-none" @click="setSearch(1)">
                        <span class="absolute inset-0" aria-hidden="true" />
                        Venues<span aria-hidden="true"> &rarr;</span>
                    </a>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Search by championships, date, and more</p>
                </div>
            </div>
            <div class="card relative flex items-center space-x-4 hover:bg-usgaLightGray focus-within:ring-2 focus-within:ring-usgaGray">
                <div class="bg-usgaRed flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg">
                    <FlagIcon class="h-6 w-6 text-white" aria-hidden="true" /> 
                </div>
                <div>
                    <h3 class="text-2xl font-medium text-gray-900">
                    <a href="#" class="focus:outline-none" @click="setSearch(2)">
                        <span class="absolute inset-0" aria-hidden="true" />
                        Championships<span aria-hidden="true"> &rarr;</span>
                    </a>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">Search by champion, entries, data, and more</p>
                </div>
            </div>
        </div>

        <div class="mt-16 m-6">
            <div>
                <div class="pb-8">
                    <h2 class="text-lg font-medium text-gray-900 float-left">Recent Saved Searches</h2>
                    <router-link to="/saved-searches" class="link float-right">View All &rarr;</router-link>
                </div>
				<tw-loading v-show="savedSearchesLoading"></tw-loading>
                <div v-if="savedSearches != null && Object.keys(savedSearches).length == 0" class="text-gray-900 mt-2 text-center">No recent searches.</div>
                <ul v-show="!savedSearchesLoading" role="list" class="mt-6 focus-within:border-t divide-y divide-gray-200">
                    <li></li>
                    <li class="" v-for="(item, itemIdx) in savedSearches" :key="itemIdx">
                        <div class="relative group py-4 flex items-start space-x-3">
                            <div class="flex-shrink-0">
                                <span :class="[item.iconColor, 'inline-flex items-center justify-center h-10 w-10 rounded-lg']">
                                    <component :is="item.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                                </span>
                            </div>
                            <div class="min-w-0 flex-1">
                                <div class="text-sm font-medium text-gray-900">
                                    <a href="#" @click="setupSavedSearch(item)">
                                        <span class="absolute inset-0" aria-hidden="true" />
                                        {{ item.name }}
                                    </a>
                                </div>
                                <p class="text-sm text-gray-500">{{ item.description }}</p>
                            </div>
                            <div class="flex-shrink-0 self-center">
                                <ChevronRightIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            </div>
                        </div>
                    </li>
                    <li></li>
                </ul>
            </div>
        </div>
    </div>
    <div v-if="searchStep == 2">
        <div class="mx-6 mt-2">
			<div class="flex gap-4 justify-between items-center">
                <tw-button @click="goToStepOne" class="btn-white btn-base sm:w-24" :rounded="true">
                    <template v-slot:icon><ArrowSmLeftIcon class="h-5 w-5"/></template>Back
                </tw-button>
                <tw-button v-if="searchStep == 2" @click="saveSearchModal = saveSearchModal ? false : true" class="btn-white btn-base" :rounded="true" >
                    Save Current Search
                </tw-button>
            </div>
            <div class="flex items-center gap-2 mt-4">
                <div v-if="selectedSearch == 0" class="pr-2 rounded-sm inline-block">
                    <div class="bg-usgaBlue flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg">
                        <UsersIcon class="h-6 w-6 text-white" aria-hidden="true" />
                    </div> 
                </div>
                <div v-if="selectedSearch == 1" class="pr-2 rounded-sm inline-block">
                    <div class="bg-usgaGray flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg">
                        <LocationMarkerIcon class="h-6 w-6 text-white" aria-hidden="true" /> 
                    </div> 
                </div>
                <div v-if="selectedSearch == 2" class="pr-2 rounded-sm inline-block">
                    <div class="bg-usgaRed flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg">
                        <FlagIcon class="h-6 w-6 text-white" aria-hidden="true" /> 
                    </div> 
                </div>
                    {{selectedSearchType}} that match 
                    <tw-selectmenu 
                        v-model="matchCriteriaSelectedId"
                        class="w-20 inline-block" 
                        :options="matchCriteriaOptions"/> 
                    of the following criteria
            </div>
            
        </div>

        <div class="mx-6 mt-6">
            <transition-group name="fade">
                <div v-for="(field, i) in searchFields" :key="i ">
                    <tw-searchfield 
                        class="py-2"
						:i="i"
						:fieldOptions="fieldOptions"
						:searchField="field"
                        @update-field="(p) => {updateField(p, i)}" 
                        @update-operator="(p) => {updateOperator(p, i)}" 
                        @update-criteria="(p) => {updateCriteria(p, i)}" 
                        @delete="deleteSearchField(i)" 
                        @duplicate="duplicateSearchField" ></tw-searchfield>
                </div>
            </transition-group>
        </div>

        <div class="grid grid-cols-12 space-x-4 mx-6 mt-4">
            <div class="col-span-12 lg:col-span-3 xl:col-span-3">
                <tw-button @click="addSearchField" class="btn-white btn-base" :rounded="true" >
                    <template v-slot:icon><PlusSmIcon class="h-5 w-5"/></template> Add another field
                </tw-button>
            </div>
        </div>
        <div class="flex gap-4 justify-end items-center mx-6 mt-12">
            <tw-button @click="cancelSearch" class="btn-white btn-base sm:w-24" :rounded="true">
               Cancel
            </tw-button>
            <tw-button @click="performSearch" :disabled="searchDisabled" class="btn-red btn-base sm:w-24" :rounded="true">
               Search
            </tw-button>
        </div>
    </div>

    <div v-if="searchStep == 3">
        <div class="flex gap-4 justify-between items-center mx-6 mt-2">
            <tw-button @click="goBackToSearchCriteria" class="btn-white btn-base sm:w-24" :rounded="true">
               <template v-slot:icon><ArrowSmLeftIcon class="h-5 w-5"/></template>Back
            </tw-button>
			<span class="flex space-x-4">
				<tw-button @click="searchStep = 4" class="btn-white btn-base " :rounded="true" :disabled="isLoading">
					Export Results
				</tw-button>
				<tw-button @click="saveSearchModal = saveSearchModal ? false : true" class="btn-white btn-base " :rounded="true">
					Save Current Search
				</tw-button>
			</span>
        </div>
		<div class="my-6">
			<tw-table  
				@view="routeToDetailPage"  
				:isLoading="isLoading" 
				:config="selectedTableConfig" 
				:data="searchResults" 
				placeholder="No Results"
				:viewable="true"></tw-table>			
		</div>
    </div>

	<!-- export -->
	<div v-if="searchStep == 4">
        <div class="flex gap-4 justify-between items-center mx-6 mt-2">
            <tw-button @click="searchStep = 3" class="btn-white btn-base " :rounded="true">
               <template v-slot:icon><ArrowSmLeftIcon class="h-5 w-5"/></template>Back to search results
            </tw-button>
			<span class="flex space-x-4">
				<!-- <tw-button @click="saveSearchModal = saveSearchModal ? false : true" class="btn-white btn-base " :rounded="true">
				Save Current Export Settings
				</tw-button> -->
			</span>
        </div>
        <Export :selectedSearch="selectedSearch" :data="searchResults" :query="query"></Export>
    </div>

    <TransitionRoot as="template" :show="saveSearchModal">
        <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="saveSearchModal = false">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div class="">
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <DialogTitle as="h1" class="text-3xl leading-6 font-medium text-gray-900"> Save Current Search </DialogTitle>
                        <div class="pt-6 mx-auto">
                            <tw-input v-model="saveSearchName" label="Name"></tw-input>
                            <label for="desc" class="block text-sm font-medium text-gray-700">Description</label>
                            <textarea id="desc" class="mt-1 shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-sm" v-model="saveSearchDescription" label="Description"></textarea>
                        </div>
                    </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="button" class="w-max inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm" @click="saveSearch">Save</button>
                    <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm" @click="saveSearchModal = false" ref="cancelButtonRef">Cancel</button>
                </div>
            </div>
            </TransitionChild>
        </div>
        </Dialog>
    </TransitionRoot>
    <tw-notification v-if="savedNotification" @reset-notification="resetNotification" class="m-12" :title="'Success!'" :subtitle="'Successfully saved current search.'" :duration="5000" :icon="0"></tw-notification>

</template>

<script setup>

    // #region import statements
import { FlagIcon, UsersIcon, MapPinIcon as LocationMarkerIcon } from '@heroicons/vue/24/outline'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { defineComponent, ref, defineProps, computed } from 'vue'
import { saveSearchMut } from './mutations.ts'
import Export from '@/components/Export/Export.vue'
import formatDateListToRange from "@/utilities/formatDateListToRange.js"
import { listSavedSearchByUser, nodeByTargetTypeP, nodeByTargetTypeC, nodeByTargetTypeV} from './queries.ts'
import { useRouter } from 'vue-router'
import queryAll from "@/utilities/queryAll"
import api from "@/utilities/api"
import { toTitleCase } from '@/utilities/sanitize.js'
import { VL_DB_FIELDS_SEARCH_PLAYERS, VL_DB_FIELDS_SEARCH_CHAMP, VL_DB_FIELDS_SEARCH_VENUE } from '@/utilities/valueLists.js' 
// #endregion

    // #region setup
            const props = defineProps([
                'item'
            ])
			const query = ref([])
            defineComponent({
                components: {
                    Dialog,
                    DialogOverlay,
                    DialogTitle,
                    TransitionChild,
                    TransitionRoot,
					Export
                }
            })
            const selectedSearchType = computed(() => {
                if(selectedSearch.value == 0) return 'Players'
                else if(selectedSearch.value == 1) return 'Venues'
                else if(selectedSearch.value == 2) return 'Championships'
                return ''
            })
            const fieldOptions = computed(() => {
                if(selectedSearch.value == 0) return VL_DB_FIELDS_SEARCH_PLAYERS
                else if(selectedSearch.value == 1) return VL_DB_FIELDS_SEARCH_VENUE
                else if(selectedSearch.value == 2) return VL_DB_FIELDS_SEARCH_CHAMP
                return VL_DB_FIELDS_SEARCH_PLAYERS
            })
            //Step to determine what should be loaded on page
            const searchStep = ref(1)
			const searchDisabled = computed(() =>{
				return searchFields.value.some(field =>{
					return !field.criteria || !field.field || !field.operator
				})
			})
            const savedNotification = ref(false)
            const saveSearchModal = ref(false)
            const saveSearchName = ref('')
            const saveSearchDescription = ref('')
            //Default serach field
            const searchFields = ref([])
			const isLoading = ref(false)

            const subtitle = computed(() => {
                if(searchStep.value == 3){ 
                    let criteria = searchFields.value.map((field) => {
                        return `<span class="font-bold text-black">${field.field.label}</span> ${field.operator.label} <span class="font-bold text-black">'${field.criteria}'</span>`
                    }).join(matchCriteriaSelectedId.value.value == 'any' ? '<span class="text-sm"> OR </span>' : '<span class="text-sm"> AND </span>')

                    return {htmlDescription: searchResults.value.length + ' results where ' + criteria}
                }
				else if(searchStep.value == 4){ 
					return {htmlDescription: 'Select columns to include in the export below'}
				}
                return {htmlDescription: 'Build your own custom search query'}
            })   

            //Options for match criteria
            const matchCriteriaOptions = [
                {id: 1, label: 'all', value: 'all'},
                {id: 2, label: 'any', value: 'any'}
            ]
            const matchCriteriaSelectedId = ref({})


            // #endregion
			const heading = computed(() => {
				if(searchStep.value < 4 ) return 'Advanced Search'
				else return 'Export Search Results'
			})
    // #region search 
            //Adds a new search field
            function addSearchField() {
                searchFields.value.push({
                    field: null,
                    operator: null,
                    criteria: null
                })
            }

            const selectedSearch = ref(0)
            function setSearch(i){
                searchFields.value = [{
                    field: null,
                    operator: null,
                    criteria: null
                }]
                matchCriteriaSelectedId.value = {
                    id: 1,
                    label: 'all',
                    value: 'all',
                    show: true
                }
                
                searchStep.value = 2
                selectedSearch.value = i

            }

            //Uses emitted id to delete search field
            function deleteSearchField(i) {
                console.log(i)
                if (searchFields.value.length == 1){
                    return
                }
                searchFields.value.splice(i, 1)
            }

            //Adds a new search field using the payload from duplicated search field
            function duplicateSearchField(payload) {
                searchFields.value.push(payload)
            }

			async function goToStepOne() {
				searchStep.value = 1
				await getSavedSearches()
			}

			function goBackToSearchCriteria(){
				searchResults.value = []
				searchStep.value = 2
			}

            function updateCriteria(payload, i) {
                searchFields.value[i].criteria = payload
            }

            function updateOperator(payload, i) {
                searchFields.value[i].operator = payload
            }
            
            function updateField(payload, i) {
                searchFields.value[i].field = payload
            }

            //Cancel search and reset to base search page
            function cancelSearch() {
                searchStep.value = 1
            }

            async function saveSearch() {
                var type = ''
                switch (selectedSearch.value){
                    case 0: type = 'p'
                        break
                    case 1: type = 'v'
                        break
                    case 2: type = 'c'
                        break
                }
                const input = {
                    type: type,
                    user: '12345', //Test Value
                    name: saveSearchName.value,
                    description: saveSearchDescription.value,
                    criteria: JSON.stringify(searchFields.value),
                    createdAt: (new Date()).toISOString()
                }
                try{
                    await api(saveSearchMut, {input})
                    // showNoti.value = true
                    // notificationSubtitle.value = 'Successfuly saved your import configuration.'
                    saveSearchModal.value = false
                }catch(e){
                    console.log(e)
                }finally{
                    savedNotification.value = true
                }
            }

            const savedSearches = ref(null)
			const savedSearchesLoading = ref(false)
            async function getSavedSearches() {
				savedSearchesLoading.value = true
                savedSearches.value = await api(listSavedSearchByUser)
                savedSearches.value = savedSearches.value.data.listSearches.items
                savedSearches.value = savedSearches.value.map(function(obj) {
                    var o = {
                        ...obj,
                        criteria: JSON.parse(obj.criteria)
                    }
                    if (obj.type == 'p'){
                        o['iconColor'] = 'bg-usgaBlue'
                        o['icon'] = UsersIcon
                    }else if (obj.type == 'v'){
                        o['iconColor'] = 'bg-usgaDarkGray'
                        o['icon'] = LocationMarkerIcon
                    }else if (obj.type == 'c'){
                        o['iconColor'] = 'bg-usgaRed'
                        o['icon'] = FlagIcon
                    }
                    return o
                })
                savedSearchesLoading.value = false
            }

            function setupSavedSearch(item) {
                searchFields.value = item.criteria
                selectedSearch.value = item.type == 'p' ? 0 : (item.type == 'v' ? 1 : (item.type == 'c' ? 3 : 0))
                searchStep.value = 2
            }

            function resetNotification (){
                savedNotification.value = false
            }

            (async () => {
                searchFields.value = [{
                    id: 1,
                    payload: {
                        field: 1,
                        operator: 1,
                        searchInput: '',
                    }
                }]
                
                if (props.item){
                    setupSavedSearch(JSON.parse(props.item))
                }

               await getSavedSearches()
            })()


//Functions for search results page
            const searchResults = ref([])
            async function performSearch() {
				searchStep.value = 3
				isLoading.value = true;
				query.value = []
                let type = ''
				let searchQuery =  null
                switch (selectedSearch.value){
                    case 0: 
						type = 'p'
						searchQuery = nodeByTargetTypeP
                        break
                    case 1: 
						type = 'v'
						searchQuery = nodeByTargetTypeV
                        break
                    case 2: 
						type = 'c'
						searchQuery = nodeByTargetTypeC
                        break
                }

                // set search input
                let upperInput = {
                    type: type,
                    sortDirection: 'ASC',
                    filter: {}
                }
				let lowerInput = {
                    type: type,
                    sortDirection: 'ASC',
                    filter: {}
                }

                if(matchCriteriaSelectedId.value.value == 'all') {
                    searchFields.value.forEach(field => {
                        if(field.field.value != 'name'){
                            lowerInput.filter[field.field.value] = {}
                            lowerInput.filter[field.field.value][field.operator.value] = field.criteria
							upperInput.filter[field.field.value] = {}
                            upperInput.filter[field.field.value][field.operator.value] = field.criteria
                        }
                        else{
                            lowerInput.name = {}
                            lowerInput.name[field.operator.value] = field.criteria.toLowerCase()
                            upperInput.name = {}
                            upperInput.name[field.operator.value] = toTitleCase(field.criteria)
                        }
                    })
                    
                    let lowerRes = await queryAll(searchQuery, lowerInput, 'nodeByTargetType')
                    if(selectedSearch.value == 2) {
						lowerRes.map((item) => {
							let dates = formatDateListToRange(item.dates)
							item.dates = dates
							return
						})  
					}
					let upperRes = await queryAll(searchQuery, upperInput, 'nodeByTargetType')
                    if(selectedSearch.value == 2) {
						upperRes.map((item) => {
							let dates = formatDateListToRange(item.dates)
							item.dates = dates
							return
						})  
					}
                    searchResults.value = [...upperRes, ...lowerRes]
					query.value.push(JSON.parse(JSON.stringify(lowerInput)))
					query.value.push(JSON.parse(JSON.stringify(upperInput)))
                }
                
                else {
					for(const field of searchFields.value) {
                        if(field.field.value != 'name') {
							lowerInput.filter[field.field.value] = {}
                            lowerInput.filter[field.field.value][field.operator.value] = field.criteria
							upperInput.filter[field.field.value] = {}
                            upperInput.filter[field.field.value][field.operator.value] = field.criteria
          
                            let lowerRes = await queryAll(searchQuery, lowerInput, 'nodeByTargetType')
                            if(selectedSearch.value == 2){
                                lowerRes.map((item) => {
                                    let dates = formatDateListToRange(item.dates)
                                    item.dates = dates
                                    return
                                })  
                            }
							let upperRes = await queryAll(searchQuery, upperInput, 'nodeByTargetType')
                            if(selectedSearch.value == 2){
                                upperRes.map((item) => {
                                    let dates = formatDateListToRange(item.dates)
                                    item.dates = dates
                                    return
                                })  
                            }
                            searchResults.value = [
                                ...searchResults.value,
                                ...lowerRes,
								...upperRes
                            ]
							query.value.push(JSON.parse(JSON.stringify(lowerInput)))
							query.value.push(JSON.parse(JSON.stringify(upperInput)))
                        }
                        else {
                            lowerInput.name = {}
                            lowerInput.name[field.operator.value] = field.criteria.toLowerCase()
                            upperInput.name = {}
                            upperInput.name[field.operator.value] = toTitleCase(field.criteria)

                            let lowerRes = await queryAll(searchQuery, lowerInput, 'nodeByTargetType')
                            if(selectedSearch.value == 2){
                                lowerRes.map((item) => {
                                    let dates = formatDateListToRange(item.dates)
                                    item.dates = dates
                                    return
                                })  
                            }
							let upperRes = await queryAll(searchQuery, upperInput, 'nodeByTargetType')
                            if(selectedSearch.value == 2){
                                upperRes.map((item) => {
                                    let dates = formatDateListToRange(item.dates)
                                    item.dates = dates
                                    return
                                })  
                            }
                            searchResults.value = [
                                ...searchResults.value,
                                ...lowerRes,
								...upperRes
                            ]
							query.value.push(JSON.parse(JSON.stringify(lowerInput)))
							query.value.push(JSON.parse(JSON.stringify(upperInput)))
                        }
					}
                }
				
				isLoading.value = false;
				searchStep.value = 3
            }

            // #region route user to player page

            const router = useRouter()

            /**
             * Routes user to detail page of selected championship
             * @function routeToPlayerPage
             * @param {Object} payload - Payload object that contains sort direction and query 
             */
            function routeToDetailPage(payload){
                router.push({name: selectedTableConfig.value.destination, params:{id: payload.nodeId}})
            }

            // #endregion
            const playerConfig = {
                columns: [
                    // {header: 'Name', prop: 'name', mobile: true, mobileBp: 'lg', stacked: false, class: 'font-medium text-gray-900', editable: false}, 
                    {header: 'First Name', prop: 'nameFirst', mobile: true, mobileBp: 'lg', stacked: false, class: 'font-medium text-gray-900', editable: false}, 
                    {header: 'Last Name', prop: 'nameLast', mobile: true, mobileBp: 'lg', stacked: false, class: 'font-medium text-gray-900', editable: false}, 
                    {header: 'Most Recent Championship', prop: 'mostRecentChampionship', mobile: false, mobileBp: 'lg', stacked: false, class: 'hidden text-gray-500 lg:table-cell', editable: false},
                    {header: 'Country Affiliation', prop: 'country', mobile: false, mobileBp: 'lg', stacked: true, class: 'hidden text-gray-500 lg:table-cell', editable: false},
                    {header: 'Championships', prop: 'championshipsPlayed', mobile: false, mobileBp: 'sm', stacked: false, class: 'hidden text-gray-500 sm:table-cell', editable: false},
                ],
                destination: 'Player Detail',
            }

            const champConfig = {
                columns: [
                    {header: 'Name', prop: 'name', mobile: true, mobileBp: 'lg', stacked: false, class: 'text-gray-900', editable: false}, 
                    {header: 'Champion', prop: 'winner', mobile:false, mobileBp: 'lg', stacked: false, class: 'hidden text-gray-500 lg:table-cell', editable: false},
                    {header: 'Dates', prop: 'dates', mobile: false, mobileBp: 'lg', stacked: true, class: 'hidden text-gray-500 lg:table-cell', editable: false},
                    {header: 'Venue', prop: 'location', mobile: false, mobileBp: 'lg', stacked: true, class: 'hidden text-gray-500 lg:table-cell', editable: false},
                    {header: 'City', prop: 'city', mobile: false, mobileBp: 'lg', stacked: false, class: 'hidden text-gray-500 lg:table-cell', editable: false},
                    {header: 'State', prop: 'state', mobile: false, mobileBp: 'lg', stacked: false, class: 'hidden text-gray-500 lg:table-cell', editable: false},
                    {header: 'Country', prop: 'country', mobile: false, mobileBp: 'lg', stacked: false, class: 'hidden text-gray-500 lg:table-cell', editable: false},
                ],
                destination: 'Championship Detail',
            }

            const venueConfig = {
                columns: [
                    {header: 'Name', prop: 'name', mobile: true, mobileBp: 'lg', stacked: false, class: 'text-gray-900', editable: false}, 
                    {header: 'City', prop: 'city' , mobile: false, mobileBp: 'lg', stacked: false, class: 'hidden text-gray-500 lg:table-cell', editable: false},
                    {header: 'State', prop: 'state', mobile: false, mobileBp: 'lg', stacked: true, class: 'hidden text-gray-500 lg:table-cell', editable: false},
                    {header: 'Country', prop: 'country', mobile: false, mobileBp: 'lg', stacked: true, class: 'hidden text-gray-500 lg:table-cell', editable: false},
                ],
                destination: 'Venue Detail',
            }

            const selectedTableConfig = computed(() => {
                if(selectedSearch.value == 0){
                    return playerConfig
                }
                else if(selectedSearch.value == 1){
                    return venueConfig
                }
                else {
                    return champConfig
                }
            })

            
            // #endregion  

			// function exportResults(){
				
			// }

</script>

<style scoped>
    ul {
        column-count: 2;
        column-gap: 48px;
    }

    .fade-enter-from{
        opacity: 0;
    }

    .fade-enter-to{
        opacity: 1;
    }

    .fade-enter-active{
        transition: all .7s ease;
    }

    .fade-leave-from{
        opacity: 1;
    }

    .fade-leave-to{
        opacity: 0;
    }

    .fade-leave-active{
        transition: all .3s ease;
    }
</style>

import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"

// Create a new store instance.
export const store = createStore({
    state: {
        user: {},
        loginRedirect: null,
        registrationId: null,
        championshipFilter: null,
        playerFilter: null,
        champTableFilter: null,
        playerTableFilter: null,
        managePlayerProfilesFilter: null,
        managePlayerProfilesConfirmationFilter: null
    },
    mutations: {
        setUser(state, payload){
            state.user = payload
        },
        setLoginRedirect(state, payload){
            state.loginRedirect = payload
        },
        setRegistrationId(state, payload){
            state.registrationId = payload
        },
		setChampFilter(state, payload){
            state.championshipFilter = payload
        },
		setPlayerFilter(state, payload){
            state.playerFilter = payload
        },
        setManagePlayerProfilesFilter(state, payload){
            state.managePlayerProfilesFilter = payload
        }
    },
    plugins: [createPersistedState({
        paths: ['user', 'loginRedirect', 'registrationId', 'championshipFilter', 'playerFilter', 'champTableFilter', 'playerTableFilter', 'managePlayerProfilesFilter', ' managePlayerProfilesConfirmationFilter'] // persist contact form state
    })]
})
<template>
    <div class="card">
        <div class="flex items-center">
            <div class="min-w-0 flex-1 ">
                <div class="min-w-0">
                    <div class="block sm:flex justify-between items-center">
                        <h2 class=" text-xl font-bold text-gray-900 sm:text-2xl sm:truncate">{{ payload.name }}</h2>
                        <div class="flex space-x-3 items-center">
                            <div class="inline-flex items-center space-x-1">
                                <DotsHorizontalIcon v-if="updatedPayload.status == 'processing'" class="h-5 w-5 text-usgaDarkGray" aria-hidden="true" />
                                <SolidCheckCircleIcon v-if="updatedPayload.status == 'completed'" class="h-5 w-5 text-usgaBlue" aria-hidden="true" />
                                <ReplyIcon v-if="updatedPayload.status == 'reverted'" class="h-5 w-5 text-usgaRed" aria-hidden="true" />
                                <DotsHorizontalIcon v-if="updatedPayload.status == 'revertInProgress'" class="h-5 w-5 text-usgaRed" aria-hidden="true" />
                                <h3 
                                    class="text-base font-medium sm:truncate"
                                    :class="{'text-usgaRed' : updatedPayload.status.includes('revert'), 
                                             'text-usgaBlue' : !updatedPayload.status.includes('revert')}">
                                        {{ importStatusText }}
                                </h3>
                            </div>
                        </div>
                    </div>
                    
                    <div class="block ">
                        <div class="flex items-center">
                            <CalendarIcon class="h-5 w-5 mr-1.5 text-gray-500"></CalendarIcon>
                            <h3 class=" text-gray-600 sm:truncate">{{ new Date(payload.createdAt).toLocaleString() }}</h3>
                        </div>
                        <div class="flex items-center">
                            <UserIcon class="h-5 w-5 mr-1.5 text-gray-500"></UserIcon>
                            <h3 class=" text-gray-600 sm:truncate">{{payload.user}}</h3>
                        </div>
                    </div>
                </div>
                <div class="min-w-0 flex-1 grid grid-cols-4">
                    <div class="col-span-4 xl:col-span-1 xl:mt-4">
                        <dt class="hidden xl:block text-usgaDarkGray text-sm">Venue</dt>
                        <dd v-for="(venue, i) in JSON.parse(payload.venue)" :key="i" class="inline-flex items-center text-gray-600 xl:text-gray-900">
                            <SolidLocationMarkerIcon class="xl:hidden h-5 w-5 mr-1.5 text-gray-500"></SolidLocationMarkerIcon>
                            {{ venue.name }}
                            <span v-if="!venue.value" class="text-xs ml-2 text-usgaDarkGray">(Created)</span>
                        </dd>
                    </div>
                    <div class="col-span-4 xl:col-span-1 xl:mt-4">
                        <dt class="hidden xl:block text-usgaDarkGray text-sm">Championship</dt>
                        <dd class="inline-flex items-center text-gray-600 xl:text-gray-900">
                            <SolidFlagIcon class="xl:hidden h-5 w-5 mr-1.5 text-gray-500"></SolidFlagIcon>
                            {{ JSON.parse(payload.championship)?.name }}
                            <span v-if="!JSON.parse(payload.championship)?.value && JSON.parse(payload.championship)?.name" class="text-xs ml-2 text-usgaDarkGray">(Created)</span>
                        </dd>
                    </div>
                    <div class="col-span-2 xl:col-span-1 mt-4">
                        <tw-button :rounded="true" type="button" class="btn-white btn-base" @click="fileDownload">Download File</tw-button>
                    </div>
                    <div class="col-span-2 xl:col-span-1 mt-4" :class="{'text-left': payload.status == 'reverted'}">
                        
                        <dt v-if="payload.status == 'reverted'" class=" text-usgaDarkGray text-sm">Reverted At</dt>
                        <dd v-if="payload.status == 'reverted'" class="text-gray-900 truncate">
                            {{ new Date(payload.revertedAt).toLocaleString() }}
                        </dd>
                        <tw-button :rounded="true" v-show="payload.status == 'completed'" type="button" class="btn-white btn-base" @click="revertImportOpen = true">Revert Import</tw-button>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>

    <TransitionRoot as="template" :show="revertImportOpen">
        <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="revertImportOpen = false">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                    <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div class="">
                        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <DialogTitle as="h1" class="text-3xl leading-6 font-medium text-black mb-4"> Revert Import </DialogTitle>
                            <p class="text-usgaDarkGray">This will permanently remove all data added by this import.</p>
                            <p class="text-black">There is no undo for this action.</p>
                            <p class="text-usgaDarkGray">Type the name of the import below to continue.</p>
                            <div class="mt-2 mx-auto">
                                <tw-input v-model="revertImportMatchText"></tw-input>
                                <p class="text-usgaDarkGray">{{ payload.name }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-usgaRed text-base font-medium text-white hover:bg-usgaDarkRed focus:outline-none sm:ml-3 sm:w-auto sm:text-sm" :class="{ 'opacity-25 cursor-not-allowed': revertImportMatchText != payload.name }" :disabled="revertImportMatchText != payload.name" @click="revertImport">Revert</button>
                        <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm" @click="revertImportOpen = false">Cancel</button>
                    </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>


</template>

<script setup>
    import { ref, defineProps, computed } from 'vue'
    import { updateImport } from './mutations.ts'
    import api from "@/utilities/api"
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { Storage } from 'aws-amplify'

    const props = defineProps([
        'payload',
        'index'
    ])

    const updatedPayload = ref(props.payload)
    const revertImportOpen = ref(false)
    const revertImportMatchText = ref('')

    /**
     * Display text for import status
     * @yields {String}
     */
    const importStatusText = computed(() => {
        if(updatedPayload.value.status == 'completed') return 'Completed Import'
        else if(updatedPayload.value.status == 'processing') return 'Processing Import'
        else if(updatedPayload.value.status == 'reverted') return 'Reverted Import'
        else if(updatedPayload.value.status == 'revertInProgress') return 'Revert in Progress'
        return ''
    })

    async function fileDownload(){
        let object = await Storage.get(props.payload.file)
        window.open(object, "_self")
    }

    async function revertImport(){
        //do something else if status is not completed
        if (props.payload.status != 'completed'){
            return 
        }

        const input = {
            id: props.payload.id,
            createdAt: props.payload.createdAt,
            revertedAt: (new Date()).toISOString(),
            status: 'revertInProgress'
        }

        try{
            // fire off lambda, don't need to wait for it to finish
            api(updateImport, {input})
        }
        catch(e) {
            console.log(e)
        }

        updatedPayload.value.status = 'revertInProgress'
        revertImportOpen.value = false
    }
</script>

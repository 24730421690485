const VL_IMPORT_TYPES = [
    {id: 1, label: 'Stroke Play'}, 
    {id: 2, label: 'Team Event'}, 
    {id: 3, label: 'Match Play'},
    {id: 4, label: 'Player Information'},
    {id: 5, label: 'Championship Information'},
    {id: 6, label: 'Venue Information'},
    {id: 7, label: 'Player Profile Migration'},
]

const VL_IMPORT_AS = [
    {id: 1, label: 'Players'}, 
    {id: 2, label: 'Championships'}, 
    {id: 3, label: 'Venues'}, 
    {id: 4, label: 'Rounds'}
]

const VL_ROUNDS_STROKE_PLAY = [
    {id: 1, label: 'Round 1', value: 'r1'},
    {id: 2, label: 'Round 2', value: 'r2'},
    {id: 3, label: 'Round 3', value: 'r3'},
    {id: 4, label: 'Round 4', value: 'r4'}
]
const VL_ROUNDS_MATCH_PLAY = [
    {id: 1, label: 'Stroke Play', value: 'r1'},
    {id: 2, label: 'Playoff', value: 'p'},
    {id: 3, label: 'Round of 64', value: 'r64'},
    {id: 4, label: 'Round of 32', value: 'r32'},
    {id: 5, label: 'Round of 16', value: 'r16'},
    {id: 6, label: 'Quarterfinals', value: 'quarter'},
    {id: 7, label: 'Semifinals', value: 'semi'},
    {id: 8, label: 'Final', value: 'final'}
]
const VL_ROUNDS_TEAM_PLAY = [
    {id: 1, label: 'Stroke Play - Round 1', value: 'r1'},
    {id: 2, label: 'Stroke Play - Round 2', value: 'r2'},
    {id: 3, label: 'Playoff', value: 'p'},
    {id: 4, label: 'Round of 32', value: 'r32'},
    {id: 5, label: 'Round of 16', value: 'r16'},
    {id: 6, label: 'Quarterfinals', value: 'quarter'},
    {id: 7, label: 'Semifinals', value: 'semi'},
    {id: 8, label: 'Final', value: 'final'}
]

const VL_DB_FIELDS = [
    {id: 2, label: 'City', value: 'city'},
    {id: 3, label: 'State', value: 'state'},
    {id: 4, label: 'Country', value: 'country'},
    {id: 5, label: 'Date', value: 'date'},
    {id: '5a', label: 'Player Birthday', value: 'date'},
    {id: 6, label: 'Player Name', value: 'name'},
    {id: '6a', label: 'Championship Name', value: 'name'},
    {id: '6b', label: 'Venue Name', value: 'name'},
    {id: 7, label: 'Image', value: 'img'},
    {id: 8, label: 'Location', value: 'location'},
    {id: 9, label: 'Secondary Location', value: 'secondaryLocation'},
    {id: 10, label: 'Winner', value: 'winner'},
    {id: 11, label: 'Entry Count', value: 'entries'},
    {id: 12, label: 'Field Size', value: 'fieldSize'},
    {id: 13, label: 'Home Club', value: 'homeClub'},
    {id: 14, label: 'Home Club City', value: 'homeClubCity'},
    {id: 15, label: 'Home Club State', value: 'homeClubState'},
    {id: 16, label: 'Championships Played', value: 'championshipsPlayed'},
    // {id: 17, label: 'Player Name', value: 'playerName'},
    {id: 18, label: 'Age', value: 'age'},
    {id: 19, label: 'Currency', value: 'currency'},
    {id: 20, label: 'First U.S. Open?', value: 'firstUsoUswo'},
    {id: 21, label: 'Skill Level', value: 'level'},
    {id: 22, label: 'Money', value: 'money'},
    {id: 23, label: 'Position', value: 'position'},
    {id: 24, label: 'Rank', value: 'rank'},
    {id: 25, label: 'Round 1 Score', value: 'round1'},
    {id: 26, label: 'Round 2 Score', value: 'round2'},
    {id: 27, label: 'Round 3 Score', value: 'round3'},
    {id: 28, label: 'Round 4 Score', value: 'round4'},
    {id: 29, label: 'Total Score', value: 'total'},
    {id: 30, label: 'Drive', value: 'drive'},
    {id: 31, label: 'Fairways Hit', value: 'fairwaysHit'},
    {id: 32, label: 'Fairways Possible', value: 'fairwaysPossible'},
    {id: 33, label: 'Greens Hit', value: 'greens'},
    {id: 34, label: 'Partners', value: 'partners'},
    {id: 35, label: 'Par In', value: 'parIn'},
    {id: 36, label: 'Par Out', value: 'parOut'},
    {id: 37, label: 'Par Total', value: 'parTotal'},
    {id: 38, label: 'Putts', value: 'putts'},
    {id: 39, label: 'Strokes In for Match 1', value: 'strIn'},
    {id: 40, label: 'Strokes In for Match 2', value: 'strIn2'},
    {id: 41, label: 'Strokes Out for Match 1', value: 'strOut'},
    {id: 42, label: 'Strokes Out for Match 2', value: 'strOut2'},
    {id: 43, label: 'Strokes Total for Match 1', value: 'strTotal'},
    {id: 44, label: 'Strokes Total for Match 2', value: 'strTotal2'},
    {id: 45, label: 'Combined Team In', value: 'teamIn'},
    {id: 46, label: 'Combined Team Out', value: 'teamOut'},
    {id: 47, label: 'Combined Team Total', value: 'teamTotal'},
    {id: 48, label: 'Yards In', value: 'ydsIn'},
    {id: 49, label: 'Yards Out', value: 'ydsOut'},
    {id: 50, label: 'Yards Total', value: 'ydsTotal'},
    {id: 51, label: 'Hole 1 Par', value: 'par1'},
    {id: 52, label: 'Hole 2 Par', value: 'par2'},
    {id: 53, label: 'Hole 3 Par', value: 'par3'},
    {id: 54, label: 'Hole 4 Par', value: 'par4'},
    {id: 55, label: 'Hole 5 Par', value: 'par5'},
    {id: 56, label: 'Hole 6 Par', value: 'par6'},
    {id: 57, label: 'Hole 7 Par', value: 'par7'},
    {id: 58, label: 'Hole 8 Par', value: 'par8'},
    {id: 59, label: 'Hole 9 Par', value: 'par9'},
    {id: 60, label: 'Hole 10 Par', value: 'par10'},
    {id: 61, label: 'Hole 11 Par', value: 'par11'},
    {id: 62, label: 'Hole 12 Par', value: 'par12'},
    {id: 63, label: 'Hole 13 Par', value: 'par13'},
    {id: 64, label: 'Hole 14 Par', value: 'par14'},
    {id: 65, label: 'Hole 15 Par', value: 'par15'},
    {id: 66, label: 'Hole 16 Par', value: 'par16'},
    {id: 67, label: 'Hole 17 Par', value: 'par17'},
    {id: 68, label: 'Hole 18 Par', value: 'par18'},
    {id: 69, label: 'Hole 1 Strokes', value: 'str1'},
    {id: 70, label: 'Hole 2 Strokes', value: 'str2'},
    {id: 71, label: 'Hole 3 Strokes', value: 'str3'},
    {id: 72, label: 'Hole 4 Strokes', value: 'str4'},
    {id: 73, label: 'Hole 5 Strokes', value: 'str5'},
    {id: 74, label: 'Hole 6 Strokes', value: 'str6'},
    {id: 75, label: 'Hole 7 Strokes', value: 'str7'},
    {id: 76, label: 'Hole 8 Strokes', value: 'str8'},
    {id: 77, label: 'Hole 9 Strokes', value: 'str9'},
    {id: 78, label: 'Hole 10 Strokes', value: 'str10'},
    {id: 79, label: 'Hole 11 Strokes', value: 'str11'},
    {id: 80, label: 'Hole 12 Strokes', value: 'str12'},
    {id: 81, label: 'Hole 13 Strokes', value: 'str13'},
    {id: 82, label: 'Hole 14 Strokes', value: 'str14'},
    {id: 83, label: 'Hole 15 Strokes', value: 'str15'},
    {id: 84, label: 'Hole 16 Strokes', value: 'str16'},
    {id: 85, label: 'Hole 17 Strokes', value: 'str17'},
    {id: 86, label: 'Hole 18 Strokes', value: 'str18'},
    {id: 87, label: 'Hole 19 Strokes', value: 'str19'},
    {id: 88, label: 'Hole 20 Strokes', value: 'str20'},
    {id: 89, label: 'Hole 21 Strokes', value: 'str21'},
    {id: 90, label: 'Hole 22 Strokes', value: 'str22'},
    {id: 91, label: 'Hole 23 Strokes', value: 'str23'},
    {id: 92, label: 'Hole 24 Strokes', value: 'str24'},
    {id: 93, label: 'Hole 25 Strokes', value: 'str25'},
    {id: 94, label: 'Hole 26 Strokes', value: 'str26'},
    {id: 95, label: 'Hole 27 Strokes', value: 'str27'},
    {id: 96, label: 'Hole 28 Strokes', value: 'str28'},
    {id: 97, label: 'Hole 29 Strokes', value: 'str29'},
    {id: 98, label: 'Hole 30 Strokes', value: 'str30'},
    {id: 99, label: 'Hole 31 Strokes', value: 'str31'},
    {id: 100, label: 'Hole 32 Strokes', value: 'str32'},
    {id: 101, label: 'Hole 33 Strokes', value: 'str33'},
    {id: 102, label: 'Hole 34 Strokes', value: 'str34'},
    {id: 103, label: 'Hole 35 Strokes', value: 'str35'},
    {id: 104, label: 'Hole 36 Strokes', value: 'str36'},
    {id: 105, label: 'Hole 1 Score', value: 'scr1'},
    {id: 106, label: 'Hole 2 Score', value: 'scr2'},
    {id: 107, label: 'Hole 3 Score', value: 'scr3'},
    {id: 108, label: 'Hole 4 Score', value: 'scr4'},
    {id: 109, label: 'Hole 5 Score', value: 'scr5'},
    {id: 110, label: 'Hole 6 Score', value: 'scr6'},
    {id: 111, label: 'Hole 7 Score', value: 'scr7'},
    {id: 112, label: 'Hole 8 Score', value: 'scr8'},
    {id: 113, label: 'Hole 9 Score', value: 'scr9'},
    {id: 114, label: 'Hole 10 Score', value: 'scr10'},
    {id: 115, label: 'Hole 11 Score', value: 'scr11'},
    {id: 116, label: 'Hole 12 Score', value: 'scr12'},
    {id: 117, label: 'Hole 13 Score', value: 'scr13'},
    {id: 118, label: 'Hole 14 Score', value: 'scr14'},
    {id: 119, label: 'Hole 15 Score', value: 'scr15'},
    {id: 120, label: 'Hole 16 Score', value: 'scr16'},
    {id: 121, label: 'Hole 17 Score', value: 'scr17'},
    {id: 122, label: 'Hole 18 Score', value: 'scr18'},
    {id: 123, label: 'Hole 19 Score', value: 'scr19'},
    {id: 124, label: 'Hole 20 Score', value: 'scr20'},
    {id: 125, label: 'Hole 21 Score', value: 'scr21'},
    {id: 126, label: 'Hole 22 Score', value: 'scr22'},
    {id: 127, label: 'Hole 23 Score', value: 'scr23'},
    {id: 128, label: 'Hole 24 Score', value: 'scr24'},
    {id: 129, label: 'Hole 25 Score', value: 'scr25'},
    {id: 130, label: 'Hole 26 Score', value: 'scr26'},
    {id: 131, label: 'Hole 27 Score', value: 'scr27'},
    {id: 132, label: 'Hole 28 Score', value: 'scr28'},
    {id: 133, label: 'Hole 29 Score', value: 'scr29'},
    {id: 134, label: 'Hole 30 Score', value: 'scr30'},
    {id: 135, label: 'Hole 31 Score', value: 'scr31'},
    {id: 136, label: 'Hole 32 Score', value: 'scr32'},
    {id: 137, label: 'Hole 33 Score', value: 'scr33'},
    {id: 138, label: 'Hole 34 Score', value: 'scr34'},
    {id: 139, label: 'Hole 35 Score', value: 'scr35'},
    {id: 140, label: 'Hole 36 Score', value: 'scr36'},
    {id: 141, label: 'Hole 1 Yards', value: 'yds1'},
    {id: 142, label: 'Hole 2 Yards', value: 'yds2'},
    {id: 143, label: 'Hole 3 Yards', value: 'yds3'},
    {id: 144, label: 'Hole 4 Yards', value: 'yds4'},
    {id: 145, label: 'Hole 5 Yards', value: 'yds5'},
    {id: 146, label: 'Hole 6 Yards', value: 'yds6'},
    {id: 147, label: 'Hole 7 Yards', value: 'yds7'},
    {id: 148, label: 'Hole 8 Yards', value: 'yds8'},
    {id: 149, label: 'Hole 9 Yards', value: 'yds9'},
    {id: 150, label: 'Hole 10 Yards', value: 'yds10'},
    {id: 151, label: 'Hole 11 Yards', value: 'yds11'},
    {id: 152, label: 'Hole 12 Yards', value: 'yds12'},
    {id: 153, label: 'Hole 13 Yards', value: 'yds13'},
    {id: 154, label: 'Hole 14 Yards', value: 'yds14'},
    {id: 155, label: 'Hole 15 Yards', value: 'yds15'},
    {id: 156, label: 'Hole 16 Yards', value: 'yds16'},
    {id: 157, label: 'Hole 17 Yards', value: 'yds17'},
    {id: 158, label: 'Hole 18 Yards', value: 'yds18'},
    {id: 159, label: 'Hole 1 Up By', value: 'up1'},
    {id: 160, label: 'Hole 2 Up By', value: 'up2'},
    {id: 161, label: 'Hole 3 Up By', value: 'up3'},
    {id: 162, label: 'Hole 4 Up By', value: 'up4'},
    {id: 163, label: 'Hole 5 Up By', value: 'up5'},
    {id: 164, label: 'Hole 6 Up By', value: 'up6'},
    {id: 165, label: 'Hole 7 Up By', value: 'up7'},
    {id: 166, label: 'Hole 8 Up By', value: 'up8'},
    {id: 167, label: 'Hole 9 Up By', value: 'up9'},
    {id: 168, label: 'Hole 10 Up By', value: 'up10'},
    {id: 169, label: 'Hole 11 Up By', value: 'up11'},
    {id: 170, label: 'Hole 12 Up By', value: 'up12'},
    {id: 171, label: 'Hole 13 Up By', value: 'up13'},
    {id: 172, label: 'Hole 14 Up By', value: 'up14'},
    {id: 173, label: 'Hole 15 Up By', value: 'up15'},
    {id: 174, label: 'Hole 16 Up By', value: 'up16'},
    {id: 175, label: 'Hole 17 Up By', value: 'up17'},
    {id: 176, label: 'Hole 18 Up By', value: 'up18'},
    {id: 177, label: 'Hole 1 Win', value: 'win1'},
    {id: 178, label: 'Hole 2 Win', value: 'win2'},
    {id: 179, label: 'Hole 3 Win', value: 'win3'},
    {id: 180, label: 'Hole 4 Win', value: 'win4'},
    {id: 181, label: 'Hole 5 Win', value: 'win5'},
    {id: 182, label: 'Hole 6 Win', value: 'win6'},
    {id: 183, label: 'Hole 7 Win', value: 'win7'},
    {id: 184, label: 'Hole 8 Win', value: 'win8'},
    {id: 185, label: 'Hole 9 Win', value: 'win9'},
    {id: 186, label: 'Hole 10 Win', value: 'win10'},
    {id: 187, label: 'Hole 11 Win', value: 'win11'},
    {id: 188, label: 'Hole 12 Win', value: 'win12'},
    {id: 189, label: 'Hole 13 Win', value: 'win13'},
    {id: 190, label: 'Hole 14 Win', value: 'win14'},
    {id: 191, label: 'Hole 15 Win', value: 'win15'},
    {id: 192, label: 'Hole 16 Win', value: 'win16'},
    {id: 193, label: 'Hole 17 Win', value: 'win17'},
    {id: 194, label: 'Hole 18 Win', value: 'win18'},
    {id: 195, label: 'Hole 1 Team Strokes', value: 'team1'},
    {id: 196, label: 'Hole 2 Team Strokes', value: 'team2'},
    {id: 197, label: 'Hole 3 Team Strokes', value: 'team3'},
    {id: 198, label: 'Hole 4 Team Strokes', value: 'team4'},
    {id: 199, label: 'Hole 5 Team Strokes', value: 'team5'},
    {id: 200, label: 'Hole 6 Team Strokes', value: 'team6'},
    {id: 201, label: 'Hole 7 Team Strokes', value: 'team7'},
    {id: 202, label: 'Hole 8 Team Strokes', value: 'team8'},
    {id: 203, label: 'Hole 9 Team Strokes', value: 'team9'},
    {id: 204, label: 'Hole 10 Team Strokes', value: 'team10'},
    {id: 205, label: 'Hole 11 Team Strokes', value: 'team11'},
    {id: 206, label: 'Hole 12 Team Strokes', value: 'team12'},
    {id: 207, label: 'Hole 13 Team Strokes', value: 'team13'},
    {id: 208, label: 'Hole 14 Team Strokes', value: 'team14'},
    {id: 209, label: 'Hole 15 Team Strokes', value: 'team15'},
    {id: 210, label: 'Hole 16 Team Strokes', value: 'team16'},
    {id: 211, label: 'Hole 17 Team Strokes', value: 'team17'},
    {id: 212, label: 'Hole 18 Team Strokes', value: 'team18'},
]

const VL_DB_FIELDS_SEARCH_PLAYERS = [
    {id: 1, label: 'Name', value: 'name'},
    {id: 2, label: 'Nickname', value: 'nickname'},
    {id: 3, label: 'Maiden Name', value: 'maidenName'},
    {id: 4, label: 'Current City', value: 'city'},
    {id: 5, label: 'Current State', value: 'state'},
    {id: 6, label: 'Current Country', value: 'country'},
    {id: 7, label: 'Birth City', value: 'birthCity'},
    {id: 8, label: 'Birth State', value: 'birthState'},
	{id: 9, label: 'Birth Country', value: 'birthCountry'},
    {id: 10, label: 'Home Club', value: 'homeClub'},
    {id: 11, label: 'Home Club City', value: 'homeClubCity'},
    {id: 12, label: 'Home Club State', value: 'homeClubState'},
    {id: 13, label: 'Championships Played', value: 'championshipsPlayed'},
	{id: 14, label: 'School Attended', value: 'schoolName'},
    {id: 15, label: 'Major/Field of Study', value: 'majorNames'},
    {id: 16, label: 'Year Graduated', value: 'graduationYear'},
    {id: 17, label: 'Occupation', value: 'occupation'}
]


const VL_DB_FIELDS_SEARCH_CHAMP = [
    {id: 1, label: 'Name', value: 'name'},
    {id: 2, label: 'City', value: 'city'},
    {id: 3, label: 'State', value: 'state'},
    {id: 4, label: 'Country', value: 'country'},
    {id: 5, label: 'Dates', value: 'dates'},
    {id: 6, label: 'Venue', value: 'location'},
    {id: 7, label: 'Winner', value: 'winner'},
    {id: 8, label: 'Field Size', value: 'fieldSize'}
]

const VL_DB_FIELDS_SEARCH_VENUE = [
    {id: 1, label: 'Name', value: 'name'},
    {id: 2, label: 'City', value: 'city'},
    {id: 3, label: 'State', value: 'state'},
    {id: 4, label: 'Country', value: 'country'}
]

const VL_DB_FIELDS_IMPORT = [
	// shared fields
    {id: 1,   label: 'City', value: 'city'},
    {id: 2,   label: 'State', value: 'state'},
    {id: 3,   label: 'Country', value: 'country'},
    {id: 4,   label: 'Date', value: 'date'},
    {id: 5,   label: 'Player Name', value: 'name'},
    {id: 5,   label: 'Player First Name', value: 'nameFirst'},
    {id: 5,   label: 'Player Middle Name', value: 'nameMiddle'},
    {id: 5,   label: 'Player Last Name', value: 'nameLast'},
    {id: 5,   label: 'Player Name Suffix', value: 'nameSuffix'},
    {id: 6,   label: 'Championship Name', value: 'name'},
    {id: 7,   label: 'Venue Name', value: 'name'},
    {id: 8,   label: 'Image', value: 'img'},

	// Round fields
    {id: 9,   label: 'Position', value: 'rank'},
    {id: 10,  label: 'Rank', value: 'rank'},
    {id: 11,  label: 'Par In', value: 'parIn'},
    {id: 12,  label: 'Par Out', value: 'parOut'},
    {id: 13,  label: 'Par Total', value: 'parTotal'},
    {id: 14,  label: 'Strokes In', value: 'strIn'},
    {id: 15,  label: 'Strokes Out', value: 'strOut'},
    {id: 16,  label: 'Strokes Total', value: 'strTotal'},
    {id: 17,  label: 'Yards In', value: 'ydsIn'},
    {id: 18,  label: 'Yards Out', value: 'ydsOut'},
    {id: 19,  label: 'Yards Total', value: 'ydsTotal'},
    {id: 20,  label: 'Hole 1 Strokes', value: 'str1'},
    {id: 21,  label: 'Hole 2 Strokes', value: 'str2'},
    {id: 22,  label: 'Hole 3 Strokes', value: 'str3'},
    {id: 23,  label: 'Hole 4 Strokes', value: 'str4'},
    {id: 24,  label: 'Hole 5 Strokes', value: 'str5'},
    {id: 25,  label: 'Hole 6 Strokes', value: 'str6'},
    {id: 26,  label: 'Hole 7 Strokes', value: 'str7'},
    {id: 27,  label: 'Hole 8 Strokes', value: 'str8'},
    {id: 28,  label: 'Hole 9 Strokes', value: 'str9'},
    {id: 29,  label: 'Hole 10 Strokes', value: 'str10'},
    {id: 30,  label: 'Hole 11 Strokes', value: 'str11'},
    {id: 31,  label: 'Hole 12 Strokes', value: 'str12'},
    {id: 32,  label: 'Hole 13 Strokes', value: 'str13'},
    {id: 33,  label: 'Hole 14 Strokes', value: 'str14'},
    {id: 34,  label: 'Hole 15 Strokes', value: 'str15'},
    {id: 35,  label: 'Hole 16 Strokes', value: 'str16'},
    {id: 36,  label: 'Hole 17 Strokes', value: 'str17'},
    {id: 37,  label: 'Hole 18 Strokes', value: 'str18'},
    {id: 38,  label: 'Hole 19 Strokes', value: 'str19'},
    {id: 39,  label: 'Hole 20 Strokes', value: 'str20'},
    {id: 40,  label: 'Hole 21 Strokes', value: 'str21'},
    {id: 41,  label: 'Hole 22 Strokes', value: 'str22'},
    {id: 42,  label: 'Hole 23 Strokes', value: 'str23'},
    {id: 43,  label: 'Hole 24 Strokes', value: 'str24'},
    {id: 44,  label: 'Hole 25 Strokes', value: 'str25'},
    {id: 45,  label: 'Hole 26 Strokes', value: 'str26'},
    {id: 46,  label: 'Hole 27 Strokes', value: 'str27'},
    {id: 47,  label: 'Hole 28 Strokes', value: 'str28'},
    {id: 48,  label: 'Hole 29 Strokes', value: 'str29'},
    {id: 49,  label: 'Hole 30 Strokes', value: 'str30'},
    {id: 50,  label: 'Hole 31 Strokes', value: 'str31'},
    {id: 51,  label: 'Hole 32 Strokes', value: 'str32'},
    {id: 52,  label: 'Hole 33 Strokes', value: 'str33'},
    {id: 53,  label: 'Hole 34 Strokes', value: 'str34'},
    {id: 54,  label: 'Hole 35 Strokes', value: 'str35'},
    {id: 55,  label: 'Hole 36 Strokes', value: 'str36'},

	// player profile fields
	{id: 56,  label: 'Player ID', value: 'playerId'},
	{id: 56,  label: 'Bio ID', value: 'bioId'},
	{id: 57,  label: 'Maiden Name', value: 'maidenName'},
    {id: 58,  label: 'Nickname', value: 'nickname'},
    {id: 59,  label: 'Countries of Citizenship', value: 'citizenshipCountries'},
	{id: 60,  label: 'Date of Birth', value: 'date'},
    {id: 61,  label: 'Preferred City', value: 'city'},
    {id: 62,  label: 'Preferred State', value: 'state'},
    {id: 63,  label: 'Preferred Country', value: 'country'},
    {id: 64,  label: 'Birth City', value: 'birthCity'},
    {id: 65,  label: 'Birth State', value: 'birthState'},
    {id: 66,  label: 'Birth Country', value: 'birthCountry'},
    {id: 67,  label: 'Email', value: 'email'},
    {id: 68,  label: 'Instagram', value: 'instagram'},
    {id: 69,  label: 'Twitter', value: 'twitter'},
    {id: 70,  label: 'Name of School', value: 'schoolName'},
    {id: 71,  label: 'Year Graduated', value: 'graduationYear'},
    {id: 72,  label: 'Major/Field of Study', value: 'majorNames'},
    {id: 73,  label: 'Occupation', value: 'occupation'},
    {id: 74,  label: 'Company', value: 'company'},
    {id: 75,  label: 'Interests', value: 'interests'},
    {id: 76,  label: 'About', value: 'about'},
    {id: 77,  label: 'Age Started Golfing', value: 'startAge'},
    {id: 78,  label: 'Influences', value: 'influencedBy'},
    {id: 79,  label: 'Left Handed', value: 'leftHanded'},
    {id: 80,  label: 'Made Hole in One', value: 'holeInOne'},
    {id: 81,  label: 'Hole in One Details', value: 'holeInOneDetails'},
    {id: 82,  label: 'Junior Golf Participant', value: 'juniorParticipant'},
    {id: 83,  label: 'Junior Golf Program Details', value: 'juniorProgramName'},
    {id: 84,  label: 'Reinstated Amateur', value: 'reinstatedAmateur'},
    {id: 85,  label: 'Reinstated Amateur Date', value: 'reinstatedAmateurDate'},
    {id: 86,  label: 'School Golf Team Participant', value: 'schoolGolfTeamParticipant'},
    {id: 87,  label: 'School Golf Team Details', value: 'schoolGolfTeamsList'},
    {id: 88,  label: 'Non-USGA Playing Record', value: 'recordOther'},
    {id: 89,  label: 'USGA Playing Record', value: 'recordUSGA'},
    {id: 90,  label: 'Lowest Competitive Score', value: 'lowestScore'},
    {id: 91,  label: 'Honors/Highlights/Awards', value: 'awards'},
    {id: 92,  label: 'Parter Details', value: 'partner'},
    {id: 93,  label: 'Family Details', value: 'familyMembers'},
    {id: 94,  label: 'USGA Family Members', value: 'usgaFamilyMembers'},
]

const VL_PLAYER_EXPORT_FIELDS = [
    {id: 1, label: 'Player ID', value: 'playerId'},
    {id: '1a', label: 'Bio ID', value: 'bioId'},
    {id: 2, label: 'City', value: 'city'},
    {id: 3, label: 'State', value: 'state'},
    {id: 4, label: 'Country', value: 'country'},
    {id: 5, label: 'Date', value: 'date'},
    {id: '5a', label: 'Player Birthday', value: 'date'},
    {id: 6, label: 'Player Name', value: 'name'},
    {id: 7, label: 'Image', value: 'img'},
    {id: 8, label: 'Location', value: 'location'},
    {id: 9, label: 'Secondary Location', value: 'secondaryLocation'},
    {id: 10, label: 'Winner', value: 'winner'},
    {id: 11, label: 'Entry Count', value: 'entries'},
    {id: 12, label: 'Field Size', value: 'fieldSize'},
    {id: 13, label: 'Home Club', value: 'homeClub'},
    {id: 14, label: 'Home Club City', value: 'homeClubCity'},
    {id: 15, label: 'Home Club State', value: 'homeClubState'},
    {id: 16, label: 'Championships Played', value: 'championshipsPlayed'},
	{id: 17, label: 'Maiden Name', value: 'maidenName'},
    // {id: 18, label: '', value: 'registrationId'},
    // {id: 19, label: '', value: 'userId'},
    {id: 20, label: 'Email', value: 'email'},
    {id: 21, label: 'Twitter', value: 'twitter'},
    {id: 22, label: 'Instagram', value: 'instagram'},
    {id: 23, label: 'School Name', value: 'schoolName'},
    {id: 24, label: 'Major', value: 'majorNames'},
    {id: 25, label: 'Graduation Year', value: 'graduationYear'},
    {id: 26, label: 'Occupation', value: 'occupation'},
    {id: 27, label: 'Company', value: 'company'},
    {id: 28, label: 'Family Members', value: 'familyMembers'},
    // {id: 29, label: '', value: 'usgaFamilyMembers'},
    {id: 30, label: 'Interests', value: 'interests'},
    // {id: 31, label: '', value: 'about'},
    // {id: 32, label: '', value: 'schoolGolfTeamParticipant'},
    // {id: 33, label: '', value: 'schoolGolfTeamsList'},
    // {id: 34, label: '', value: 'startAge'},
    // {id: 35, label: '', value: 'influencedBy'},
    // {id: 36, label: '', value: 'leftHanded'},
    // {id: 37, label: '', value: 'holeInOne'},
    // {id: 38, label: '', value: 'holeInOneDetails'},
    // {id: 39, label: '', value: 'recordUSGA'},
    // {id: 40, label: '', value: 'recordOther'},
    // {id: 41, label: '', value: 'lowestScore'},
    // {id: 42, label: '', value: 'juniorParticipant'},
    // {id: 43, label: '', value: 'juniorProgramName'},
    {id: 44, label: 'Awards', value: 'awards'},
    // {id: 45, label: '', value: 'partner'},
    // {id: 46, label: '', value: 'reinstatedAmateur'},
    // {id: 47, label: '', value: 'reinstatedAmateurDate'    },
    {id: 48, label: 'Player Profile Updated At', value: 'playerProfileUpdateAt'},
    {id: 49, label: 'Player Profile Confirmed At', value: 'playerProfileConfirmedAt'},
    // {id: 17, label: 'Player Name', value: 'playerName'},
  
]


const VL_DB_FIELDS_PROFILE = [
    {id: 1, label: 'Name', value: 'name'},
    {id: 2, label: 'Maiden Name', value: 'maidenName'},
    {id: 3, label: 'Nickname', value: 'nickname'},
    {id: 4, label: 'Date of Birth', value: 'date'},
    {id: 5, label: 'All Countries of Citizenship', value: 'citizenshipCountries'},
    {id: 6, label: 'Preferred City', value: 'city'},
    {id: 7, label: 'Preferred State', value: 'state'},
    {id: 8, label: 'Preferred Country', value: 'country'},
    {id: 9, label: 'Birth City', value: 'birthCity'},
    {id: 10, label: 'Birth State', value: 'birthState'},
    {id: 11, label: 'Birth Country', value: 'birthCountry'},
    {id: 12, label: 'Email', value: 'email'},
    {id: 13, label: 'Instagram', value: 'instagram'},
    {id: 14, label: 'Twitter', value: 'twitter'},
    {id: 15, label: 'Name of School', value: 'schoolName'},
    {id: 16, label: 'Year Graduated', value: 'graduationYear'},
    {id: 17, label: 'Major/Field of Study', value: 'majorNames'},
    {id: 18, label: 'Occupation', value: 'occupation'},
    {id: 19, label: 'Company', value: 'company'},
    {id: 20, label: 'Interests', value: 'interests'},
    {id: 21, label: 'About', value: 'about'},
    {id: 22, label: 'Age Started Golfing', value: 'startAge'},
    {id: 23, label: 'Influences', value: 'influencedBy'},
    {id: 24, label: 'Left Handed', value: 'leftHanded'},
    {id: 25, label: 'Made Hole in One', value: 'holeInOne'},
    {id: 26, label: 'Hole in One Details', value: 'holeInOneDetails'},
    {id: 27, label: 'Junior Golf Participant', value: 'juniorParticipant'},
    {id: 28, label: 'Junior Golf Program Details', value: 'juniorProgramName'},
    {id: 29, label: 'Reinstated Amateur', value: 'reinstatedAmateur'},
    {id: 30, label: 'Reinstated Amateur Date', value: 'reinstatedAmateurDate'},
    {id: 31, label: 'School Golf Team Participant', value: 'schoolGolfTeamParticipant'},
    {id: 32, label: 'School Golf Team Details', value: 'schoolGolfTeamsList'},
    {id: 33, label: 'Non-USGA Playing Record', value: 'recordOther'},
    {id: 34, label: 'USGA Playing Record', value: 'recordUSGA'},
    {id: 35, label: 'Lowest Competitive Score', value: 'lowestScore'},
    {id: 36, label: 'Honors/Highlights/Awards', value: 'awards'},
    {id: 37, label: 'Parter Details', value: 'partner'},
    {id: 38, label: 'Family Details', value: 'familyMembers'},
    {id: 39, label: 'USGA Family Members', value: 'usgaFamilyMembers'},
    {id: 40, label: 'USGA Championship History', value: 'usgaChampionshipRecord'},
]

exports.VL_DB_FIELDS_PROFILE = VL_DB_FIELDS_PROFILE
exports.VL_IMPORT_TYPES = VL_IMPORT_TYPES
exports.VL_IMPORT_AS = VL_IMPORT_AS
exports.VL_DB_FIELDS = VL_DB_FIELDS
exports.VL_DB_FIELDS_IMPORT = VL_DB_FIELDS_IMPORT
exports.VL_ROUNDS_STROKE_PLAY = VL_ROUNDS_STROKE_PLAY
exports.VL_ROUNDS_MATCH_PLAY = VL_ROUNDS_MATCH_PLAY
exports.VL_ROUNDS_TEAM_PLAY = VL_ROUNDS_TEAM_PLAY
exports.VL_PLAYER_EXPORT_FIELDS = VL_PLAYER_EXPORT_FIELDS
exports.VL_CHAMP_EXPORT_FIELDS = VL_PLAYER_EXPORT_FIELDS
exports.VL_VENUE_EXPORT_FIELDS = VL_PLAYER_EXPORT_FIELDS
exports.VL_DB_FIELDS_SEARCH_PLAYERS = VL_DB_FIELDS_SEARCH_PLAYERS
exports.VL_DB_FIELDS_SEARCH_CHAMP = VL_DB_FIELDS_SEARCH_CHAMP
exports.VL_DB_FIELDS_SEARCH_VENUE = VL_DB_FIELDS_SEARCH_VENUE
export const getAllPlayersOrig = /* GraphQL */ `
  query NodeByTargetType(
    $type: String!
    $name: ModelStringKeyConditionInput
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByTargetType(
      type: $type
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        country
        name
		email
        createdAt
        createdBySource
      }
      nextToken
    }
  }
`;
export const getAllPlayers = /* GraphQL */ `
  query NodeByTargetTypeSortedByCreatedAt(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
	nodeByTargetTypeSortedByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        country
        countryCode
        name
		    mostRecentChampionship
		    registrationId
        email
        createdAt
		    updatedAt
		    playerProfileUpdateAt
        createdBySource
        nameFirst
        nameLast
        champCode
      }
      nextToken
    }
  }
`;

export const getAllPlayersNeedEmail = /* GraphQL */ `
  query NodeByNeedsPlayerProfileEmailSentSortedByDate(
    $needsPlayerProfileEmailSent: Int!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByNeedsPlayerProfileEmailSentSortedByDate(
      needsPlayerProfileEmailSent: $needsPlayerProfileEmailSent
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        country
        name
        email
        mostRecentChampionship
        registrationId
        signUpEmailSent
        createdAt
        updatedAt
        playerProfileUpdateAt
        createdBySource
        nameFirst
        nameLast
        champCode
		emails(sortDirection: DESC) {
			items {
				createdAt
			}
		}
      }
      nextToken
    }
  }
`;

export const getAllChampionships = /* GraphQL */ `
  query NodeByTargetType(
    $type: String!
    $name: ModelStringKeyConditionInput
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByTargetType(
      type: $type
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        name
      }
      nextToken
    }
  }
`;

export const byTargetTypeSortedFirstName = /* GraphQL */ `
  query ByTargetTypeSortedFirstName(
    $type: String!
    $nameFirst: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTargetTypeSortedFirstName(
      type: $type
      nameFirst: $nameFirst
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        country
        countryCode
        name
		    mostRecentChampionship
		    registrationId
        email
        createdAt
		    updatedAt
		    playerProfileUpdateAt
        createdBySource
        nameFirst
        nameLast
        champCode
      }
      nextToken
    }
  }
`;

export const byTargetTypeSortedLastName = /* GraphQL */ `
  query ByTargetTypeSortedLastName(
    $type: String!
    $nameLast: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTargetTypeSortedLastName(
      type: $type
      nameLast: $nameLast
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        country
        countryCode
        name
		    mostRecentChampionship
		    registrationId
        email
        createdAt
		    updatedAt
		    playerProfileUpdateAt
        createdBySource
        nameFirst
        nameLast
        champCode
      }
      nextToken
    }
  }
`;

export const byNeedsPlayerProfileEmailSentSortedByFirstName = /* GraphQL */ `
  query ByNeedsPlayerProfileEmailSentSortedByFirstName(
    $needsPlayerProfileEmailSent: Int!
    $nameFirst: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byNeedsPlayerProfileEmailSentSortedByFirstName(
      needsPlayerProfileEmailSent: $needsPlayerProfileEmailSent
      nameFirst: $nameFirst
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        country
        name
        email
        mostRecentChampionship
        registrationId
        signUpEmailSent
        createdAt
        updatedAt
        playerProfileUpdateAt
        createdBySource
        nameFirst
        nameLast
        champCode
		emails(sortDirection: DESC) {
			items {
			createdAt
			}
		}
      }
      nextToken
    }
  }
`;

export const byNeedsPlayerProfileEmailSentSortedByLastName = /* GraphQL */ `
  query ByNeedsPlayerProfileEmailSentSortedByLastName(
    $needsPlayerProfileEmailSent: Int!
    $nameLast: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byNeedsPlayerProfileEmailSentSortedByLastName(
      needsPlayerProfileEmailSent: $needsPlayerProfileEmailSent
      nameLast: $nameLast
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        country
        name
        email
        mostRecentChampionship
        registrationId
        signUpEmailSent
        createdAt
        updatedAt
        playerProfileUpdateAt
        createdBySource
        nameFirst
        nameLast
        champCode
		emails(sortDirection: DESC) {
			items {
				createdAt
			}
		}
      }
      nextToken
    }
  }
`;

export const listChampcodes = /* GraphQL */ `
  query ListChampcodes(
    $id: ID
    $filter: ModelChampcodesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChampcodes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        code
        name
		isActive
      }
      nextToken
    }
  }
`;

export const byYear = /* GraphQL */ `
  query ByYear(
    $allChampYears: Int!
    $year: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelchampYearsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byYear(
      allChampYears: $allChampYears
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        year
      }
      nextToken
    }
  }
`;

export const byTargetTypeSortedMostRecentChampionship = /* GraphQL */ `
  query ByTargetTypeSortedMostRecentChampionship(
    $type: String!
    $mostRecentChampionship: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTargetTypeSortedMostRecentChampionship(
      type: $type
      mostRecentChampionship: $mostRecentChampionship
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        country
        countryCode
        name
		    mostRecentChampionship
		    registrationId
        email
        createdAt
		    updatedAt
		    playerProfileUpdateAt
        createdBySource
        nameFirst
        nameLast
        champCode
      }
      nextToken
    }
  }
`;

export const byNeedsPlayerProfileEmailSentSortedByMostRecentChampionship = /* GraphQL */ `
  query ByNeedsPlayerProfileEmailSentSortedByMostRecentChampionship(
    $needsPlayerProfileEmailSent: Int!
    $mostRecentChampionship: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byNeedsPlayerProfileEmailSentSortedByMostRecentChampionship(
      needsPlayerProfileEmailSent: $needsPlayerProfileEmailSent
      mostRecentChampionship: $mostRecentChampionship
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        country
        name
        email
        mostRecentChampionship
        registrationId
        signUpEmailSent
        createdAt
        updatedAt
        playerProfileUpdateAt
        createdBySource
        nameFirst
        nameLast
        champCode
		emails(sortDirection: DESC) {
			items {
				createdAt
			}
		}
      }
      nextToken
    }
  }
`;
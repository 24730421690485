import { FlagIcon } from '@heroicons/vue/24/outline'
import { Nav } from '@/types' // must be imported since file contains an export statement

export const nav: Nav = { 
    name: 'Championships', 
    to: {name: 'Championships'}, 
    icon: FlagIcon, 
    position: 0,
    show: true,
    module: 'championships'
}
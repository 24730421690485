<template>
    <!-- :class in div calculates where to place border based on whether mathcup is mod 2 or not -->
    <div v-for="(entry, i) in matchupInfo" :key="entry"
        :class="[
            {'border-t-2 rounded-tr-md' : ((matchupIndex % 2 == 0) && i && columnIndex != columnCount-1)},
            {'border-t-2 mr-20' : ((matchupIndex % 2 == 0) && i && columnIndex == columnCount-1)},
            {'border-b-2 rounded-br-md' : ((matchupIndex % 2 == 1) && !i)},
            {'border-r-2' : (((matchupIndex % 2 == 0) && i) || (((matchupIndex % 2 == 1) && !i))) && columnIndex != columnCount - 1},
            {'mr-16 rounded-tr-none' : columnIndex == columnCount-1 }
        ]"
        :style="[
        (columnIndex) ? ((i) ? (paddingBottom) : (paddingTop)) : ((i) ? (paddingBottomFirst) : (paddingTopFirst)),
        ]"    
    >
        <tw-bracketentry :players="entry.playerName" :rank="entry.rank" :winner="entry.wonRound" :score="entry.score" :entryIndex="i" :columnIndex="columnIndex" :columnCount="columnCount"></tw-bracketentry>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: [
        'matchupInfo',
        'matchupIndex',
        'columnIndex',
        'columnCount',
    ],
    setup(props) {

        //Height of one bracket entry. Used to find middle of matchup to evenly space matchups using 2^x
        const bracketEntryHeight = 48

        //Calculate padding needed between matchups in column based on which column they are in
        const paddingBottomFirst = 'padding-bottom: ' + (80 * Math.pow(2, props.columnIndex) - bracketEntryHeight) + 'px;'
        const paddingTopFirst = 'padding-top: ' + (80 * Math.pow(2, props.columnIndex) - bracketEntryHeight) + 'px;'
        const paddingBottom = 'padding-bottom: ' + (80 * (Math.pow(2, props.columnIndex)) - bracketEntryHeight) + 'px;'
        const paddingTop = 'padding-top: ' + (80 * (Math.pow(2, props.columnIndex)) - bracketEntryHeight) + 'px;'


        // function getPaddingTop 

        return {
            paddingTopFirst,
            paddingBottomFirst,
            paddingTop,
            paddingBottom,
        }
    },
})
</script>
export const listSavedSearchByUser = /* GraphQL */ `
  query ListSearches(
    $id: ID
    $filter: ModelSearchFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSearches(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        user
        name
        description
        type
        createdAt
        criteria
      }
      nextToken
    }
  }
`;

export const nodeByTargetTypeP = /* GraphQL */ `
  query NodeByTargetType(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByTargetType(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        country
        name
        mostRecentChampionship
        championshipsPlayed
        nameFirst
        nameLast
      }
      nextToken
    }
  }
`;

export const nodeByTargetTypeC = /* GraphQL */ `
  query NodeByTargetType(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByTargetType(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        city
        country
        state
        dates
        location
        name
      }
      nextToken
    }
  }
`;

export const nodeByTargetTypeV = /* GraphQL */ `
  query NodeByTargetType(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByTargetType(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        city
        country
        state
        name
      }
      nextToken
    }
  }
`;

export const byTargetTypeSortedFirstName = /* GraphQL */ `
  query ByTargetTypeSortedFirstName(
    $type: String!
    $nameFirst: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTargetTypeSortedFirstName(
      type: $type
      nameFirst: $nameFirst
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        country
        name
        mostRecentChampionship
        championshipsPlayed
        nameFirst
        nameLast
      }
      nextToken
    }
  }
`;
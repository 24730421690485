export const updateChampionshipInfo = /* GraphQL */ `
  mutation UpdateNode(
    $input: UpdateNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    updateNode(input: $input, condition: $condition) {
      dates
      location
      winner
      entries
      fieldSize
    }
  }
`;
export const updateRoundScores = /* GraphQL */ `
  mutation UpdateNode(
    $input: UpdateNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    updateNode(input: $input, condition: $condition) {
      date
      championshipsPlayed
      name
      city
      country
    }
  }
`;
<template>
    <tw-full-loading v-if="step2Loading">
        Finding available matches. This may take a moment.
    </tw-full-loading>
    <tw-heading title="Import Data" :subtitle="{description: importName}"></tw-heading>
    <teleport to="#notification">
        <tw-notification 
        v-if="showNotification" 
        @close="showNotification = false" 
        :title="notificationTitle" 
        :subtitle="notificationSubtitle" 
        :duration="8000" 
        :icon="notificationIcon"/>
    </teleport>
    
    <!-- Modal -->
    <TransitionRoot as="template" :show="saveImportModalOpen">
        <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="saveImportModalOpen = false">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="saveImportModalOpen = false">
                        <span class="sr-only">Close</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                    </div>
                    
                    <div class="mt-3 text-center sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">{{ saveImportSettingsText }}</DialogTitle>
                        <div class="mt-2 mx-auto">
                            <tw-input v-model="saveImportSettingsName" label="Name" :required="true" ></tw-input>
                        </div>
                        <div v-if="savedImportSettings?.id" class="mt-2">
                            <div>
                                The settings for <span class=" font-bold">{{ savedImportSettings.name }}</span> were last updated on <span class=" font-bold">{{ new Date(savedImportSettings.updatedAt).toDateString() }}</span>. Do you want to overwrite these settings with the current configuration?
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row justify-end space-x-4">
                        <tw-button
                            class="button btn-base btn-white flex-1 sm:flex-none sm:w-24" 
                            :rounded="true" 
                            @click="saveImportModalOpen = false">
                                Cancel
                        </tw-button>
                        <tw-button
                            class="button btn-base btn-red flex-1 sm:flex-none sm:w-24" 
                            :rounded="true"
                            @click="saveImportSettings">
                                Save
                        </tw-button>
                    </div>
                </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>

    <!-- progress bar -->
    <div class="mx-6 py-4 mb-4 border-b border-usgaLightGray">
        <nav aria-label="Progress">
            <ol role="list" class="space-y-4 md:flex md:space-y-0 md:space-x-8">
                <li v-for="(step, i) in steps" :key="i" class="md:flex-1">
                    <div v-if="step.status === 'complete'" :href="step.href" class="group pl-4 py-2 flex flex-col border-l-4 border-red-500  md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
                        <span class="text-xs text-red-600 font-semibold tracking-wide uppercase">{{ step.id }}</span>
                        <span class="text-sm font-medium">{{ step.name }}</span>
                    </div>
                    <div v-else-if="step.status === 'current'" :href="step.href" class="pl-4 py-2 flex flex-col border-l-4 border-red-500 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step">
                        <span class="text-xs text-red-600 font-semibold tracking-wide uppercase">{{ step.id }}</span>
                        <span class="text-sm font-medium">{{ step.name }}</span>
                    </div>
                    <div v-else :href="step.href" class="group pl-4 py-2 flex flex-col border-l-4 border-gray-200  md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
                        <span class="text-xs text-gray-500 font-semibold tracking-wide uppercase ">{{ step.id }}</span>
                        <span class="text-sm font-medium">{{ step.name }}</span>
                    </div>
                </li>
            </ol>
        </nav>
    </div>

    <div class="flex-1">
        <!-- Upload File step -->
        <div v-if="step == 0">
            <div class="grid grid-cols-1 lg:grid-cols-2 space-y-4 lg:space-x-4 lg:space-y-0">
                <div class="col-span-1 px-6 lg:pr-0">
                    <span class="block text-sm font-medium text-gray-700">File</span>
                    <div class="mt-1 stealthFileContainer" ref="drop" @dragover.prevent @drop.prevent>
                        <label 
                            for="FileUpload" 
                            :class="{'bg-usgaLightGray border-solid' : file != null, 'border-dashed' : file === null}"
                            class= "fileContainer mx-auto relative h-56 border-2 border-gray-300 rounded-sm p-12 text-center hover:border-gray-400 cursor-pointer box-content flex justify-center align-middle">
                            <div class="p-16" v-if="file === null">
                                <DocumentAddIcon class="mx-auto h-12 w-12 text-gray-900" aria-hidden="true"></DocumentAddIcon>
                                <div class="mt-2 block text-sm font-medium text-gray-900"> Drop file here or click to choose </div>
                            </div>
                            <div v-else class ="block mx-auto my-auto text-sm font-medium text-gray-900">
                                <DocumentReportIcon class="mx-auto h-12 w-12 text-gray-900" aria-hidden="true"></DocumentReportIcon>
                                <XIcon @click.prevent="removeFile" class="h-6 w-6 cursor-pointer absolute top-4 right-4 z-30" aria-hidden="true"/>
                                <span class="font-sans">{{ file.name }}</span>
                                <span v-if="file.sizeMb >= 1" class="text-center block">{{ file.sizeMb }} MB</span>
                                <span v-else-if="file.sizeMb < 1" class="text-center block">{{ file.sizeKb }} KB</span>
                            </div>
                            <div class="h-full w-full absolute top-0 left-0 text-black text-3xl p-24" :class="{'bg-usgaGray opacity-100 bg-opacity-90 rounded-sm z-20': isOver, 'z-0  opacity-0 ': !isOver}">
                                Drop file here
                            </div>
                            <input type="file" id="FileUpload" mname="upload" accept=".xlsx, .xls, .csv" @change="fileUpload($event)" class="hidden">
                        </label>
                    </div>
                </div>

                <div class="col-span-1 px-6 lg:pl-0 space-y-4">
                    <tw-input 
                        v-model="importName"
                        label="Import Name"
                        id="importName"/>
                    <tw-selectmenu 
                        v-model="savedImportSettings" 
                        @change="setSavedImportSettings"
                        :clearable="true" 
                        :filterable="true"
                        label="Saved Import Settings" 
                        :options="vl_savedImportSettings" />
                    <tw-selectmenu 
                        v-model="importType"
                        label="Import Type" 
                        :options="VL_IMPORT_TYPES" />
                    <tw-selectmenu 
                        v-model="importAs"
                        class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 2xl:col-span-3"
                        label="Import As"
                        :options="VL_IMPORT_AS"/>
                    <div v-show="showChampAndVenueOptions">
                        <Transition name="slide-fade">
                            <div v-if="existingChampionship">
                                <span @click="changeChampionshipInput" class="link text-sm float-right flex justify-center">
                                    <ArrowSmLeftIcon class="h-4 w-4 mt-1"/>
                                    <span>Create New</span>
                                </span>
                                <tw-selectmenu v-model="championship" :clearable="true" :filterable="true" label="Championship (Select Existing)" :options="vl_championships" />
                            </div>
                        </Transition>
                        <Transition name="slide-fade">
                            <div v-if="!existingChampionship">
                                <span @click="changeChampionshipInput" class="link text-sm float-right flex justify-center" >
                                    <ArrowSmLeftIcon class="h-4 w-4 mt-1"/>
                                    <span>Select Existing</span>
                                </span>
								<div class="flex w-full space-x-4">
									<tw-input 
										v-model="championship.year"  
										class="w-44"
										placeholder="Year"
										label="Championship Year"
										:required="false"></tw-input>
									<tw-selectmenu 
										v-model="championship.code"  
										class="w-full"
										label="Championship Type" 
										placeholder="Select a championship type"
										:clearable="true" 
										:filterable="true"
										:options="vl_champcodes"></tw-selectmenu>

								</div>
                            </div>
                        </Transition>
                        <tw-selectmenu 
                            v-model="round" 
                            label="Select round" 
                            :options="vl_round_options"
                            class="mt-4"/>
                        <tw-input 
                            v-model="roundDate"
                            label="Round Date"
                            @input="validateRoundDate()"
                            id="roundDate"
                            :isInvalid="invalidRoundDate"
                            validation="Please enter a valid date"
                            class="mt-4"/>
                    </div>
                    <div v-show="showChampAndVenueOptions" class="space-y-4">
                        <template v-for="(venue, i) in venues" :key="i">
                            <Transition name="slide-fade">
                                <div v-if="existingVenue[i]">
                                    <span @click="changeVenueInput(i)" class="link text-sm float-right flex justify-center">
                                        <ArrowSmLeftIcon class="h-4 w-4 mt-1"/>
                                        <span>Create New</span>
                                    </span>
                                    <tw-selectmenu v-model="venues[i]" :clearable="true" :filterable="true" label="Round Venue (Select Existing)" :options="vl_venues"/>
                                </div>
                            </Transition>
                            <Transition name="slide-fade">
                                <div v-if="!existingVenue[i]">
                                    <span @click="changeVenueInput(i)" class="link text-sm float-right flex justify-center" >
                                        <ArrowSmLeftIcon class="h-4 w-4 mt-1"/> 
                                        <span>Select Existing</span>
                                    </span>
                                    <tw-input v-model="venues[i].name"  label="Round Venue (Create New)"  placeholder="Enter venue name"  :required="false"  validation="Please select a venue"/>
                                </div>
                            </Transition>
                        </template>

                    </div>
                    <!-- <div class="flex justify-between" v-show="showChampAndVenueOptions">
                        <div @click="addVenue" class="link text-sm" >
                            Add another venue
                        </div>
                        <div v-show="venueCount > 1" @click="removeVenue" class="link text-sm" >
                            Remove most recent venue
                        </div>
                    </div> -->

                </div>
            </div>
        </div>


        <!-- Import setup & column match step -->
        <div v-if="step == 1">
            <!-- Setup fields -->
            <div class="grid grid-cols-12 gap-4 mx-6">
                
                <div
					v-if="importAs.id == IMPORT_AS_ROUNDS_ID"
					class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 2xl:col-span-3">
					<tw-input
						v-model="parInRow"
						label="Par in row"
						class="w-full"
						validation="Please enter a valid row number."
						id="parInRow"/>
				</div>
                <div 
					v-if="importAs.id == IMPORT_AS_ROUNDS_ID"
					class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 2xl:col-span-3">
					<tw-input 
						v-model="yardageInRow"
						label="Yardage in row"
						class="w-full"
						validation="Please enter a valid row number."
						id="yardageInRow"/>
				</div>
                <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 2xl:col-span-3">
					<tw-input 
                    v-for="(venue, i) in venues" :key="venue.id"
                    v-model="fieldHeaderRow[i]"
                    :label="`Column Headers begin on row ${venues.length > 1 ? `(${venue.name})` : ''}`" 
                    :isInvalid="headerRowOutOfRange[i]"
                    @change="getColumnNames()"
                    class="w-full"
					:disabled="noHeaders"
                    validation="Please enter a valid row number."/>
					<div class="flex space-x-2 items-center mt-2">
						<input type="checkbox" @change="getColumnNames()" class="h-4 w-4 rounded border-gray-300 text-usgaRed focus:ring-usgaRed sm:left-6" :value="false" v-model="noHeaders" />
						<p>This file has no column headers</p>
					</div>
				</div>
                <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 2xl:col-span-3">
					<tw-input
                    v-model="dataStartRow[0]"
                    label="Data begins on row"
                    :isInvalid="dataRowOutOfRange"
                    @change="currentRecordNumber = 1"
                    class="w-full"
                    validation="Please enter a valid row number."
                    id="dataStartRow"/>
				</div>
            </div>

            <div class="grid grid-cols-6 gap-4 grid-flow-row mx-6 my-4">
                <div class="col-span-6 lg:col-span-4 overflow-visible shadow ring-1 ring-black ring-opacity-5 sm:rounded-sm">
                    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">Select Columns to Import</h3>
                    </div>
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                            <tr>
                                <th class="uppercase py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-6">
                                    Source Column
                                </th>
                                <th class="uppercase py-3.5 text-left text-sm font-semibold text-gray-500">
                                    <div>Action</div>
                                </th>
                                <th class="uppercase py-3.5 pr-3 text-left text-sm font-semibold text-gray-500">
                                    Imports To
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-if="srcColToDbImportMap.length == 0">
                                <td class="flex whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-usgaDarkGray sm:pl-6">
                                    Please adjust the column header and data row settings above.
                                </td>
                            </tr>
                            <tr v-for="(col, i) in srcColToDbImportMap" :key="i">
                                <td class="flex whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {{ col.name }} 
                                </td>
                                <td> 
                                    <div class="inline-flex ml-auto w-full pr-8 text-center" :class="{'cursor-pointer': col?.importTo?.value === 'name' || col?.importTo?.value === 'date' || col?.importTo?.value === 'city'}" @click="toggleMatchField(col)">
                                            <SolidCheckCircleIcon 
                                            class="h-7 w-7 z-20"
                                            :class="[col?.match ? 'text-usgaDarkGreen' : 'text-usgaGray', 'transition-all duration-300 float-left -mr-7']" 
                                            v-if="col?.importTo?.value === 'playerId' || col?.importTo?.value === 'name' || col?.importTo?.value === 'date' || col?.importTo?.value === 'city'"/> 
                                        <span 
                                            class="flex justify-center text-center items-center px-2 py-0.5 rounded-full text-xs font-medium  w-52 transition-all duration-300" 
                                            :class="[!col?.importTo.value ? 'bg-usgaLightGray text-usgaDarkGray' : col?.match ? 'text-usgaDarkGreen bg-green-100' : 'text-usgaRed bg-red-100']" 
                                        > 
                                            {{importFieldText(col)}}
                                            <ArrowSmRightIcon class="h-6 w-6" /> 
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <tw-selectmenu 
                                        v-model="col.importTo"
                                        class="pr-4"
                                        :clearable="true"
                                        :class="{'text-gray-500' : !col.importTo}"
                                        :filterable="true"
                                        :options="VL_DB_FIELDS_IMPORT"
                                        @change="col.match = false"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-span-6 lg:col-span-2 overflow-visibleshadow ring-1 ring-black ring-opacity-5 sm:rounded-sm">
                    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 flex">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">Import Preview</h3>    
                        <span class="flex ml-auto">
                            <ChevronLeftIcon class="h-6 w-6 cursor-pointer" :class="{'text-usgaRed' : currentRecordNumber > 1}" @click="prevRecord" />
                            {{ currentRecordNumber }}/{{ recordCount }}
                            <ChevronRightIcon class="h-6 w-6 cursor-pointer" :class="{'text-usgaRed' : currentRecordNumber < recordCount}" @click="nextRecord" />       
                        </span>      
                    </div>
                    <table class="min-w-full divide-y divide-gray-300">
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="(field,i) in currentRowPreview" :key="i" class=""> 
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    <div class="text-gray-500">
                                        {{ field.source }}<span aria-hidden="true"> &rarr; </span>{{ field.destination }}
                                    </div>
                                    <div class="whitespace-pre-line">
                                        {{ field.value || '—'}}
                                    </div> 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> 


        <!-- Verify Step -->
        <div v-if="step == 2">
            <div class="grid grid-cols-2 gap-4 grid-flow-row mx-6 my-4">
                <div class="col-span-2 lg:col-span-1 overflow-visible shadow ring-1 ring-black ring-opacity-5 sm:rounded-sm">
                    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 flex">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">Select or Add Unmatched {{matchingItemsHelpText}} ({{unmatched.length}})</h3> 
                    </div>
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                            <tr>
                                <th 
                                    scope="col" 
                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-6"
                                >
                                    Import Name
                                </th>
                                <th 
                                    scope="col" 
                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-6"
                                >
                                    Imports To
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-if="unmatched.length === 0">
                                <td class="py-4 pl-4 pr-3 text-usgaDarkGray">
                                    No {{matchingItemsHelpText}} unmatched based on {{matchedFieldsText}} 
                                </td>
                            </tr>
                            <tr v-for="(item, i) in unmatched" :key="i">
                                <td class="flex whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    <div class="grid grid-flow-row">
                                        <span class="row-span-1">
                                            <!-- {{ item.row[srcColToDbImportMap.findIndex(x => x.importTo.value == 'name')] }} -->
											{{ item.importName }}
                                            <span v-if="updateExistingRecordsBasedOn.some((el) => el.importTo.value == 'city')" class="text-usgaDarkGray text-sm">
                                                ({{ item.row[srcColToDbImportMap.findIndex(x => x.importTo.value == 'city')] || 'No city'}})
                                            </span>
                                            <span v-else-if="updateExistingRecordsBasedOn.some((el) => el.importTo.value == 'date')" class="text-usgaDarkGray text-sm row-span-1">
                                                ({{ item.row[srcColToDbImportMap.findIndex(x => x.importTo.value == 'date')] || 'No date'}})
                                            </span>
                                        </span>
                                    </div>
                                    <ArrowSmRightIcon class="h-6 w-6 ml-auto"/>
                                </td>
                                <td>
                                    <tw-selectmenu 
                                        v-model="item.selected"
                                        class="pr-4"
										:searchable="true"
										:searchMethod="searchMethod"
                                        :options="searchResults"/>
                                </td>
                            </tr> 
                        </tbody>
                    </table>
                </div>
                <div class="col-span-2 lg:col-span-1 overflow-visible shadow ring-1 ring-black ring-opacity-5 sm:rounded-sm">
                    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 flex">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">Verify Matching {{matchingItemsHelpText}} ({{matched.length}})</h3> 
                    </div>
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                        <tr>
                            <th 
                                scope="col" 
                                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-6"
                            >
                                Import Name
                            </th>
                            <th 
                                scope="col" 
                                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-6"
                            >
                                Imports To
                            </th>
                        </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-if="matched.length === 0">
                                <td class="py-4 pl-4 pr-3 text-usgaDarkGray">
                                    No {{matchingItemsHelpText}} matched based on {{matchedFieldsText}} 
                                </td>
                            </tr>
                        <tr v-for="(item, i) in matched" :key="i">
                            <td class="flex whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <div class="grid grid-flow-row">
                                    <span class="row-span-1">
                                        <!-- {{ item.row[srcColToDbImportMap.findIndex(x => x.importTo.value == 'name')] }} -->
                                        {{ item.importName }}
                                        <span v-if="updateExistingRecordsBasedOn.some((el) => el.importTo.value == 'city')" class="text-usgaDarkGray text-sm">
                                            ({{ item.row[srcColToDbImportMap.findIndex(x => x.importTo.value == 'city')] || 'No city'}})
                                        </span>
                                        <span v-else-if="updateExistingRecordsBasedOn.some((el) => el.importTo.value == 'date')" class="text-usgaDarkGray text-sm row-span-1">
                                            ({{ item.row[srcColToDbImportMap.findIndex(x => x.importTo.value == 'date')] || 'No date'}})
                                        </span>
                                    </span>
                                </div>
                                <ArrowSmRightIcon class="h-6 w-6 ml-auto"/>
                            </td>
                            <td>
                                <div class="flex align-middle">
                                    <tw-selectmenu 
                                        v-model="item.selected"
                                        class="pr-4 col-span-1 w-full" 
                                        :filterable="true"
                                        :options="item.data"/> 
                                    <span class="col-span-1 mr-2 align-middle">({{item.data.length}})</span>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Import Summary Step -->
        <div v-if="step == 3">
            <div class="mt-8 mx-6 overflow-hidden shadow  sm:rounded-sm">
                <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 flex">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Results</h3> 
                </div>
                <table class="min-w-full divide-y divide-gray-300">
                    <tbody class="divide-y divide-gray-200 bg-white">
                        <tr>
                            <div class="min-w-0 flex-1 px-6 py-4 md:grid md:grid-cols-3 md:gap-4">
                                <div>
                                    <dt class="text-sm text-usgaDarkGray truncate">{{ importSummaryInfo.name }}</dt>
                                    <p class="text-gray-900">
                                        <span class="truncate">Imported by {{ importSummaryInfo.user }}</span>
                                    </p>
                                </div>
                                <div class="hidden md:block">
                                    <div>
                                        <dt class="text-sm text-usgaDarkGray">Imported on {{ importSummaryInfo.createdAt }}</dt>
                                        <p class="text-gray-900 flex items-center">
                                            <DotsHorizontalIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" aria-hidden="true" />
                                            Processing import
                                        </p>
                                    </div>
                                </div>
                                <div class="hidden lg:block text-usgaDarkGray">
                                    <dt class="text-sm">File</dt>
                                    <div class="text-gray-900">
                                        {{ importSummaryInfo?.file?.substring(importSummaryInfo?.file?.indexOf('/') + 1) }}
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-center py-4 sm:px-6">
                                <div class="min-w-0 flex-1 flex items-center">
                                    <div class="text-usgaDarkGray min-w-0 flex-1 md:grid md:grid-cols-1">
                                        <dt class="text-sm">Venue</dt>
                                        <div v-if="JSON.parse(importSummaryInfo.venue)[0] != null">
                                            <div v-for="(venue, i) in JSON.parse(importSummaryInfo.venue)" :key="i" class="text-gray-900">
                                                    {{ venue.name}}
                                            </div>
                                        </div>
                                        <div v-else>
                                            No Venues
                                        </div>
                                    </div>
                                    <div class="text-usgaDarkGray flex-1 md:grid md:grid-cols-1">
                                        <dt class="text-sm">Championship</dt>
                                        <div v-if="JSON.parse(importSummaryInfo.championship) != null" class="text-gray-900">
                                            {{ JSON.parse(importSummaryInfo.championship).name }}
                                        </div>
                                        <div v-else>
                                            No Championship
                                        </div>
                                    </div>
                                    <div class="min-w-0 flex-1 md:grid md:grid-cols-1"></div>
                                </div>
                            </div>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="flex justify-end gap-4 px-6 mt-4">
                <!-- <tw-button type="button" class="btn-base btn-white" @click="importAnotherFile">Import Another File</tw-button> -->
                <tw-button type="button" class="btn-base btn-white" @click="goToImportHistory">Go to Import History</tw-button>
            </div>
        </div>
    </div>

    <div v-show="step != 3" class=" z-20 px-6 py-2 flex flex-row justify-end space-x-4 border-t-2 border-usgaLightGray sticky bottom-0 w-full bg-white">
        <tw-button 
            v-if="step == 1"  
            class="button btn-base btn-white flex-1 sm:flex-none sm:w-auto mr-4" 
            :rounded="true" 
            @click="saveImportModalOpen = true">
                {{ saveImportSettingsText }}...
        </tw-button>
        <tw-button
            v-if="step !=0"
            class="button btn-base btn-white flex-1 sm:flex-none sm:w-24" 
            :rounded="true" 
            @click="previousStep">
                Back
        </tw-button>
        <tw-button
            class="button btn-base btn-red flex-1 sm:flex-none sm:w-24" 
            :rounded="true"
            :disabled="disableNext"
            @click="nextStep">
                Next
        </tw-button>
    </div>

</template>

<script setup>
    import { onMounted, ref, computed } from 'vue'
    import { onBeforeRouteLeave, useRouter } from 'vue-router'
    import api from "@/utilities/api"
	import { validateDate } from '@/utilities/validate.js'
	import { sanitizeDate } from '@/utilities/sanitize.js'
    // import queryAll from "@/utilities/queryAll"
    import { saveImportConfig, updateImportConfig } from './mutations.ts'
    import { getImportConfigs, listNodes, getRecordByName, getRecordByNameWithDate, getRecordByNameWithCity, getRecordByPlayerId, getPlayersForSelect, listChampcodes} from './queries.ts'
    import * as XLSX from 'xlsx/xlsx.mjs'
    import { Auth, Storage } from 'aws-amplify'
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { VL_IMPORT_TYPES, VL_IMPORT_AS, VL_DB_FIELDS_IMPORT, VL_ROUNDS_STROKE_PLAY, VL_ROUNDS_MATCH_PLAY, VL_ROUNDS_TEAM_PLAY } from '@/utilities/valueLists.js'
    import moment from 'moment'

    const IMPORT_TYPE_STROKE_ID = 1
    const IMPORT_TYPE_TEAM_ID = 2
    const IMPORT_TYPE_MATCH_ID = 3
    // const IMPORT_TYPE_PLAYERS_ID = 4
    // const IMPORT_TYPE_CHAMPIONSHIPS_ID = 5
    // const IMPORT_TYPE_VENUES_ID = 6
	const IMPORT_TYPE_PLAYER_PROFILE_MIGRATION_ID = 7

    const IMPORT_AS_PLAYERS_ID = 1
    const IMPORT_AS_CHAMPIONSHIPS_ID = 2
    const IMPORT_AS_VENUES_ID = 3
    const IMPORT_AS_ROUNDS_ID = 4


        // #region route setup
            const router = useRouter()

            onBeforeRouteLeave(() => {
                if(step.value > 0 && step.value < 3){
                    const answer = window.confirm(
                        'This import is not yet complete. You will lose all changes if you leave.'
                    )
                    // cancel the navigation and stay on the same page
                    if (!answer) return false
                }
            })
        // #endregion

        // #region notification
            const showNotification = ref(false)
            const notificationIcon = ref(0)
            const notificationTitle = ref('')
            const notificationSubtitle = ref('')
        // #endregion
            
        // #region steps configuration
            /**
            * Curret step of the import
            * @type {Number}
            */
            const step = ref(0)

            /**
            * Steps coniguration for display
            * @type {Object}
            */
            const steps = ref([
                { id: 'Step 1', name: 'Select File', status: 'current' },
                { id: 'Step 2', name: 'Select and Match Columns', status: 'upcoming' },
                { id: 'Step 3', name: 'Verify', status: 'upcoming' },
                { id: 'Step 4', name: 'Import Summary', status: 'upcoming' },
            ])

            /**
            * Navigates to the previous step of the import and changes active tab
            * @function previousStep
            */
            function previousStep() {
                if (step.value == 0) return
                if (step.value == 2) matchingIds.value = []
                steps.value[step.value].status = 'upcoming'
                step.value -= 1
                steps.value[step.value].status = 'current'
            }
            
            /**
            * Navigates to the next next step of the import and changes active tab
            * @function nextStep
            */
            async function nextStep() {
    
                //Setup header and data row arrays based on how many venues there are
                if (step.value == 0) {
                    if (importName.value == ''){
                        notificationTitle.value = 'Error'
                        notificationSubtitle.value = 'Please provide a name for this import.'
                        showNotification.value = true
                        notificationIcon.value = 1
                        document.getElementById('importName').focus()
                        return
                    }

                    if (importType.value.id < 4 && (existingChampionship.value && !championship.value.name) || (!existingChampionship.value && (!championship.value?.code?.label || !championship.value?.year))){
                        notificationTitle.value = 'Error'
                        notificationSubtitle.value = 'Please select or enter a new championship.'
                        showNotification.value = true
                        notificationIcon.value = 1
                        return
                    }

                    if (importType.value.id < 4 && !round.value.id){
                        notificationTitle.value = 'Error'
                        notificationSubtitle.value = 'Please select a round for the championship.'
                        showNotification.value = true
                        notificationIcon.value = 1
                        return
                    }

                    if (importType.value.id < 4 && (!roundDate.value || invalidRoundDate.value)){
                        notificationTitle.value = 'Error'
                        notificationSubtitle.value = 'Please enter a valid round date.'
                        showNotification.value = true
                        notificationIcon.value = 1
                        document.getElementById('roundDate').focus()
                        return
                    }
					// combine new championship type and year into name
					if(!existingChampionship.value) {
						championship.value.name = championship.value.year + " " + championship.value.code.label
					}

                    venues.value.forEach(() => {
                        fieldHeaderRow.value.push(1)
                        dataStartRow.value.push(2)
                    })

					getColumnNames()
                    
                    if(savedImportSettings?.value?.id) { 
                        setupImportFromSaved(savedImportSettings.value)
                    }

                    // switch(importAs.value.id) {
                    //     case IMPORT_AS_PLAYERS_ID :
                    //         importAs.value = {id: 1, label: 'Players', show: true}
                    //         break
                    //     case IMPORT_AS_CHAMPIONSHIPS_ID :
                    //         importAs.value = {id: 2, label: 'Championships', show: true}
                    //         break
                    //     case IMPORT_AS_VENUES_ID :
                    //         importAs.value = {id: 3, label: 'Venues', show: true}
                    //         break
                    //     default :
                    //         importAs.value = {id: 4, label: 'Rounds', show: true}
                    // }

					
                }

                else if (step.value == 1){
                    // validate setup fields
                    
                    let invalidHeaderField = fieldHeaderRow.value.some(field => {
                        return !field
                    })
                    if(invalidHeaderField || headerRowOutOfRange.value.some((n) => {return n})){
                        notificationTitle.value = 'Error'
                        notificationSubtitle.value = 'Please provide valid header options for this import.'
                        notificationIcon.value = 1
                        showNotification.value = true
                        return
                    }
                    let invalidDataStartField = dataStartRow.value.some(field => {
                        return !field
                    })
                    if(invalidDataStartField || dataRowOutOfRange.value){
                        notificationTitle.value = 'Error'
                        notificationSubtitle.value = 'Please provide a valid row where data begins for this import.'
                        notificationIcon.value = 1
                        showNotification.value = true
                        document.getElementById('dataStartRow').focus()
                        return
                    }

                    step2Loading.value = true
					if(importType?.value?.id != IMPORT_TYPE_PLAYER_PROFILE_MIGRATION_ID) {
						await findMatchingRecords()
						// await getAllNames()
					}
					else{
						steps.value[step.value].status = 'complete'
						step.value += 1
						steps.value[step.value].status = 'current'
						await s3Upload()
					}
                    step2Loading.value = false
                }

                else if (step.value == 2){
                    await s3Upload()
                }

                //Set current step to complete and move on to next step
                steps.value[step.value].status = 'complete'
                step.value += 1
                steps.value[step.value].status = 'current'
            }
        // #endregion
            
        // #region Step 1
            // #region load value lists
            const vl_savedImportSettings = ref([])
            const vl_championships = ref([])
            const vl_venues = ref([])
			const vl_champcodes = ref([])

            ;(async () => {
                try{
                    // Gets all import configs for user to select, format data structure to match select option
                    const importQueryInput = {
                        type: 'c',
                        sortDirection: 'ASC'
                    }
                    vl_savedImportSettings.value = (await api(getImportConfigs, importQueryInput)).data.importByType.items.map(record =>{
                        return {
                            ...record,
                            label: record.name
                        }
                    })

                    // Gets all existing championship names for user to select, format data structure to match select option
                    const championshipQueryInput = {
                        type: 'c',
                        sortDirection: 'ASC'
                    }
                    vl_championships.value = (await api(listNodes, championshipQueryInput)).data.nodeByTargetType.items.map(record =>{
                        return {
                            label: record.name,
                            name: record.name,
                            value: record.nodeTypeWithId
                        }
                    })

                    // Gets all existing venue names for user to select, format data structure to match select option
                    const venueQueryInput = {
                        type: 'v',
                        sortDirection: 'ASC'
                    }
                    vl_venues.value = (await api(listNodes, venueQueryInput)).data.nodeByTargetType.items.map(record =>{
                        return {
                            label: record.name,
                            name: record.name,
                            value: record.nodeTypeWithId
                        }
                    })

					// get champ codes
					const input = {
						limit: 100
					}
					const listRes = await api(listChampcodes, input)
					const items = listRes.data.listChampcodes.items
					
					vl_champcodes.value = items.map(record =>{
						return {
							label: record.name,
                            name: record.name,
                            value: record.code
						}
					})
                }catch(e){
                    console.log(e)
                }
            })()  
            // #endregion

            const importName = ref('')
            const importType = ref(null)

            // #region saved import settigngs
            const savedImportSettings = ref(null)
            
            /**
            * Sets the import type from the selected saved import settings
            * @function changeChampionshipInput
            */
            function setSavedImportSettings(selected) {
               importType.value = JSON.parse(selected.importType)
               importAs.value = JSON.parse(selected.importAs)
            //    importAs.value = JSON.parse(savedImportSettings.value.importAs)
            }

            /**
            * Sets header, data, and field mapping settings based on selected saved settings when going from step 1 to step 2
            * @function removeFile
            */
            function setupImportFromSaved() {
                parInRow.value = savedImportSettings.value.parInRow 
                yardageInRow.value = savedImportSettings.value.yardageInRow 
                dataStartRow.value = savedImportSettings.value.dataBeginsOnRow 
				getColumnNames()

                for (const row in savedImportSettings.value.headerBeginsOnRow){
                    fieldHeaderRow.value[row] = savedImportSettings.value.headerBeginsOnRow[row]
                }
                
                saveImportSettingsName.value = savedImportSettings.value.name

                // srcColToDbImportMap.value = JSON.parse(savedImportSettings.value.fieldMapping)
                let savedFieldMapping = JSON.parse(savedImportSettings.value.fieldMapping)
				savedFieldMapping.forEach((field, i) => {
					if(i < srcColToDbImportMap.value.length) srcColToDbImportMap.value[i] = field
				})
            }
            // #endregion
            
            // #region championship and venue options
            /**
            * Controls if championship and venue fields are shown on the first step of the import
            * @yields {Boolean}
            */
            const showChampAndVenueOptions = computed(() => {
                return importType?.value?.id < 4
            })

            const championship = ref({})
            const round = ref({})
            const vl_round_options = computed(() => {
                if(importType?.value?.id == IMPORT_TYPE_STROKE_ID) return VL_ROUNDS_STROKE_PLAY
                else if(importType?.value?.id == IMPORT_TYPE_MATCH_ID) return VL_ROUNDS_MATCH_PLAY
                else if(importType?.value?.id == IMPORT_TYPE_TEAM_ID) return VL_ROUNDS_TEAM_PLAY
                else return VL_ROUNDS_STROKE_PLAY
            })
            const roundDate = ref('')

            const invalidRoundDate = ref(false)
            /**
             * Formats entered date for round
             * validateRoundDate
             */
            function validateRoundDate(){
				let valid = validateDate(roundDate.value)
				invalidRoundDate.value = !valid
            }
            // const championshipDates = ref('')
            const venues = ref([{}])

            const existingChampionship = ref(true)
            /**
            * Switches between input or select for championship
            * @function changeChampionshipInput
            */
            function changeChampionshipInput(){
                existingChampionship.value = !existingChampionship.value
                championship.value = {}
            }

            const existingVenue = ref([true])
            /**
            * Switches between input or select for venue
            * @function changeVenueInput
            */
            function changeVenueInput(index){
                existingVenue.value[index] = !existingVenue.value[index]
                venues.value[index] = {}
            }

            /**
            * Adds a field to add another venue to the import
            * @function addVenue
            */
            // function addVenue() {
            //     venues.value.push({})
            //     existingVenue.value.push(true)
            // }

            /**
            * Decreases how many venue fields there are
            * @function removeVenue
            */
            // function removeVenue() {
            //     if (venueCount.value == 1) return
            //     venues.value.pop()
            //     existingVenue.value.pop()
            // }

            /**
            * Number of venue fields chosen
            * @yields {Number}
            */
            // const venueCount = computed(()=>{
            //     return venues.value.length
            // })
            // #endregion
            
            /**
            * Controls if next button is disabled on the first and second step of the import
            * @yields {Boolean}
            */
            const disableNext = computed(() => {
                let disable = false
                if (step.value == 0) {
                    disable = step.value == 0 && file.value == null
                }
                else if (step.value == 1) {
                    disable = updateExistingRecordsBasedOn.value.length > 0 ? 0 : 1
                }
                else if (step.value == 2) {
                    for (let i=0; i<matched.value.length; i++){
                        if (JSON.stringify(matched.value[i].selected) == "{}"){
                            disable = true
                        }
                    }
                }
                return disable
            })

            // #region drag and drop file upload
            const drop = ref(null)
            const isOver = ref(false)
            const file = ref(null)
            onMounted(() => {
                drop.value.addEventListener("dragover", () => {
                    isOver.value = true
                })
                drop.value.addEventListener("drop", (e) => {
                    isOver.value = false
                    fileUpload(e)
                })
                drop.value.addEventListener("dragleave", () => {
                    isOver.value = false
                })
            })

            /**
            * Validates file type and reads file
            * @function fileUpload
            * @param {Object} e - drop event
            */
            function fileUpload(e){
                try {
                    isOver.value = false
                    file.value = e.target.files || e.dataTransfer.files
                    file.value = file.value['0']
                    file.value['sizeMb'] = (file.value['size'] / 1e6).toFixed(1)
                    file.value['sizeKb'] = (file.value['size'] / 1e3).toFixed(1)
                    const re = new RegExp(/^.+\.(xlsx|xls|csv)$/, 'g')
                    const acceptedFileType = re.test(file.value.name)
                    if(!acceptedFileType){
                        file.value = null
                        notificationTitle.value = "Invalid file type"
                        notificationSubtitle.value = 'Only .xlsx, .xls, and .csv file types are supported.'
                        notificationIcon.value = 1
                        showNotification.value = true
                    }
                    else if (file.value) {
                        var reader = new FileReader()
                        //Attach onload trigger function
                        reader.onload = (e) => {
                            var data = new Uint8Array(e.target.result)
                            var workbook = XLSX.read(data, {type: 'array', sheetRows: 500})
                            parseXLSX(workbook)
                        }

                        reader.readAsArrayBuffer(file.value)
                    }
                }catch(err){
                    console.log(err)
                }
            }
            
            /**
            * Parses Excel file 
            * @function removeFile
            */
            function parseXLSX(workbook){
                try {
					// read the first sheet
                    var workbookName = workbook?.SheetNames[0]
                    var sheet = workbook.Sheets[workbookName]
                }
                catch(e) {
                    console.log(e)
                }

                try {   
                    jsonSheet.value = XLSX.utils.sheet_to_json(sheet, {blankrows: true, defval: '', raw: false, header: 1})
                }
                catch(e){
                    console.log(e)
                }
            }

            /**
            * Removes currently selected file when x is clicked
            * @function removeFile
            */
            function removeFile(){
                document.getElementById('FileUpload').value = ""
                file.value = null
            }
            // #endregion

        // #endregion

        // #region Step 2
            const importAs = ref({})
            const step2Loading = ref(false)

            // #region header and data row settings
            const parInRow = ref()
            const yardageInRow = ref()
            const fieldHeaderRow = ref([])
            const dataStartRow = ref([])

            /**
             * Controls validation of header row field
             * @yields {Array}
             */
            const headerRowOutOfRange = computed(() => {
                return fieldHeaderRow.value.map((field) => {
                    return field > jsonSheet.value.length
                })
            })

            /**
             * Controls validation of data begins on row field
             * @yields {Boolean}
             */
            const dataRowOutOfRange = computed(() => {
                return dataStartRow.value[0] > jsonSheet.value.length
            })

            /**
             * Determines if current import is stroke play round of team event
             * @yields {boolean}
             */
            const isTeamEventStrokePlay = computed(() => {
                return importType.value.id == IMPORT_TYPE_TEAM_ID && round.value.id <= 2 

            })

			/**
             * Determines if current import is match play round of team event
             * @yields {boolean}
             */
            const isMatchPlayRound = computed(() => {
                return (importType.value.id == IMPORT_TYPE_TEAM_ID && round.value.id > 3) ||
						(importType.value.id == IMPORT_TYPE_MATCH_ID && round.value.id > 2)
            })

			const isTeamEventMatchPlay = computed(() => {
                return (importType.value.id == IMPORT_TYPE_TEAM_ID && round.value.id > 3)
            })
            // #endregion
            
            // #region field mapping table
            const jsonSheet = ref(null)
            const srcColToDbImportMap = ref([])
			const noHeaders = ref(false)
            /**
             * Updates Source Column label text in table when header row changes
             * @function getColumnNames
             */
            function getColumnNames() {
                // Entered header row number is out of range
                if(headerRowOutOfRange.value[0]) {
                    fieldHeaderRow.value[0] = jsonSheet.value.length
                }
                
                try {
                    const columnHeaders = jsonSheet.value[fieldHeaderRow.value[0] - 1] // use the index directly

                    srcColToDbImportMap.value = []
                    columnHeaders.forEach((header, i) => {
						if(noHeaders.value) header = null
                        //If there is no header for column dont add colon to column name
                        let name = `Column ${String.fromCharCode(65 + i)}${header ? ': ' + header : ''}`
                        srcColToDbImportMap.value.push({
                            'name': name,
                            'importTo': {},
                            'match' : false,
                        })
                    })

                    currentRowPreview.value = []
                }
                catch(e) {
                    console.log(e)
                }
            }

            /**
             * Text to display how source fields will be used in the import
             * @yields {String}
             */
            function importFieldText(col){
                if(!col?.importTo?.value) return "Don't import"
                else if(col?.match) return 'Match on field'
                else if(col?.importTo?.value) return 'Import'
            }

            /**
             * Sets match value on field mapping table to control UI
             * @function toggleMatchField
             * @param {Object} col - the column (field) being edited
             */
            function toggleMatchField(col) {
				// clear out other columns if using match by player id
				if(col?.importTo?.value === 'playerId' && !col.match){
					updateExistingRecordsBasedOn.value.forEach(field => {
						field.match = false
					})
				}

				// clear out player id columns if using match by name, city, or date
				if((col?.importTo?.value === 'name' || col?.importTo?.value === 'date' || col?.importTo?.value === 'city') && !col.match){
					updateExistingRecordsBasedOn.value.forEach(field => {
						if(field.importTo.value == 'playerId') {
							field.match = false
						}
					})
				}

                if(col?.importTo?.value === 'playerId' || col?.importTo?.value === 'name' || col?.importTo?.value === 'date' || col?.importTo?.value === 'city'){
                    col.match = !col.match
                }
				
            }

            /**
             * Fields to match and update existing records on
             * @yields {Array}
             */
            const updateExistingRecordsBasedOn = computed(() => {
                return srcColToDbImportMap.value.filter(field => {
                    return field.match === true
                })
            })
            // #endregion

            // #region import preview
            const currentRecordNumber = ref(1)

            /**
            * Number of records to be imported from source file
            * @yields {Number}
            */
            const recordCount = computed(() => {
                return jsonSheet.value.length - ( dataStartRow.value[0] - 1 )
            })

            /**
            * Row index of source file currently viewed in the import preview
            * @yields {Number}
            */
            const previewPageIndex = computed(() => {
                if(dataRowOutOfRange.value) return 0
                return dataStartRow.value[0] - 2 + currentRecordNumber.value
            })

            /**
            * Navigates to previous record preview
            * @function prevRecord
            */
            function prevRecord() {
                if(currentRecordNumber.value > 1) currentRecordNumber.value--
            }

            /**
            * Navigates to next record preview
            * @function nextRecord
            */
            function nextRecord() {
                if(currentRecordNumber.value < recordCount.value) currentRecordNumber.value++
            }

            /**
            * Current preview of data to be imported from source file using selected field mapping
            * @yields {Object}
            */
            const currentRowPreview = computed(() => {
                // Get row object of preview row to display info
                let previewRow = jsonSheet.value[previewPageIndex.value]
				let previewRows = []
				if(!previewRow) return []

                // If team event stroke play, get grouping of three preview rows for 2 individuals and team score
                if (isTeamEventStrokePlay.value){
					const previewRowRelative = ( previewPageIndex.value - (Number(dataStartRow.value[0]) - 1 )) % 3
					// push in three rows to build preview
					for(let i = 0; i < 3; i++){
						previewRows.push(jsonSheet.value[previewPageIndex.value + i - previewRowRelative])
					}
                }

				// If match play round, get grouping of three to four preview rows dynamically based on blank rows between scores
				else if(isMatchPlayRound.value){
					let blankRow = previewRow.every(el => {return !el})

					// are in a blank row
					if(blankRow) {
						// skip over any concurrent blank rows
						let i = 0
						while(blankRow){
							i++
							blankRow = jsonSheet.value[previewPageIndex.value + i]?.every(el => {return !el})
						}
						// go down until next blank row
						do {
							previewRows.push(jsonSheet.value[previewPageIndex.value + i])
							i++
							blankRow = jsonSheet.value[previewPageIndex.value + i]?.every(el => {return !el})
						} while(!blankRow && blankRow != undefined && i < 10)
					}
					// are not in a blank row
					else {
						// go up until you hit previous blank row
						let i = 0
						do {
							previewRows.unshift(jsonSheet.value[previewPageIndex.value + i])
							i--
							blankRow = jsonSheet.value[previewPageIndex.value + i]?.every(el => {return !el})
						} while(!blankRow && blankRow != undefined &&  i > -10)

						// go down until you hit next blank row
						i = 1
						blankRow = jsonSheet.value[previewPageIndex.value + i]?.every(el => {return !el})
						while(!blankRow && blankRow != undefined && i < 10){
							previewRows.push(jsonSheet.value[previewPageIndex.value + i])
							i++
							blankRow = jsonSheet.value[previewPageIndex.value + i]?.every(el => {return !el})
						}
					}
				}	

				// If stroke play round or basic import
				else {
					// push in one row to build preview
					previewRows.push(jsonSheet.value[previewPageIndex.value])
				}

                const arr = []
                // Loop through all source columns
                srcColToDbImportMap.value.forEach((srcCol, i) => {
                    if(!srcCol?.importTo?.value) return // If no value selected, skip it

					// If team event stroke play round
                    if(isTeamEventStrokePlay.value) {
                        if(srcCol?.importTo?.value == 'name'){
                            arr.push({
                                source: srcCol.name,
                                destination: srcCol?.importTo?.label,
                                value: [
                                    previewRows[0][i],
                                    previewRows[1][i],
                                    previewRows[0][i] + " & " + previewRows[1][i]
                                ].join('\n')
                            })
                        }
                        else{
                            arr.push({
                                source: srcCol.name,
                                destination: srcCol?.importTo?.label,
                                value: [
                                    previewRows[0][i],
                                    previewRows[1][i],
                                    previewRows[2][i]
                                ].join('\n')
                            })
                        }
                    }

					// If match play round
					else if(isMatchPlayRound.value){
						if(srcCol?.importTo?.value == 'name') {
							// TODO - find which rows have name dynamically
                            arr.push({
                                source: srcCol.name,
                                destination: srcCol?.importTo?.label,
                                value: [
									previewRows[0][i],
                                    previewRows[1][i],
                                    previewRows[2][i]
                                ].join('\n')
                            })
                        }
                        else{
							let value = []
							previewRows.forEach(row => value.push(row[i]))
                            arr.push({
                                source: srcCol.name,
                                destination: srcCol?.importTo?.label,
                                value: value.join('\n')
                            })
                        }
					}

					// If stroke play round or basic import
                    else {
                        //Set header name key equal to correct info from row
                        arr.push({
                            source: srcCol.name,
                            destination: srcCol?.importTo?.label,
                            value: previewRows[0][i]
                        })
                    }
                })
                return arr
            })
            // #endregion

            // #region save import settings
            const saveImportSettingsName = ref('')
            const saveImportModalOpen = ref(false)
            const saveImportSettingsText = computed(() => {
                if(savedImportSettings?.value?.id) return "Update Saved Import Settings"
                return "Save import settings"
            })

            /**
            * Saves or updates current import settings
            * @function saveImportSettings
            */
            async function saveImportSettings(){
                // create
                if(!savedImportSettings?.value?.id){
                    const input = {
                        type: 'c',
                        name: saveImportSettingsName.value,
                        importType: JSON.stringify(importType.value),
                        fieldMapping: JSON.stringify(srcColToDbImportMap.value),
                        parInRow: Number(parInRow.value),
                        yardageInRow: Number(yardageInRow.value),
                        headerBeginsOnRow: fieldHeaderRow.value,
                        dataBeginsOnRow: dataStartRow.value,
                        importAs: JSON.stringify(importAs.value)
                    }
                    try{
                        let record = await api(saveImportConfig, {input})
                        notificationSubtitle.value = 'Your import settings have been saved.'
                        notificationTitle.value = 'Success'
                        savedImportSettings.value = record.data.createImport
                        notificationIcon.value = 0
                        showNotification.value = true
                        saveImportModalOpen.value = false
                    }catch(e){
                        console.log(e)
                    }
                }
                // update
                else{
                    const input = {
                        id: savedImportSettings.value.id,
                        type: 'c',
                        name: saveImportSettingsName.value,
                        importType: JSON.stringify(importType.value),
                        fieldMapping: JSON.stringify(srcColToDbImportMap.value),
                        parInRow: Number(parInRow.value),
                        yardageInRow: Number(yardageInRow.value),
                        headerBeginsOnRow: fieldHeaderRow.value,
                        dataBeginsOnRow: dataStartRow.value,
                        importAs: JSON.stringify(importAs.value),
                        createdAt: savedImportSettings.value.createdAt
                    }
                    try{
                        let record = await api(updateImportConfig, {input})
                        notificationSubtitle.value = 'Your import settings have been updated.'
                        notificationTitle.value = 'Success'
                        savedImportSettings.value = record.data.updateImport
                        notificationIcon.value = 0
                        showNotification.value = true
                        saveImportModalOpen.value = false
                    }catch(e){
                        console.log(e)
                    }
                }
            }
            // #endregion

        // #endregion

        // #region Step 3

            // #region record matching help text
            /**
            * Text to display which fields were chosen to match records to be updated
            * @yields {String}
            */
            const matchedFieldsText = computed(()=>{
                let text = []
                srcColToDbImportMap.value.forEach(col =>{
                    if(col.match) text.push(col.importTo.label)
                })
                if(text.length < 1) return 'name'
                return text.join(', ')
            })

            /**
            * Text to display which record type was chosen to create when imported
            * @yields {String}
            */
            const matchingItemsHelpText = computed(() => {
                let type = ''
                switch(importAs.value.id) {
                    case IMPORT_AS_PLAYERS_ID :
                        type = 'Players'
                        break
                    case IMPORT_AS_CHAMPIONSHIPS_ID :
                        type = 'Championships'
                        break
                    case IMPORT_AS_VENUES_ID :
                        type = 'Venues'
                        break
                    // case IMPORT_AS_ROUNDS_ID :
                    //     type = 'Rounds'
                    //     break
                    default :
                        type = 'Players'
                }
                return type
            })
            // #endregion

            // #region record matching
           
            const matchingIds = ref([])
            /**
             * Queries database to try to find matching record for all records in Excel file being imported
             * @function findMatchingRecords
             */
            async function findMatchingRecords() {
                try{
                    const rowsToImport = jsonSheet.value.slice(dataStartRow.value - 1)
                    const nameIndex = srcColToDbImportMap.value.findIndex(x => x.importTo.value == 'name')
                    const playerIdIndex = srcColToDbImportMap.value.findIndex(x => x.importTo.value == 'playerId')
                    const dateIndex = srcColToDbImportMap.value.findIndex(x => x.importTo.value == 'date')
                    const cityIndex = srcColToDbImportMap.value.findIndex(x => x.importTo.value == 'city')

                    for (const i in rowsToImport){
						if(isTeamEventStrokePlay.value && (i % 3 === 2)){
							// if row is combined team score, skip
							continue
						}
						if(isMatchPlayRound.value && !rowsToImport[i][nameIndex]){
							// if blank row or row with no name, skip
							continue
						}
                        const inputs = ref([]) // team event has multiple players in row on match play rounds
                        let matchingFields = ''

                        updateExistingRecordsBasedOn.value.forEach(field =>{
                            let importTo = field.importTo.value
                            if(importTo == 'name') {
								matchingFields += 'n'
								if(isTeamEventMatchPlay.value) {
									rowsToImport[i][nameIndex].split('/').forEach(name => {
										inputs.value.push({
											name: name.trim(),
											sortDirection: 'ASC'
										})
									})
								}
								else{
									inputs.value.push({
										name: rowsToImport[i][nameIndex].trim(),
										sortDirection: 'ASC'
									})
								}
                            }
							else if(importTo == 'playerId') {
								matchingFields += 'p'
								inputs.value.push({
									playerId: rowsToImport[i][playerIdIndex].trim()
								})
							}

                            if(importTo == 'date') {
                                inputs.value.forEach(el => {
									el.date = {
										eq: rowsToImport[i][dateIndex].trim()
									}
								})
                                matchingFields += 'd'
                            }
                            else if(importTo == 'city') {
                                inputs.value.forEach(el => {
									el.city = {
										eq: rowsToImport[i][cityIndex].trim()
									}
								})
                                matchingFields += 'c'
                            }
                        })

                        try{
							// inputs.value.forEach(async (input) => { // 
							for(const input of inputs.value){
								var resIds = []
								if (matchingFields == 'nd'){
									const res = await api(getRecordByNameWithDate, input)
									resIds = res.data.nodeByName.items
								}
								else if (matchingFields == 'nc'){
									const res = await api(getRecordByNameWithCity, input)
									resIds = res.data.nodeByName.items
								}
								else if(matchingFields == 'n'){
									const res = await api(getRecordByName, input)
									resIds = res.data.nodeByName.items
								}
								else if(matchingFields == 'p'){
									if(input.playerId){
										const res = await api(getRecordByPlayerId, input)
										resIds = res.data.nodeByPlayerId?.items
									}
									else{
										resIds = []
									}
								}
								resIds = resIds.map((obj) => ({
									...obj,
									label: `${obj.name}${obj.playerId && obj.playerId!='null'&& obj.playerId!=null ? ' ( '+obj.playerId +', ': ' ( '}${obj.city ? obj.city+' )' : 'No city )'}`,
									show: true
								}))

								if (resIds.length != 0) {
									matchingIds.value.push({
										row: rowsToImport[i], 
										data: resIds,
										importName: rowsToImport[i][nameIndex],
										selected: resIds.length == 1 ? {...resIds[0]} : {}
									})
								}
								else {
									matchingIds.value.push({
										row: rowsToImport[i], 
										data: null, // make it a list of players
										importName: rowsToImport[i][nameIndex],
										selected: {
											label: '+ Create New',
											id: 1,
											show: true
										}
									})
								}
							}
                        }
						catch(e) {
                            console.log(e)
                        }
                    }
                }catch(e){
                    console.log(e)
                }
            }


			const searchResults = ref([])
			/**
             * Loads options for records to match to
             * @function searchMethod
             */
			const searchMethod = async function(searchText) {
				const input = {
					type: 'p',
					sortDirection: 'ASC',
					name: {
						beginsWith: searchText
					}
				}
				const res = await api(getPlayersForSelect, input)
				searchResults.value = res.data.nodeByTargetType.items.map(item => {
					return {
						...item,
						label: item.name
					}
				})
				searchResults.value.unshift({
                        label: '+ Create New',
                        id: 1
				})
			}

            /**
             * List of rows in the Excel sheet that didn't match records in the DB
             * @yieldds {Array}
             */
            const unmatched = computed(() => {
                return matchingIds.value.filter(x => x.data == null)
            })

            /**
             * List of rows in the Excel sheet that matched records in the DB
             * @yieldds {Array}
             */
            const matched = computed(() => {
                return matchingIds.value.filter(x => x.data != null)
            })
            // #endregion

            // #region upload to s3
            const importSummaryInfo = ref('')
            /**
             * Uploads selected file to s3 when going from step 3 to step 4
             * @function s3Upload
             */
            async function s3Upload(){
                if (file.value){
                    const now = (moment().format() + ':' + moment().millisecond())
                    const key = now + '/' + file.value.name

                    await Storage.put(key, file.value)

                    const user = await Auth.currentAuthenticatedUser()
                    // const nameIndex = srcColToDbImportMap.value.findIndex(x => x.importTo.value == 'name')
                    const idMap = matchingIds.value.map((row) =>{
                        return {
                            id: row.selected?.id,
                            nodeTypeWithId: row.selected?.nodeTypeWithId,
                            targetTypeWithId: row.selected?.targetTypeWithId,
                            label: row.selected.label,
                            name: row.importName
                        }
                    })
                    //Add new import type d to db that then calls the lamda to actually import data
                    const input = {
                        type: 'd',
                        file: key,
                        name: importName.value,
                        fieldMapping: JSON.stringify(srcColToDbImportMap.value),
                        parInRow: Number(parInRow.value),
                        yardageInRow: Number(yardageInRow.value),
                        headerBeginsOnRow: fieldHeaderRow.value,
                        dataBeginsOnRow: dataStartRow.value,
                        championship: JSON.stringify(championship.value),
                        round: JSON.stringify({
                            ...round.value,
                            date: sanitizeDate(roundDate.value)
                        }),
                        venue: JSON.stringify(venues.value),
                        status: 'processing',
                        user: user.username,
                        importAs: JSON.stringify(importAs.value),
                        importType: JSON.stringify(importType.value),
                        idMapping: JSON.stringify(idMap),
						importMethod: 'Import'
                    }
                    try{
                        const res = await api(saveImportConfig, {input})
                        notificationTitle.value = 'Success'
                        notificationSubtitle.value = 'Your import has started running in the background.'
                        notificationIcon.value = 0
                        showNotification.value = true
                   
                        importSummaryInfo.value = res.data.createImport
                        importSummaryInfo.value.createdAt = moment(importSummaryInfo.value.createdAt).format('MMMM DD, YYYY')
                    }catch(e){
                        console.log(e)
                    }
                }
            }
            // #endregion
        // #endregion

        // #region Step 4
            /**
             * Takes user to import history page
             * @function goToImportHistory
             */
            function goToImportHistory(){
                router.push({name: 'ImportHistory'})
            }

            /**
             * Takes user to back to first step of import with variables cleared
             * @function importAnotherFile
             */
            // function importAnotherFile(){
            //     steps.value = [
            //         { id: 'Step 1', name: 'Select File', status: 'current' },
            //         { id: 'Step 2', name: 'Select and Match Columns', status: 'upcoming' },
            //         { id: 'Step 3', name: 'Verify', status: 'upcoming' },
            //         { id: 'Step 4', name: 'Import Summary', status: 'upcoming' },
            //     ]

            //     file.value = null
            //     importName.value = ''
            //     savedImportSettings.value = null
            //     importType.value = null
            //     importAs.value = {}
            //     championship.value = {}
            //     round.value = {}
            //     roundDate.value = ''
            //     venues.value = [{}]

            //     step.value = 0
            // }
        // #endregion
</script>

<style scoped>
    div.t1 {
        position: relative;
    }

    .slide-fade-enter-active {
        transition: all 0.8s ease-out;
    }

    /* .slide-fade-leave-active {
        transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
    } */

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateX(20px);
        opacity: 0;
    }
</style>
import PlayerProfile from './PlayerProfile.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement
import PlayerProfileLayout from '@/layouts/PlayerProfileLayout.vue'

export const route: Route[] = [
    {
      path: '/player-profile',
      name: 'PlayerProfile',
      component: PlayerProfile,
      meta: {layout: PlayerProfileLayout},
      // meta: {module: 'playerProfiles'},
      props: true
    }
];
import Settings from './Settings.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
      meta: {module: 'settings'}
    }
];
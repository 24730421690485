//Query to get all players basic information
export const venuesSortedByName = /* GraphQL */ `
  query NodeByTargetType(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByTargetType(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        type
        city
        country
        name
        state
      }
      nextToken
    }
  }
`;
export const getChampionshipEntries = /* GraphQL */ `
  query NodeByTargetTypeWithIdSortedByPosition(
    $targetTypeWithId: String!
    $position: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByTargetTypeWithIdSortedByPosition(
      targetTypeWithId: $targetTypeWithId
      position: $position
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        name
        nameFirst
        nameLast
        winner
        location
        dates
        rank
        position
        putts
		partners
        fairwaysHit
        strTotal
        greens
        playerName
		round1
		round2
		round3
		round4
		total
      }
      nextToken
    }
  }
`;

export const getChampionship = /* GraphQL */ `
  query GetNode($nodeTypeWithId: String!, $targetTypeWithId: String!) {
    getNode(
      nodeTypeWithId: $nodeTypeWithId
      targetTypeWithId: $targetTypeWithId
    ) {
      nodeTypeWithId
      targetTypeWithId
      name
      dates
      country
      city
      location
      winner
      fieldSize
      entries
	  roundTypes
	  eventType
    }
  }
`;

export const listNodes = /* GraphQL */ `
  query NodeByTargetType(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByTargetType(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        name
      }
      nextToken
    }
  }
`;

export const listPlayersRoundsByChampionship = /* GraphQL */ `
  query NodeByTargetTypeWithIdSortedByPosition(
    $targetTypeWithId: String!
    $position: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByTargetTypeWithIdSortedByPosition(
      targetTypeWithId: $targetTypeWithId
      position: $position
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        strTotal
		rank
        position
      }
      nextToken
    }
  }
`;
export const getPlayersForSelect = /* GraphQL */ `
  query NodeByTargetType(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByTargetType(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        name
      }
      nextToken
    }
  }
`;

export const byTargetTypeWithIdSortedByFirstName = /* GraphQL */ `
  query ByTargetTypeWithIdSortedByFirstName(
    $targetTypeWithId: String!
    $nameFirst: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTargetTypeWithIdSortedByFirstName(
      targetTypeWithId: $targetTypeWithId
      nameFirst: $nameFirst
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        nameFirst
        nameLast
        winner
        location
        dates
        rank
        position
        putts
		    partners
        fairwaysHit
        strTotal
        greens
        playerName
        round1
        round2
        round3
        round4
        total
      }
      nextToken
    }
  }
`;
export const byTargetTypeWithIdSortedByLastName = /* GraphQL */ `
  query ByTargetTypeWithIdSortedByLastName(
    $targetTypeWithId: String!
    $nameLast: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTargetTypeWithIdSortedByLastName(
      targetTypeWithId: $targetTypeWithId
      nameLast: $nameLast
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        nameFirst
        nameLast
        winner
        location
        dates
        rank
        position
        putts
		    partners
        fairwaysHit
        strTotal
        greens
        playerName
        round1
        round2
        round3
        round4
        total
      }
      nextToken
    }
  }
`;
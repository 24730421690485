<template>
	<tw-full-loading v-if="loading">
        Exporting data. This may take a moment.
    </tw-full-loading>
	<teleport to="#notification">
        <tw-notification 
			v-if="showNotification" 
			@close="showNotification = false" 
			:title="notificationTitle" 
			:subtitle="notificationSubtitle" 
			:duration="8000" 
			:icon="notificationIcon"/>
    </teleport>
	<div class="min-h-screen">

		
		<div class="grid grid-cols-6 gap-4 grid-flow-row mx-6 my-4">
			<div class="col-span-6 overflow-visible shadow ring-1 ring-black ring-opacity-5 sm:rounded-sm">
				<div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
					<h3 class="text-lg leading-6 font-medium text-gray-900">Select Columns to Export</h3>
				</div>
				<table class="min-w-full divide-y divide-gray-300">
					<thead class="bg-gray-50">
						<tr>
							<th></th>
							<th class="w-1/2 uppercase py-3.5 pl-4 text-left text-sm font-semibold text-gray-500 sm:pl-6">
								Source Column
							</th>
							<th class="w-1/2 uppercase py-3.5 pl-4 text-left text-sm font-semibold text-gray-500 sm:pl-6">
								Exports To
							</th>
						</tr>
					</thead>
					<tbody class="divide-y divide-gray-200 bg-white drop-zone">
						<tr 
							v-for="(col, i) in srcColToDbExportMap" 
							:key="i">
							<td class="flex flex-col pl-4 py-4 justify-center items-center">
								<button 
									:disabled="i === 0"
									@click="moveRow(i, i-1)" 
									class="rounded-md inline-flex text-black hover:text-usgaRed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-usgaRed">
									<span class="sr-only">Close</span>
									<ArrowSmUpIcon class="h-5 w-5" aria-hidden="true" />
								</button>
								<button 
									:disabled="i === srcColToDbExportMap.length - 1"
									@click="moveRow(i, i+1)" 
									class="rounded-md inline-flex text-black hover:text-usgaRed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-usgaRed">
									<span class="sr-only">Close</span>
									<ArrowSmDownIcon class="h-5 w-5" aria-hidden="true" />
								</button>
							</td>
							<td class="py-4 pl-4 text-sm font-medium text-gray-900 sm:pl-6">
								<tw-selectmenu 
									v-model="col.exportFrom"
									class="pr-4"
									:clearable="true"
									:class="{'text-gray-500' : !col.exportFrom}"
									:filterable="true"
									:options="columnOptions"/>
							</td>
							<td class="flex justify-between align-middle px-4 py-6 sm:pl-6">
								<div>{{ col.name }}</div>
								<button @click="removeColumn(i)" class="rounded-md text-black hover:text-usgaRed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-usgaRed">
									<span class="sr-only">Close</span>
									<XIcon class="h-5 w-5" aria-hidden="true" />
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="w-full mt-2">
				<tw-button class="w-full btn btn-white btn-base" @click="addColumn" rounded>Add a column</tw-button>
			</div>
		</div>
		</div>
	<div class=" z-20 px-6 py-2 flex flex-row justify-end space-x-4 border-t-2 border-usgaLightGray sticky bottom-0 w-full bg-white">
        <tw-button @click="exportData" :disabled="srcColToDbExportMap.length === 0" class="btn btn-base btn-red w-32" rounded>Export</tw-button>
    </div>
	
</template>

<script setup>
    import { ref, computed, defineProps } from 'vue'
    import { createExport } from './mutations.ts'
    import api from "@/utilities/api"
	import * as XLSX from 'xlsx/xlsx.mjs'
    import { Auth } from 'aws-amplify'
	import { VL_PLAYER_EXPORT_FIELDS, VL_CHAMP_EXPORT_FIELDS, VL_VENUE_EXPORT_FIELDS } from '@/utilities/valueLists.js'

    const props = defineProps([
        'data',
		'selectedSearch',
		'query'
    ])

	// #region notification
		const showNotification = ref(false)
		const notificationIcon = ref(0)
		const notificationTitle = ref('')
		const notificationSubtitle = ref('')
	// #endregion

	const srcColToDbExportMap = ref([])
	const loading = ref(false)

	/**
     * List of fields selectable for exporting
     * @yields {array}
     */
	const columnOptions = computed(() => {
		if(props.selectedSearch === 0) return VL_PLAYER_EXPORT_FIELDS
		else if(props.selectedSearch === 1) return VL_VENUE_EXPORT_FIELDS
		else return VL_CHAMP_EXPORT_FIELDS
	})
	
	/**
	 * Adds a field to the export order
	 * @function addColumn
	 */
	function addColumn(){
		let numColumns = srcColToDbExportMap.value.length
		let name = `Column ${String.fromCharCode(65 + numColumns)}`
	
		srcColToDbExportMap.value.push({
			exportFrom:{
				value: '',
				label: '',
				id: ''
			},
			name: name
		})
	}

	/**
	 * Removes a field in the export order
	 * @function removeColumn
	 * @param {Number} i - The index of the field to be removed
	 */
	function removeColumn(i){
		// remove element
		srcColToDbExportMap.value.splice(i,1)
		// update column labels
		updateColumns()
	}

	/**
	 * Moves a field in the export order
	 * @function moveRow
	 * @param {Number} fromIndex - The field's original index in the export order
	 * @param {Number} toIndex - The field's destination index in the export order
	 */
	function moveRow(fromIndex, toIndex) {
		let element = srcColToDbExportMap.value[fromIndex]
		srcColToDbExportMap.value.splice(fromIndex, 1)
		srcColToDbExportMap.value.splice(toIndex, 0, element)
		updateColumns()
		document.activeElement.blur()
	}

	/**
	 * Updates the labels of columns to export when a column is moved
	 * @function clientExport
	 */
	function updateColumns(){
		// update column letters
		srcColToDbExportMap.value.forEach((col, i) => {
			col.name = `Column ${String.fromCharCode(65 + i)}`
		})
	}

	/**
	 * Determines what type of export to run
	 * @function clientExport
	 */
	function exportData(){
		loading.value = true

		// if <= 500 results, run on client
		if(props.data.length <= 500){
			clientExport()
		}
		else {
			serverExport()
		}
	}

	/**
	 * Export data on client side
	 * @function clientExport
	 */
	async function clientExport(){
		// build query
		let fieldList = srcColToDbExportMap.value.map(field =>{
			return field.exportFrom.value
		}).join('\n')
		const getNode = `
			query GetNode($nodeTypeWithId: String!, $targetTypeWithId: String!) {
				getNode(
					nodeTypeWithId: $nodeTypeWithId
					targetTypeWithId: $targetTypeWithId
				) {
					${fieldList}
				}
			}
		`;

		// query for all in found set & bulid export object
		let exportData = []
		let headers = []
		for(const field of srcColToDbExportMap.value){
			headers.push(field.exportFrom.label)
		}
		exportData.push(headers)
		for(const row of props.data){
			const input = {
				nodeTypeWithId: row.nodeTypeWithId,
				targetTypeWithId: row.targetTypeWithId
			}
			const res = await api(getNode, input)
			let exportRow = []
			for(const field of srcColToDbExportMap.value){
				let col = res.data.getNode[field.exportFrom.value]
				exportRow.push(col)
			}
			exportData.push(exportRow)
		}
		
		
		// export
		var filename = "export.xlsx";
		var ws_name = "Export";

		if(typeof console !== 'undefined') console.log(new Date());
		var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(exportData);

		// add worksheet to workbook
		XLSX.utils.book_append_sheet(wb, ws, ws_name);

		// write workbook
		if(typeof console !== 'undefined') console.log(new Date());
		XLSX.writeFile(wb, filename);
		if(typeof console !== 'undefined') console.log(new Date());

		loading.value = false
	}

	/**
	 * Export data using server side Lambda
	 * @function serverExport
	 */
	async function serverExport(){
		// create export record
		const user = await Auth.currentAuthenticatedUser()
		const input = {
			type: 'd',
			name: new Date().getTime(),
			fieldMapping: JSON.stringify(srcColToDbExportMap.value),
			status: 'processing',
			user: user.username,
			query: JSON.stringify(props.query)
		}

		try{
			const res = await api(createExport, {input})
			console.log(res)
			// notificationTitle.value = 'Success'
			// notificationSubtitle.value = 'Your export has started running in the background. You will be able to download the file under the Exports screen when it is complete.'
			// notificationIcon.value = 0
			// showNotification.value = true
			// console.log(res)
		}
		catch(e) {
			console.log(e)
		}
		finally {
			loading.value = false
		}
	}

</script>
<style scoped>
	table{
		border-collapse: collapse;
		-webkit-user-select: none; /* Safari */
		-ms-user-select: none; /* IE 10+ and Edge */
		user-select: none; /* Standard syntax */
	}
</style>
import Venues from './Venues.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/venues',
      name: 'Venues',
      component: Venues,
      meta: {module: 'venues'}
    }
];
export const getMatchPlayRounds = /* GraphQL */ `
  query NodeByTargetTypeWithIdSortedByPosition(
    $targetTypeWithId: String!
    $position: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByTargetTypeWithIdSortedByPosition(
      targetTypeWithId: $targetTypeWithId
      position: $position
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        playerName
        rank
        opponents
        partners
		wonRound
		round
        up1
        up2
        up3
        up4
        up5
        up6
        up7
        up8
        up9
        up10
        up11
        up12
        up13
        up14
        up15
        up16
        up17
        up18
      }
      nextToken
    }
  }
`;
<template>
	<div>
	<teleport to="#notification">
        <tw-notification 
			v-if="showNotification" 
			@close="showNotification = false" 
			:title="notificationTitle" 
			:subtitle="notificationSubtitle" 
			:duration="8000" 
			:icon="notificationIcon"/>
    </teleport>
    <div class="sm:mx-6">
        <div class="mt-8 overflow-x-scroll card p-0 rounded-t-none">
            <div v-if="title" class="sticky left-0 px-4 py-3 border-b border-gray-200 sm:px-6 flex justify-between">
                <h3 class="text-2xl leading-6 text-gray-900">{{ title }}</h3>
				<span v-if="$slots.actions"><slot name="actions"></slot></span>
            </div>

            <table class="w-full divide-y divide-gray-300 rounded-b table-fixed text-xs sm:text-sm">
                <thead class="bg-usgaLightGray">
                    <tr>
						<th v-if="checkable" scope="col" class="relative w-16 pl-4 sm:pl-6">
							<input 
								type="checkbox" 
								class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-usgaRed focus:ring-usgaRed sm:left-6" 
								:checked="indeterminate || selectedRows.length === data.length" 
								:indeterminate="indeterminate" 
								@change="selectedRows = $event.target.checked ? data.map((r) => r.nodeTypeWithId) : []" />
							<tw-dropdown 
								v-if="selectedRows.length > 0"
								class="py-3 ml-5" 
								:items="multiRowActions" 
								position="left"
								:selectedTableRows="selectedRows">
								<template v-slot:icon><ChevronDownIcon /></template>
							</tw-dropdown>
						</th>
                        <th 
                            @click="sort(i)" 
                            v-for="(column, i) in config.columns" 
                            :key="column.prop" 
                            scope="col"
							:class="[column.headerClass, {'sticky left-0 bg-usgaLightGray' : i == 0}]"
                            class="text-left text-brand-darkGray px-4 sm:px-6 py-4 font-semibold truncate">
							<div v-if="i == 0" class="absolute inset-y-0 right-0 w-0.5 bg-gray-200"></div>
							<a href="#" class="uppercase group inline-flex items-center">
								{{ column.header }}
								<span v-if="sortColumn === i"  class="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
									<SolidChevronDownIcon class="h-5 w-5 transition-all duration-300" :class="{'transform rotate-180': sortDirection === 'ASC'}"  aria-hidden="true" />
								</span>
								<span v-else-if="column.sort" class="invisible ml-2 flex-none rounded text-usgaRed group-hover:visible group-focus:visible">
									<SolidChevronDownIcon class="h-5 w-5" aria-hidden="true" />
								</span>
							</a>
                        </th>
                        <th v-if="editable && canEdit" class="w-24">
                            <span class="sr-only">Edit</span>
                        </th>
                        <th v-if="viewable" class="bg-usgaLightGray w-24 sticky" :class="[rowActions && rowActions.length ? 'right-9' : 'right-0']">
							<div class="absolute inset-y-0 left-0 w-0.5 bg-gray-200"></div>
                            <span class="sr-only">View</span>
                        </th>
                        <th v-if="archive" class="bg-usgaLightGray w-24 sticky right-0">
							<div class="absolute inset-y-0 left-0 w-0.5 bg-gray-200"></div>
                            <span class="sr-only">Archive</span>
                        </th>
                        <th v-if="rowActions && rowActions.length" class="sticky right-0 w-10 bg-usgaLightGray">
                            <span class="sr-only">Table Row Actions</span>
                        </th>
                    </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 rounded-b">
                    <template v-for="(row, j) in data" :key="row.id">
                        <tr>
							<td v-if="checkable" class="relative w-16">
								<div v-if="selectedRows.includes(row.nodeTypeWithId)" class="absolute inset-y-0 left-0 w-0.5 bg-usgaRed"></div>
								<input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-usgaRed focus:ring-usgaRed sm:left-6" :value="row.nodeTypeWithId" v-model="selectedRows" />
							</td>
                            <td 
                                colspan="1" 
                                v-for="(column, i) in config.columns" 
                                :key="i" 
                                class="px-4 sm:px-6 table-cell truncate" 
                                :class="[column.class, {'py-2' : !config.columns[i].editable, 'sticky left-0 bg-white font-medium' : i == 0}]">
                                <template v-if="editData != j || !config.columns[i].editable" >
									<span v-if="column.prop === 'winner'">
										<router-link 
										class="block link"
										v-for="obj in JSON.parse(row[column.prop])" 
										:key="obj" 
										:to="{name: 'Player Detail', params: {id: obj.nodeTypeWithId}}">
											{{ obj.name }} ->
										</router-link>
									</span>
                                    <span v-else-if="Array.isArray(row[column.prop])" class="text-gray-900">
                                        <span v-if="typeof row[column.prop] === 'object'">
											<router-link 
											class="block link"
											v-for="obj in row[column.prop]" 
											:key="obj" 
											:to="{name: 'Venue Detail', params: {id: JSON.parse(obj).id}}">
												{{ JSON.parse(obj).name }} ->
											</router-link>
                                        </span>
                                        <span v-else>{{ (row[column.prop]).join(', ') }}</span>
                                    </span>
                                    <span v-else-if="column.type === 'Boolean'">
										<SolidCheckCircleIcon
											v-if="row[column.prop]"
											class="h-5 w-5 text-usgaRed"/>
										<CheckCircleIcon
											v-else
											class="h-5 w-5 text-usgaGray"/>
                                    </span>
                                    <span v-else class="text-gray-900" :title="row[column.prop]">
										<div v-if="i == 0" class="absolute inset-y-0 right-0 w-0.5 bg-gray-200"></div>
                                        {{column.prop == 'createdAt' || column.prop == 'updatedAt' || column.prop == 'playerProfileUpdateAt' ? row[column.prop] ? (new Date(row[column.prop])).toLocaleString() : '—' : row[column.prop] || '—'}}
                                    </span>
                                </template>
                                <template v-else-if="config.columns[i].editable">
									<tw-input
										v-model="editedData[j][column.prop]"
										type="text"
										:name="column.header"
										:id="column.prop + '-' + j"
										class="-mt-1"
										:label="column.header"
										:hiddenLabel="true">
									</tw-input>
                                </template>
                            </td>
                            <template v-if="canEdit">
                                <td v-show="editable && editData != j" class="w-24 border-l">
                                    <tw-button class="button btn-base btn-red-link w-full" @click="setupEdit(j)">Edit</tw-button>
                                </td>
                                <td v-show="editable && editData == j" class="w-24 border-l">
                                    <span class="w-1/2 inline-block text-center">
										<tw-button class="button btn-base btn-red-link w-full py-2.5" @click="editSave(j)"><CheckIcon class="p-0 m-0  h-4 w-full"/></tw-button>
									</span>
                                    <span class="w-1/2 inline-block border-l text-center">
										<tw-button class="button btn-base btn-gray-link w-full py-2.5" @click="editCancel(j)"><SolidXIcon class="p-0 m-0 h-4 w-full"/></tw-button>
									</span>
                                </td>
                            </template>
                            <td v-if="viewable" class=" bg-white w-24 sticky z-0" :class="[rowActions && rowActions.length ? 'right-9' : 'right-0']">
								<div class="absolute inset-y-0 left-0 w-0.5 bg-gray-200"></div>
								<tw-button
									class="button btn-base btn-red-link w-full px-0"
									@click="view({nodeId: row.nodeTypeWithId, targetId: row.targetTypeWithId, partners: row?.partners}, currentRecord != j ? j : -1)">
										{{currentRecord != j ? 'View' : 'Collapse'}}<span class="sr-only">, {{ row.name }}</span>
										<template v-slot:icon_right><SolidChevronRightIcon class="h-5 w-5 transition-all duration-300 -ml-3" :class="{'transform rotate-90': currentRecord === j}" aria-hidden="true" /></template>
								</tw-button>
                            </td>
							

                            <td v-if="archive" class=" bg-white w-24 sticky right-0">
								<div class="absolute inset-y-0 left-0 w-0.5 bg-gray-200"></div>
								<tw-button
									class="button btn-base btn-red-link w-full px-0"
									@click="setArchive(row.id, row.name, !row.archived, row.createdAt)">
										{{!row.archived ? 'Archive' : 'Restore'}}<span class="sr-only">, {{ row.name }}</span>
								</tw-button>
                            </td>

							<td class="w-10 sticky z-10 right-0 bg-white" v-if="rowActions && rowActions.length" >
								<div class="absolute inset-y-0 left-0 w-0.5 bg-gray-200"></div>
								<tw-dropdown 
									class="pt-2 px-2.5 absolute left-0 z-50" 
									:items="rowActions" 
									position="right"
									:tableRowIndex="j">
									<template v-slot:icon><SolidDotsHorizontalIcon /></template>
								</tw-dropdown>
							</td>
                        </tr>

                        <tr v-if="$slots.scorecard && (currentRecord === j)" class="transition-all">
                            <td :colspan="config.columns.length + 1 + canEdit" class="p-0">
                                <slot name="scorecard"></slot>
                            </td>
                        </tr>

                    </template>
					<tr v-if="data.length === 0 && !isLoading">									
						<td 
							:colspan="config.columns.length"
							class="text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500">
							{{ placeholder }}
						</td>
					</tr>

                    <tr v-show="isLoading">
                        <td class="text-center w-full p-4" :colspan="config.columns.length + 1">
                            <tw-loading></tw-loading>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
	</div>
</template>

<script setup>
	import { defineProps, defineEmits, computed, ref } from 'vue'
	import { updateEntryInfo } from './mutations.ts'
	import api from "@/utilities/api"

    const props = defineProps({
        config: Object,
        data: [Object],
        title: String,
        isLoading: Boolean,
        currentRecord: Number,
        editable: Boolean,
        canEdit: Boolean,
		rowActions: [Object],
        viewable: Boolean,
        archive: Boolean,
		checkable: Boolean,
		multiRowActions: [Object],
		sortable: {
            type: Boolean,
            default: false
        },
		sortMethod: {
            type: Function,
            default: () => {return}
        },
		placeholder: {
			type: String,
			default: "No Data"
		}
    })

	const emit = defineEmits([
		'view',
		'setArchive',
		'sort-query'
	])

	// #region notification
		const showNotification = ref(false)
		const notificationIcon = ref(0)
		const notificationTitle = ref('')
		const notificationSubtitle = ref('')
	// #endregion

	const selectedRows = ref([])
	const indeterminate = computed(() => selectedRows.value.length > 0 && selectedRows.value.length < props.data.length)
	const editData = ref(null)
	const editedData = ref(null);

	/**
	 * Navigates to detail page
	 * @function view
	 * @param {Object} pk - primary key (node and target ID) of entity to view 
	 * @param {Number} index - index of table row to view 
	 */
	function view(pk, index) {
		const payload = {
			...pk,
			'index': index
		}
		emit('view', payload)
	}

	/**
	 * Navigates to detail page
	 * @function view
	 * @param {Object} pk - primary key (node and target ID) of entity to view 
	 * @param {String} name - The name of the player to archive/restore
	 * @param {Number} index - index of table row to archive/restore 
	 */
	function setArchive(id, name, archive, createdAt) {
		const target = {
			'id': id,
			'name': name,
			'archive': archive ? 1 : 0,
			'createdAt': createdAt
		}
		emit('setArchive', target)
	}

	//Function to sort table based on selected sortable column
	const sortDirection = ref('DESC')
	// const sortColumn = computed(() => {
	// 	return props.config.columns.findIndex(function(item) {
	// 		return item.sort
	// 	})
	// })
	const sortColumn = ref(
		props.config.columns.findIndex(function(item) {
			return item.sort
		})
	)

	function sort(index) {
		//If not sortable column return
		if (!props.config.columns[index].sort) { return }

		//If already sorted column swap between ASC and DESC otherwise reset to ASC
		if (sortColumn.value === index) {
			sortDirection.value = sortDirection.value === 'ASC' ? 'DESC' : 'ASC'
		}
		else {
			sortDirection.value = 'ASC'
		}
		sortColumn.value = index
		//Emits information needed to requery data for sort
		const target = {
			sortDirection: sortDirection.value,
			query: props.config.columns[index].sort,
			queryName: props?.config?.columns[index]?.queryName,
		}
		emit('sort-query', target)
		return
	}


        //Computed needed fields for stacked mobile view
        // const stackedFields = computed (() =>{
        //     return props.config.columns.filter((item)=> {
        //         return item.stacked
        //     })
        // })

        function setupEdit(rowIndex){
          editedData.value = props.data.map(x => ({...x}))
          editData.value = rowIndex
        }

        function editCancel(rowIndex) {
          editedData.value[rowIndex] = props.data[rowIndex]

          editData.value = null
        }

        async function editSave(rowIndex) {
          var input = {
              nodeTypeWithId: editedData.value[rowIndex].nodeTypeWithId,
              targetTypeWithId: editedData.value[rowIndex].targetTypeWithId,
          }

          for (const column in props.config.columns){
            input[props.config.columns[column].prop] = editedData.value[rowIndex][props.config.columns[column].prop]
          }

          try{
              await api(updateEntryInfo, { input })
              const target = {
                sortDirection: sortDirection.value,
                query: this.config.columns[sortColumn.value].sort,
              }
              emit('sort-query', target)
          }catch(e){
              console.log(e)
          }finally{
              //Show edit button and remove inputs
              editData.value = null
          }
        }
</script>

<style scoped>
  td {
    table-layout: fixed;
    }
  
</style>
<template>
    <tw-heading title="Import History"></tw-heading>
    <div class="bg-white mx-6 my-6">
        <ul role="list" class="space-y-6">
            <li v-for="(item, i) in history" :key="i">
                <tw-history :payload="item" :index="i"></tw-history>
            </li>
        </ul>
		<div v-if="nextToken" class="flex w-full justify-center mt-4">
			<button type="button" class="button rounded-full btn-red btn-base  flex" @click="getImportHistory()"><span class="w-full"> Show More </span></button>
		</div>
  </div>
</template>

<script setup>
    import { ref } from 'vue'
    import api from "@/utilities/api"
    import { importByTypeSortedByCreatedAt } from './queries'

    const history = ref([])
	const nextToken = ref(null)

    ;( async () => {
        await getImportHistory()
    })()

    async function getImportHistory(){
        const input = {
            type: 'd',
            sortDirection: 'DESC',
			limit: 20,
			nextToken: nextToken.value
        }
        
        try {
            const res = await api(importByTypeSortedByCreatedAt, input)
			nextToken.value = res.data.importByTypeSortedByCreatedAt.nextToken
            history.value = [...history.value, ...res.data.importByTypeSortedByCreatedAt.items]
        }
        catch(e) {
            console.log(e)
        }
    }
</script>


import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "mt-1 relative" }
const _hoisted_5 = ["type", "name", "id", "disabled", "placeholder", "value"]
const _hoisted_6 = { class: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExclamationCircleIcon = _resolveComponent("ExclamationCircleIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", {
        for: _ctx.id,
        class: _normalizeClass(["block text-sm font-medium text-usgaDarkGray truncate", {'sr-only' : _ctx.hiddenLabel}])
      }, _toDisplayString(_ctx.label), 11, _hoisted_2),
      (_ctx.hint)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "text-sm text-gray-500",
            id: `hint-${_ctx.hint}`
          }, _toDisplayString(_ctx.hint), 9, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("input", {
        type: _ctx.type ?? 'text',
        name: _ctx.label,
        id: _ctx.id,
        disabled: _ctx.disabled,
        class: _normalizeClass(["shadow-sm focus:ring-usgaRed focus:border-usgaRed block w-full sm:text-sm border-gray-300 rounded-sm", {'invalid': !_ctx.valid, 'opacity-30 cursor-not-allowed bg-usgaGray': _ctx.disabled}]),
        placeholder: _ctx.placeholder,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        value: _ctx.modelValue
      }, null, 42, _hoisted_5),
      _withDirectives(_createElementVNode("div", _hoisted_6, [
        _createVNode(_component_ExclamationCircleIcon, {
          class: "h-5 w-5 text-usgaRed",
          "aria-hidden": "true"
        })
      ], 512), [
        [_vShow, !_ctx.valid]
      ])
    ]),
    _withDirectives(_createElementVNode("p", { class: "mt-2 text-sm text-usgaRed" }, _toDisplayString(_ctx.validation), 513), [
      [_vShow, !_ctx.valid]
    ])
  ]))
}
<template>
    <div :key="route.params.searchText">
        <tw-heading
			title="Search Results"
			:subtitle="subtitle"
			:key="route.params.searchText"></tw-heading>
		<div class="mx-6" >
			<div class="sm:hidden">
				<label for="tabs" class="sr-only">Select a tab</label>
				<select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-usgaRed focus:outline-none focus:ring-usgaRed sm:text-sm">
					<option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
				</select>
			</div>
			<div class="hidden sm:block">
				<div class="border-b border-gray-200">
				<nav class="-mb-px flex space-x-8" aria-label="Tabs">
					<p 
						@click="changeTab(i)" 
						v-for="(tab, i) in tabs" 
						:key="tab.name" 
						:class="[tab.current ? 'border-usgaRed text-usgaRed ' : 'cursor-pointer border-transparent text-black hover:text-black hover:border-usgaRed ', 'whitespace-nowrap py-4 px-1 border-b-4 font-medium']" 
						:aria-current="tab.current ? 'page' : undefined" >
						{{ tab.name }}
					</p>
				</nav>
				</div>
			</div>
		</div>
		<div class="mx-6 my-4 grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4">
			<div 
				v-for="hit in displayedHits" 
				:key="hit" 
				@click="goToSearchResult(hit)"
				class="relative flex items-center space-x-3 rounded-sm border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-usgaRed focus-within:ring-offset-2 hover:border-gray-400">
				<div class="flex-shrink-0">
					<UsersIcon class="h-8 w-8 text-usgaBlue" aria-hidden="true" v-if="hit.fields.type === 'p'" />
					<FlagIcon class="h-8 w-8 text-usgaRed" aria-hidden="true" v-if="hit.fields.type === 'c'" />
					<LocationMarkerIcon class="h-8 w-8 text-usgaDarkGray" aria-hidden="true" v-if="hit.fields.type === 'v'" />
				</div>
				<div class="min-w-0 flex-1">
					<a href="#" class="focus:outline-none">
						<span class="absolute inset-0" aria-hidden="true" />
						<p class="text-sm font-medium text-gray-900">{{ hit.fields.name }}</p>
						<p class="truncate text-sm text-gray-500">{{ hit.fields.country }}</p>
					</a>
				</div>
			</div>
			<div class="col-span-1 sm:col-span-4 text-center py-4 text-usgaDarkGray" v-if="displayedHits.length === 0">
				No results
			</div>
		</div>
    </div>
</template>

<script setup>
	import {
		ref,
		computed,
		watch
	} from 'vue'

	import {
		useRoute,
		useRouter
	} from 'vue-router'


	//Sets up route to retrieve params from it
	const route = useRoute()
	const router = useRouter()
	const hits = ref([])
	const numberOfHits = ref(0)

	const displayedHits = computed(() => {
		if(tabs.value[0].current) {
			return hits.value
		}
		else if(tabs.value[1].current) {
			return hits.value.filter(hit => {
				return hit.fields.type === 'p'
			})
		}
		else if(tabs.value[2].current) {
			return hits.value.filter(hit => {
				return hit.fields.type === 'c'
			})
		}
		else if(tabs.value[3].current) {
			return hits.value.filter(hit => {
				return hit.fields.type === 'v'
			})
		}
		else {
			return hits.value
		}
	})

	const q = computed(() =>{
		return route.params.searchText
	})

	//Sets up breadcrumbs for heading
	const subtitle = computed( () => {
		return {description: ' Results for "' + q.value + '"'}

	})

	// #region toggle tab control
	const tabs = ref([
		{ name: 'All', current: true },
		{ name: 'Players', current: false },
		{ name: 'Championships', current: false },
		{ name: 'Venues', current: false },
	])
    function changeTab(i) {
		tabs.value.forEach(tab => {tab.current = false})
		tabs.value[i].current = true
    }
	// #endregion

	let url = process.env.VUE_APP_CLOUD_SEARCH_API_URL + '?q=' + encodeURIComponent(route.params.searchText) + '&size=100'
	fetch(url)
		.then((response) => response.json())
		.then((data) => {
			console.log(data.hits.hit)
			numberOfHits.value = data.hits.found
			hits.value = data.hits.hit
		});

	

	watch(q, (newQ, oldQ) =>{
		if(newQ != oldQ){
			hits.value = []
			let url = process.env.VUE_APP_CLOUD_SEARCH_API_URL + '?q=' + encodeURIComponent(route.params.searchText) + '&size=100'
			fetch(url)
				.then((response) => response.json())
				.then((data) => {
					numberOfHits.value = data.hits.found
					hits.value = data.hits.hit
					changeTab(0)
				});
		}
	})

	//eslint-disable-next-line no-unused-vars
	function goToSearchResult(hit){
		if(hit.fields.type === 'p'){
			router.push({name: 'Player Detail', params: {id: hit.fields.nodetypewithid}})
		}
		else if(hit.fields.type === 'c'){
			router.push({name: 'Championship Detail', params: {id: hit.fields.nodetypewithid}})
		}
		else if(hit.fields.type === 'v'){
			router.push({name: 'Venue Detail', params: {id: hit.fields.nodetypewithid}})
		}
	}
</script>

<template>
    <tw-heading 
		@update-title="updateTitle" 
		@update-city="updateCity"
		@update-country="updateCountry" 
		:title="playerData.name" 
		:bread-crumb="breadCrumbs" 
		:subtitle="subtitle" 
		:loading="isLoadingDetails" 
		ref="heading">
        <!-- <tw-avatar class="h-14 w-14 mt-1 mr-2" :img="playerData.img"></tw-avatar> -->
    </tw-heading>
    <div v-if="canEdit" class="px-6 my-4 sm:float-right sm:-mt-16 md:float-none md:mt-4 lg:float-right lg:-mt-16">
        <tw-button v-show="!editPlayerData" class="button btn-base btn-red   w-full sm:w-auto md:w-full lg:w-auto" :rounded="true" @click="edit">Edit Player</tw-button>
        <tw-button v-show="editPlayerData"  class="button btn-base btn-red   w-full mr-0 sm:mr-4 sm:w-24 md:w-full md:mr-0 lg:mr-4 lg:w-24" :rounded="true" @click="editSave">Save</tw-button>
        <tw-button v-show="editPlayerData"  class="button btn-base btn-white w-full mt-4 sm:mt-0 sm:w-24 md:w-full md:mt-4 lg:mt-0 lg:w-24" :rounded="true" @click="editCancel">Cancel</tw-button>

        <Menu as="div" class="relative inline-block text-left ml-2">
            <div>
            <MenuButton v-show="!editPlayerData" class="flex items-center rounded-full button p-3 btn-white bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-usgaRed focus:ring-offset-2 focus:ring-offset-gray-100">
                <span class="sr-only">Open options</span>
                <SolidDotsHorizontalIcon class="h-4 w-4"></SolidDotsHorizontalIcon>
            </MenuButton>
            </div>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']" @click="mergeModalOpen = true">Merge Players</a>
                </MenuItem>
                </div>
            </MenuItems>
            </transition>
        </Menu>
    </div>
    <div class="grid grid-cols-4 px-6 content-center gap-4 text-gray-500 grid-flow-row">
        <div class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-1">
			<div v-show="isLoadingDetails " >
				<div v-if="!isRestricted" class="field-label">Date of Birth</div>
				<div v-else>Age</div>
                <div class="animate-pulse mt-2 h-2 bg-usgaGray rounded"></div>
            </div>
			<div v-if="!isLoadingDetails  && !isRestricted">
				<dt class="field-label">Date of Birth</dt>
				<dd class="text-gray-900">
					{{ $filters.date(playerData.date) || '—' }}
				</dd>
			</div>
			<div v-else-if="!isLoadingDetails && isRestricted">
				<dt class="">Age</dt>
				<dd class="text-gray-900">
					<span v-if="playerData.date!=null&&playerData.date!=''">{{ restrictedDOB}}</span><span v-else>—</span>
				</dd>
			</div>
        </div>
        <div class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-1">
            <dt class="field-label">Championships Played</dt>
            <dd v-show="!isLoadingDetails" class="text-gray-900">
                {{ playerData.championshipsPlayed || '—' }}
            </dd>
            <div v-show="isLoadingDetails" class="animate-pulse mt-2">
                <div class="h-2 bg-usgaGray rounded"></div>
            </div>
        </div>
        <div class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-1">
            <dt class="field-label">Championships Won</dt>
            <dd v-if="!isLoadingDetails" class="text-gray-900">
                {{ playerData?.championshipsWon?.length || '—' }}
            </dd>
            <div v-else class="animate-pulse mt-2">
                <div class="h-2 bg-usgaGray rounded"></div>
            </div>
        </div>
    </div>
    <div class="my-6">
        <tw-table  
        @view="setCurrentChampionship" 
        @sort-query="sortColumn" 
        :config="playerChampionshipsTableConfig" 
        :data="data"
        title="Championships" 
		placeholder="No Championships"
        :currentRecord="currentChampionship" 
        :editable="true" 
        :isLoading="isLoadingList" 
        :canEdit="canEdit" 
        :viewable="true">
            <template v-slot:scorecard>    
                <tw-scorecard
                context="player"
                :roundTypes="currentRoundType"
                :playerId="playerId"
                :targetId="targetId"
                :canEdit="canEdit"/>
            </template>
        </tw-table>
    </div>
    <div v-if="nextToken" class="flex w-full justify-center pl-6 -mt-2">
        <button type="button" class="button rounded-full btn-red btn-base  flex" @click="getChampionships({sortDirection: sortDirection, query: listChampionshipsByPlayer})"><span class="w-full"> Show More </span></button>
    </div>

    <div class="mx-6">
		<div>
			<div class="sm:hidden">
				<label for="tabs" class="sr-only">Select a tab</label>
				<select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-usgaRed focus:outline-none focus:ring-usgaRed sm:text-sm">
					<option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
				</select>
			</div>
			<div class="hidden sm:block" >
				<div class="border-b border-gray-200">
				<nav class="-mb-px flex space-x-8" aria-label="Tabs" v-if="!isRestricted">
					<p @click="changeProfileMenu(i)" v-for="(tab, i) in tabs" :key="tab.name" :class="[tab.current ? 'border-usgaRed text-usgaRed ' : 'cursor-pointer border-transparent text-black hover:text-black hover:border-usgaRed ', 'whitespace-nowrap py-4 px-1 border-b-4 font-medium']" :aria-current="tab.current ? 'page' : undefined">{{ tab.name }}</p>
				</nav>
				<nav class="-mb-px flex space-x-8" aria-label="Tabs" v-else>
					<p @click="changeProfileMenu(i)" :key="tabs[0].name" :class="[tabs[0].current ? 'border-usgaRed text-usgaRed ' : 'cursor-pointer border-transparent text-black hover:text-black hover:border-usgaRed ', 'whitespace-nowrap py-4 px-1 border-b-4 font-medium']" :aria-current="tabs[0].current ? 'page' : undefined">{{ tabs[0].name }}</p>
				</nav>
				</div>
			</div>
		</div>
		<div v-if="Object.keys(playerData).length != 0 && tabs[0].current" class="mt-4 -mx-6">
			<PlayerProfileForm 
				:playerInfo="playerData" 
				:editable="false" 
				:parentEditing="editPlayerData" 
				:isRestricted="isRestricted" 
				:restrictedDOB="restrictedDOB" 
				@update-player-info="updatePlayerProfileInfo" 
				ref="childComponent">
			</PlayerProfileForm>
		</div>
		<div v-if="Object.keys(playerData).length != 0 && tabs[1].current && !isRestricted" class="mt-4 mb-4">
			<ChangeHistory :userId="id" ></ChangeHistory>
		</div>
		<div v-if="Object.keys(playerData).length != 0 && tabs[2].current && !isRestricted" class="mt-4 mb-4">
			<EmailHistory :userId="id" />
		</div>
    </div>
    

    <!-- Modal -->
    <TransitionRoot as="template" :show="mergeModalOpen">
        <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
                    <div class="text-center sm:mt-0 sm:text-left mb-10">
                        <DialogTitle as="h1" class="text-2xl leading-6 font-medium text-gray-900">Merge Players</DialogTitle>
                        <p class="text-sm leading-6 font-medium text-gray-900">Championships and round results will be combined on the resulting player.</p>
                    </div>
                    <div class="flex">
                        <div class="mr-auto h-40 w-auto border border-usgaGray rounded-sm p-4">
                            <tw-heading class="-mt-4" @update-title="updateTitle" @update-city="updateCity" @update-country="updateCountry" :title="playerData.name" :subtitle="subtitle" :editable="false" :loading="isLoadingDetails" ref="heading">
                                <!-- <tw-avatar class="h-14 w-14 mt-1 mr-2" :img="playerData.img"></tw-avatar> -->
                            </tw-heading>
                            <input type="radio" id="currentPlayer" class="text-usgaRed focus:ring-usgaRed" value="source" v-model="overwritePlayer">
                            <label class="w-full text-center ml-2" for="currentPlayer">Overwrite the resulting Player Profile with this player's data.</label>
                        </div>
                        <div class="my-auto"><ArrowSmRightIcon class="h-6 w-6"></ArrowSmRightIcon></div>
                        <div class="ml-auto h-40 w-auto border border-usgaGray rounded-sm p-4">
                            <div class="flex py-2 mt-1 ml-6"> 
                                <!-- <tw-avatar class="h-14 w-16 mt-1 mr-2" :img="playerData.img"></tw-avatar> -->
                                <tw-selectmenu 
                                    class="w-full"
                                    v-model="selectedPlayer" 
                                    :clearable="true" 
                                    :searchable="true"
                                    :searchMethod="searchMethod"
                                    label="" 
                                    :options="searchResults" 
                                />
                            </div>
                            <input type="radio" id="currentPlayer" class="text-usgaRed focus:ring-usgaRed" value="target" v-model="overwritePlayer">
                            <label class="w-full text-center ml-2" for="currentPlayer">Overwrite the resulting Player Profile with this player's data.</label>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-10 sm:flex sm:flex-row justify-end space-x-4">
                        <tw-button
                            class="button btn-base btn-white flex-1 sm:flex-none sm:w-24 md:w-36" 
                            :rounded="true" 
                            @click="mergeModalOpen = false">
                                Cancel
                        </tw-button>
                        <tw-button
                            v-if="overwritePlayer && Object.keys(selectedPlayer).length != 0"
                            class="button btn-base btn-red flex-1 sm:flex-none sm:w-24 md:w-36" 
                            :rounded="true"
                            @click="mergePlayers">
                                Merge Players
                        </tw-button>
                        <tw-button
                            v-else
                            disabled
                            class="button btn-base btn-red flex-1 sm:flex-none sm:w-24 md:w-36" 
                            :rounded="true"
                            @click="mergePlayers">
                                Merge Players
                        </tw-button>
                    </div>
                </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<script setup>
    // #region import statements
    import { defineProps, ref, onMounted, onBeforeUnmount, onBeforeMount,  } from 'vue'
    import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { listChampionshipsByPlayer, getPlayerName, getPlayersForSelect, getChampionshipRoundTypes  } from './queries.ts'
    import { onBeforeRouteLeave } from 'vue-router';
    import api from "@/utilities/api"
    import EventBus from "@/event"
    import { updatePlayerInfo } from './mutations.ts'
    import { Auth, API } from 'aws-amplify'
    import PlayerProfileForm from '@/components/PlayerProfiles/PlayerProfileForm.vue'
    import ChangeHistory from '@/components/ChangeHistory/ChangeHistory.vue'
    import EmailHistory from '../components/EmailHistory.vue'
	import { toTitleCase } from '@/utilities/sanitize.js'
    // #endregion

    // #region setup
    const props = defineProps([
        'id'
    ])

    const mergeModalOpen = ref(false)
    const overwritePlayer = ref(null)

    const isLoadingDetails = ref(true)
    const isLoadingList = ref(false)
    const breadCrumbs = ref([])
    const subtitle = ref({})
    const playerData = ref({})
    const heading = ref(null)
            
	
    //Sets up table headers
    const playerChampionshipsTableConfig = {
        columns: [
            {header: 'Name', prop: 'name', sort: listChampionshipsByPlayer, class: 'w-52 sm:w-64', headerClass: 'w-52 sm:w-64', editable: false},
            // {header: 'Year', prop: 'date' , mobile: false, mobileBp: 'lg', stacked: true, class: ' text-gray-500 lg:table-cell', editable: false},
            {header: 'Position', prop: 'rank', class: 'w-20 sm:w-36', headerClass: 'w-20 sm:w-36', editable: true},
            {header: 'Score', prop: 'total', class: 'w-20 sm:w-36', headerClass: 'w-20 sm:w-36', editable: true},
        ],
        destination: 'Scorecard',
    }
    // #endregion

    // #region user authentication
    const canEdit = ref(false)
    // #restrict info from categories of users
    const isRestricted = ref(true)

    onBeforeMount( async () => {
        try {
            const user = await Auth.currentAuthenticatedUser()
            isRestricted.value = user.username.indexOf('azureb2c') > -1
            canEdit.value = user?.attributes['custom:azureGroup']?.indexOf(process.env.VUE_APP_ADMIN_GROUP) > -1
        }
        catch(e) {
            console.log(e)
        }
        
    });
    // #endregion
    const currentChampionship = ref(-1)
    const currentRoundType = ref(null)
    const playerId = ref(null)
    const targetId = ref(null)
    function setCurrentChampionship(payload) {
		let nodeId = null
		if(payload.partners) {
			let sortedPlayerIds = payload.partners.sort()
			nodeId= 'pp-' + sortedPlayerIds.map(id => {return id.split('p-')[1]}).join('-')
		}
		else {
			nodeId = payload.nodeId
		}
        currentChampionship.value = payload.index
        currentRoundType.value = payload.index > -1 ? data.value[payload.index].roundType : null
        playerId.value = nodeId
        targetId.value = payload.targetId.split(/^c-/)[1]
    }
    // #region edit
    const editPlayerData = ref(false)
    const updatedDOB = ref('')
    const updatedChampionshipsPlayed = ref('')
    const updatedName = ref('')
    const updatedCity = ref('')
    const updatedCountry = ref('')

    //restricted values
    const restrictedDOB = ref('')

    //Get emits and updated values in header.
    function updateTitle(title) {
        updatedName.value = title
    }
    function updateCity(city) {
        updatedCity.value = city
    }
    function updateCountry(country) {
        updatedCountry.value = country
    }

    function edit(){
        editPlayerData.value = true
        heading.value.setInputs()
    }

    function editCancel() {
        //Reset updated values to current values of info
        updatedDOB.value = playerData.value.date
        updatedChampionshipsPlayed.value = playerData.value.championshipsPlayed
        updatedName.value = playerData.value.name
        updatedCity.value = playerData.value.city
        updatedCountry.value = playerData.value.country

        editPlayerData.value = false
    }

    async function editSave() {
        const input = {
            nodeTypeWithId: playerData.value.nodeTypeWithId,
            targetTypeWithId: playerData.value.targetTypeWithId,
            type: 'p',
            championshipsPlayed: updatedChampionshipsPlayed.value,
            date: updatedDOB.value,
            name: updatedName.value,
            city: updatedCity.value,
            country: updatedCountry.value,
        }
        try{
            const res = await api(updatePlayerInfo, { input })

            //Update player data with updated values
            playerData.value.championshipsPlayed = res.data.updateNode.championshipsPlayed
            playerData.value.date = res.data.updateNode.date    
            playerData.value.name = res.data.updateNode.name
            playerData.value.city = res.data.updateNode.city
            playerData.value.country = res.data.updateNode.country
            subtitle.value = {
                city: playerData.value.city,
                country: playerData.value.country,
            }

            await childComponent.value.saveEdit()

        }catch(e){
            console.log(e)
        }finally{
            //Show edit button and remove inputs
            editPlayerData.value = false
        }
    }

    //Check if user is editing and ask if they want to save changes or not.
    onBeforeRouteLeave ((to, from, next) => {
        if (editPlayerData.value){
            const answer = window.confirm('Do you want to leave without saving your changes?')

            if (answer) {
                next()
            }else{
                next(false)
            }
        }else{
            next()
        }
    })
    // #endregion
            
    // #region setup infinite scroll
    onMounted( () => {
        EventBus.on('infinite-scroll', async () =>{
            const payload = {
                sortDirection: sortDirection.value,
                query: listChampionshipsByPlayer,
            }
            if (nextToken.value) await getChampionships(payload)
            EventBus.trigger('infinite-scroll-end', null)
        })
    })

    onBeforeUnmount( () => {
        EventBus.off('infinite-scroll', null)
    })
    // #endregion

    // #region query and sort table
    const error = ref(null)
    const data = ref([]);
    const nextToken = ref(null)
    const sortDirection = ref('ASC')

    function sortColumn(payload){
        sortDirection.value = payload.sortDirection
        nextToken.value = null
        data.value = []
        getChampionships(payload)
    }

    //Gets all championships data for current player from graphql query
    async function getChampionships(payload){
        //Query setup to get championships based on given player
        const input = {
            limit: 10,
            nodeTypeWithId: props.id,
            targetTypeWithId:{
                beginsWith: 'c'
            },
            sortDirection: payload.sortDirection, 
            nextToken: nextToken.value
        }
        try{
            const res = await api(payload.query, input)
            if(data.value.length==0){
                data.value = res.data.listNodes.items
            }else{
                data.value = [...data.value, ...res.data.listNodes.items]
            }
            nextToken.value = res.data.listNodes.nextToken
        } catch(e){
            error.value = e
            console.log(error.value)
        } finally {
            //Gets only the year from the date field
            data.value = data?.value?.map((item) => ({
                ...item,
                date: item.date?(new Date(item.date)).getFullYear():''
            }))
            for (let i=0; i<data.value.length; i++){
                let roundType = await getRoundTypes(data.value[i].targetTypeWithId)
                data.value[i].roundType = roundType
            }
            isLoadingList.value = false
        }
    }

    async function getRoundTypes(pl){
        const input = {
            nodeTypeWithId: pl,
            targetTypeWithId: pl
        }
        const result = await api(getChampionshipRoundTypes, input)
        let roundTypes = result?.data.getNode.roundTypes
        return roundTypes
    }

    (async () => {
        isLoadingDetails.value = true
        isLoadingList.value = true
        //Runs query to get player name using id
        const input = {
            nodeTypeWithId: props.id,
            targetTypeWithId: props.id,
        }
        try{
            const res = await api(getPlayerName, input)
            playerData.value = res.data.getNode
        } catch(e){
            error.value = e
            console.log(error.value)
        }

        //Set default values of updated info
        updatedDOB.value = playerData.value.date
        updatedChampionshipsPlayed.value = playerData.value.championshipsPlayed
        updatedName.value = playerData.value.name
        updatedCity.value = playerData.value.city
        updatedCountry.value = playerData.value.country

        //populate restricted values
        if (isRestricted.value){
            let today = new Date()
            today = today.getTime()
            let DOB = Date.parse(playerData.value.date)
            // let difference = today - DOB
            restrictedDOB.value = Math.floor((today - DOB)/31536000000).toString()
        }

        breadCrumbs.value = [
            {name: 'Players', route: {name: 'Players'}},
            {name: 'Player Detail'},
        ]

        subtitle.value = {
            city: playerData.value.city,
            country: playerData.value.country,
        }
        

        
        //Populates chmapionship table on inital loading of page
        const payload = ref({
            sortDirection: 'ASC',
            query: listChampionshipsByPlayer,
        })
        await getChampionships(payload.value)
        isLoadingDetails.value = false
    })()

    // #endregion

    // #region 

    /**
    * Sets the second selected player to merge with
    * @function setSelectedMergePlayer
    */
    // function setSelectedMergePlayer(selected) {
    //     selectedMergePlayer2.value = selected
    // }

    /**
    * Call api to merge selected players
    * @function mergePlayers
    */
    function mergePlayers() {
        mergeModalOpen.value = false
        const apiName = 'playerMerge'
        const path = '/merge'

        const post = {
            body: {
                source: {
                    id: playerData.value.nodeTypeWithId,
                    sourceOfTruth: overwritePlayer.value == 'source' ? true : false
                },
                target: {
                    id: selectedPlayer.value.nodeTypeWithId,
                    sourceOfTruth: overwritePlayer.value == 'target' ? true : false
                }
            }
        }

        API.post(apiName, path, post)
    }

    const searchResults = ref([])
    const selectedPlayer = ref({})
    const searchMethod = async function(searchText) {
        const lowerInput = {
            type: 'p',
            sortDirection: 'ASC',
            name: {
                beginsWith: searchText.toLowerCase()
            }
        }
        const upperInput = {
            type: 'p',
            sortDirection: 'ASC',
            name: {
                beginsWith: toTitleCase(searchText)
            }
        }
        const lowerRes = await api(getPlayersForSelect, lowerInput)
        const upperRes = await api(getPlayersForSelect, upperInput)
        const lowerMap = lowerRes.data.nodeByTargetType.items.map(item => {
            return {
                ...item,
                label: item.name
            }
        })
        const upperMap = upperRes.data.nodeByTargetType.items.map(item => {
            return {
                ...item,
                label: item.name
            }
        })
		searchResults.value = [...lowerMap, ...upperMap]
	}
    
    // #region PLAYER PROFILE STUFF
    
    const childComponent = ref(null)

    // const showPlayerProfile = ref(true)
    // const showChangeHistory = ref(false)
    const tabs = ref([
        { name: 'Player Profile', current: true },
        { name: 'Change History', current: false },
        { name: 'Email History', current: false },
    ])
	
    
    function changeProfileMenu(i) {
		tabs.value.forEach(tab => {tab.current = false})
		tabs.value[i].current = true
        // showPlayerProfile.value = !showPlayerProfile.value
        // showChangeHistory.value = !showChangeHistory.value

    }

    function updatePlayerProfileInfo(obj){
        playerData.value = obj
    }

    // #endregion
</script>


<style scoped>

    .slide-fade-enter-active {
        transition: all 0.8s ease-out;
    }

    /* .slide-fade-leave-active {
        transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
    } */

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateX(20px);
        opacity: 0;
    }
</style>
<template>
    <div class="h-screen flex overflow-hidden bg-gray-100">
        <div class="flex flex-col w-0 flex-1 overflow-hidden" >
            <div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow ribbon">
                <div class="flex-1 flex justify-between items-center px-6">
                    <div class="transition-all">
                        <img src="../assets/img/USGA-Logo.png" class="w-24 sm:w-36">
                    </div>
					
                    <div class="flex">
						<div id="ribbon-actions"></div>
                        <!-- Profile dropdown -->
                        <Menu as="div" class="ml-4">
                            <div>
								<MenuButton class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-usgaRed">
									<span class="sr-only">Open user menu</span>
									<span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-usgaDarkGray">
										<span class="uppercase font-medium leading-none text-white">{{initial}}</span>
									</span>
								</MenuButton>
                            </div>
                            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
								<MenuItems class="origin-top-right absolute right-0 mt-2 min-w-min rounded-sm shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
									<MenuItem>
										<div class="px-4 pt-2 pb-3 text-usgaBlue font-bold text-base border-b">{{userEmail}}</div>
									</MenuItem>
									<MenuItem>
										<div class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100" @click="signOut">Sign Out</div>
									</MenuItem>
								</MenuItems>
                            </transition>
                        </Menu>
                    </div> 
                </div> 
            </div>

            <main class="flex-1 relative overflow-y-auto focus:outline-none bg-usgaLightGray" id="content">
                <!-- Main content -->
                <div class="min-h-full max-w-screen-2xl mx-auto">
                    <PlayerProfile :needsTeleported="needsTeleported"></PlayerProfile>
                </div>
            </main>  
        </div>
    </div>
</template>

<script setup>
    // #region IMPORT STATEMENTS
	import PlayerProfile from '@/modules/playerProfiles/PlayerProfile.vue'
    import {
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
    } from '@headlessui/vue'
    import  { Auth } from 'aws-amplify'
    import { useStore} from 'vuex'
    import EventBus from '@/event'
    import { ref, onMounted, onBeforeUnmount } from 'vue'

    // #endregion


    // #region VARIABLES
    const store = useStore()
    const userEmail = store.state.user.email ?? ""
    const initial = userEmail.substring(0,1)

    // const ribbonHeadingHtml = ref(null)
    // const contentRef = ref(null)
    const scrollingDisabled = ref(false)
	const needsTeleported = ref(false)
    // #endregion


    async function handleScroll() {
        let ribbonActions = document.getElementById("ribbon-actions")
        let content = document.getElementById("content")
        let totalHeight = 0
        let ribbons = document.querySelectorAll('.z-10.shadow')

        ribbons?.forEach((ribbon) => {
            totalHeight+= ribbon.clientHeight;
        })
        totalHeight += ribbonActions?.clientHeight

        //infinite scroll
        if(content.clientHeight + content.scrollTop >= content.scrollHeight && !scrollingDisabled.value){
            scrollingDisabled.value = true
            EventBus.trigger('infinite-scroll', null)
        }

        // add ribbon heading
        if(content.scrollTop >= totalHeight) {
            // ribbonHeadingHtml.value = ribbonActions.innerHTML
			needsTeleported.value = true
        }
        else {
            // ribbonHeadingHtml.value = null
			needsTeleported.value = false
        }
    }

    onMounted(() => {
        document.addEventListener('scroll', () => {
            handleScroll()
        }, {
            capture: true,
            passive: true
        })

        EventBus.on('infinite-scroll-end', () =>{
             scrollingDisabled.value = false
        })
    })

    onBeforeUnmount(() => {
        document.removeEventListener('scroll', handleScroll)
        EventBus.off('infinite-scroll-end', null)
    })

    async function signOut() {
        try {
            // clear user in store
            store.commit('setUser', {})
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

</script>
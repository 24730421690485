import QuickSearch from './QuickSearch.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/search/:searchText',
      name: 'QuickSearch',
      component: QuickSearch,
      meta: {module: 'quickSearch'}
    }
];
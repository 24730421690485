<template>
    <div class="flex items-center">
        <PlayerBanner class="mt-6 mb-4" :playerInfo="playerInfo" :loading="false">
            <!-- <tw-avatar class="h-14 w-14 mt-1 mr-2"></tw-avatar> -->
        </PlayerBanner>
    </div>
    <PlayerProfileForm 
		v-if="Object.keys(playerInfo).length != 0" 
		:playerInfo="playerInfo" 
		:editable="true" 
		:needsTeleported="needsTeleported"
        :isRestricted="isRestricted"
		@update-player-info="updatePlayerInfo" 
		@editing="isEditing"/>
    <div v-if="!confirmed && !editing" class="text-white bg-usgaRed -shadow-xl w-full items-center fixed bottom-0 left-0">
        <div class="py-8 px-6 mx-auto grid grid-cols-12 max-w-screen-2xl items-center">
			<div class="col-span-12 sm:col-span-8">
				<h1 class="text-2xl sm:text-3xl font-bold">Confirm or edit your information</h1>
				<p class="text-base sm:text-lg" >Please edit your Player Profile information above or confirm the information is accurate.</p>
			</div>
            <div class="flex-none sm:flex sm:justify-end items-center mt-4 col-span-12 sm:col-span-4 ">
				<div class="flex items-center">
					<input v-model="confirmCheckbox" class="mr-2 text-black focus:ring-usgaRed" type="checkbox" />
					<p>I confirm my Player Profile information is accurate.</p>
				</div>
				<tw-button 
					class="ml-0 mt-4 sm:ml-4 sm:mt-0 button btn-base btn-white w-full md:w-24" 
					:disabled="!confirmCheckbox" 
					@click="confirm"
					:rounded="true">
					Continue
				</tw-button>
			</div>
        </div>
    </div>
</template>

<script setup>
    // #region IMPORT STATEMENTS

    import { useStore } from 'vuex'
    import { ref, onBeforeMount, onBeforeUnmount, onMounted, defineProps } from 'vue'
    import PlayerProfileForm from '@/components/PlayerProfiles/PlayerProfileForm.vue'
    import PlayerBanner from '@/components/PlayerProfiles/PlayerBanner.vue'
    import { getPlayerByUserId } from './queries.ts'
    import { updatePlayerProfileInfo } from './mutations.ts'
    import EventBus from '@/event'
    import api from "@/utilities/api"
    import { Auth } from 'aws-amplify'
    // #endregion


    // #region VARIABLES

    const store = useStore()
	// eslint-disable-next-line no-unused-vars
	const props = defineProps([
        'needsTeleported'
    ])
	
    const userId = store.state.user.sub
    const playerInfo = ref({})

    const confirmed = ref(true)
    const confirmCheckbox = ref(false)

    const editing = ref(false)
    // #restrict info from categories of users
    const isRestricted = ref(false)

    // const editing = ref(false)

    // #endregion

    // #region BEFORE MOUNTED

    onBeforeMount( async () => {
        try {
            const input = {
                limit: 1,
                userId
            }
            let res = await api(getPlayerByUserId, input)
            let playerData = res.data.nodeByUserId.items
            playerInfo.value = playerData[0]
            const user = await Auth.currentAuthenticatedUser()
            isRestricted.value = user.username.indexOf('azureb2c') > -1
        } 
		catch(e) {
            console.log(e)
        }
		finally {
            // console.log(Math.floor(Date.now() / 1000) - playerInfo.value.playerProfileConfirmedAt)
			const year = 60 * 60 * 24 * 365
			const lastConfirmed = Math.floor(Date.now() / 1000) - playerInfo.value.playerProfileConfirmedAt
            if (!playerInfo.value.playerProfileUpdateAt || lastConfirmed > year){
                confirmed.value = false
            }
        }
    })

    // #endregion

    // #region INFINITE SCROLL SETUP AND DESTROY

	onMounted(() => {
        EventBus.on('infinite-scroll', async() => {
            EventBus.trigger('infinite-scroll-end', null)
        })
    })

    onBeforeUnmount( () => {
        EventBus.off('infinite-scroll', null)
    })

    // #region


    // #region UPDATE PLAYER INFO AFTER EDIT

    function updatePlayerInfo(obj) {
        playerInfo.value = Object.assign({}, obj)
        confirmed.value = true
    }

    // #endregion


    // #region SET EDIT VARIABLE

    function isEditing(bool) {
        editing.value = bool
    }

    // #endregion


    // #region SET EDIT VARIABLE

    async function confirm() {
        try {
            var editHistory = JSON.parse(playerInfo.value?.playerProfileEditHistory || "[]")
			const timestamp = new Date().toISOString()

            var confirmedHistoryObj = {
                time: timestamp,
                user: store.state.user.name,
                type: 'confirmed'
            }

			if(editHistory){
				editHistory?.unshift(confirmedHistoryObj)
			}
			else {
				editHistory = [confirmedHistoryObj]
			}
			
            //Create input obj for api call    
            var input = {
                nodeTypeWithId: playerInfo.value.nodeTypeWithId,
                targetTypeWithId: playerInfo.value.targetTypeWithId,
                type: 'p',
				needsPlayerProfileEmailSent: 0,
                playerProfileUpdateAt: timestamp,
                playerProfileConfirmedAt: timestamp,
                playerProfileEditHistory: JSON.stringify(editHistory)
            }
            
            //API call to update record with new data
            await api(updatePlayerProfileInfo, { input })

            confirmed.value = true
		} 
		catch(e) {
			console.log(e)
		}
    }

    // #endregion
</script>
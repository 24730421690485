import { API, graphqlOperation } from 'aws-amplify'

export default async function (operation: string, variables = {}) {
    let result
    try{
		// run query or mutation
		result = await API.graphql(graphqlOperation(operation, variables))
    }
    catch(err){
		console.log(err)
		// if logged out, refresh token or log out
		result = err
    }

    return result
}
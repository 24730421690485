<template>
    <div class="grid grid-cols-12 space-y-4 lg:space-x-4 lg:space-y-0">
        <div class="col-span-12 lg:col-span-3 xl:col-span-3">
            <tw-selectmenu 
				v-model="field"
				@change="updateField"
				:filterable="true"
				:hiddenLabel="i > 0"
				label="Field" 
				:options="fieldOptions"></tw-selectmenu>
        </div>
        <div class="col-span-12 lg:col-span-3 xl:col-span-3">
            <tw-selectmenu 
				v-model="operator"
				@change="updateOperator"
				:hiddenLabel="i > 0"
				label="Operator" 
				:options="field?.value == 'name' ? primaryKeyComparisonOptions : comparisonOptions"></tw-selectmenu>
        </div>
        <div class="col-span-12 lg:col-span-4 xl:col-span-5">
            <tw-input 
				v-model="criteria" 
				@input="updateCriteria"
				:hiddenLabel="i > 0" 
				label="Criteria" 
				placeholder="Enter search criteria"></tw-input>
        </div>
        <div class="col-span-12 lg:col-span-2 xl:col-span-1 inline-flex items-center gap-2">
            <DuplicateIcon @click="duplicateSearchField" class="h-5 w-5 cursor-pointer"></DuplicateIcon>
            <XCircleIcon @click="deleteSearchField" class="h-5 w-5 cursor-pointer"></XCircleIcon>
        </div>
    </div>
</template>

<script setup>
	import { ref, defineEmits, defineProps } from 'vue'
	
	const props = defineProps([
		'i',
		'searchField',
		'fieldOptions'
	])

	const emit = defineEmits([
        'delete',
        'duplicate',
        'updateField',
        'updateOperator',
        'updateCriteria',
    ])

	const field = ref(props.searchField.field)
	const operator = ref(props.searchField.operator)
	const criteria = ref(props.searchField.criteria)


	const comparisonOptions = [
		{id: 1, label: 'is', value: 'eq'},
		{id: 2, label: 'is not', value: 'ne'},
		{id: 3, label: 'is less than or equal to', value: 'le'},
		{id: 4, label: 'is less than', value: 'lt'},
		{id: 5, label: 'is greater than or equal to', value: 'ge'},
		{id: 6, label: 'is greater than', value: 'gt'},
		{id: 7, label: 'contains', value: 'contains'},  
		{id: 8, label: 'does not contain', value: 'notContains'},  
		{id: 9, label: 'begins with', value: 'beginsWith'},  
	]

	const primaryKeyComparisonOptions = [
		{id: 9, label: 'begins with', value: 'beginsWith'},  
		{id: 1, label: 'is', value: 'eq'},
		{id: 5, label: 'is greater than or equal to', value: 'ge'},
		{id: 6, label: 'is greater than', value: 'gt'},
		{id: 3, label: 'is less than or equal to', value: 'le'},
		{id: 4, label: 'is less than', value: 'lt'},
	]
        
	//Deletes the current searchfield
	function deleteSearchField() {
		
		emit('delete')
	}

	//Emits info needed to duplucate current searchfield
	function duplicateSearchField() {
		const payload = {
			field: field.value,
			operator: operator.value,
			criteria: criteria.value
		}
		emit('duplicate', payload)
	}

	//Updates field option id
	function updateField() {
		const payload = field.value
		emit('updateField', payload)
	}

	//Updates comparison option id
	function updateOperator() {
		const payload = operator.value
		emit('updateOperator', payload)
	}

	function updateCriteria(){
		const payload = criteria.value
		emit('updateCriteria', payload)
	}
</script>

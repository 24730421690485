import ManagePlayerProfiles from './ManagePlayerProfiles.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/manage-player-profiles',
      name: 'ManagePlayerProfiles',
      component: ManagePlayerProfiles,
      meta: { module: 'manageplayerprofiles' }
    }
];
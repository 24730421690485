
    import { defineComponent, computed } from 'vue';
    import { useRoute } from 'vue-router'
    import DefaultLayout from './layouts/DefaultLayout.vue'
    /* eslint-disable */
    import BareLayout from './layouts/BareLayout.vue'

    export default defineComponent({
        name: 'App',
        components:{
            DefaultLayout,
        },
        setup(){
            const isLoaded = true

            const route = useRoute()
            const layout = computed(() => {
                return route.meta.layout || 'DefaultLayout'
            });

            return {isLoaded, layout, route}
        }
    })

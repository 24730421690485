<template>
    <tw-heading :title="championshipData.name" :subtitle="{description: championshipDates}"  :bread-crumb="breadCrumbs" :loading="isLoadingDetails" ref="heading"></tw-heading>

    <div v-if="canEdit" class="px-6 my-4 sm:float-right sm:-mt-16 md:float-none md:mt-4 lg:float-right lg:-mt-16">
        <tw-button v-show="!editChampionshipData" class="button btn-base btn-red   w-full sm:w-auto md:w-full lg:w-auto" :rounded="true" @click="edit">Edit Championship</tw-button>
        <tw-button v-show="editChampionshipData"  class="button btn-base btn-red   w-full mr-0 sm:mr-4 sm:w-24 md:w-full md:mr-0 lg:mr-4 lg:w-24" :rounded="true" @click="editSave">Save</tw-button>
        <tw-button v-show="editChampionshipData"  class="button btn-base btn-white w-full mt-4 sm:mt-0 sm:w-24 md:w-full md:mt-4 lg:mt-0 lg:w-24" :rounded="true" @click="editCancel">Cancel</tw-button>
    </div>
    <div class="grid grid-cols-4 px-6 content-center gap-4 text-gray-500 grid-flow-row">
        <div class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-1 mt-2">
          
            <dt class="field-label">Venue</dt>
            <dd v-show="!isLoadingDetails && !editChampionshipData" class="link mt-1" v-for="venue in championshipData?.location" :key="venue" >
                <router-link :to="{name: 'Venue Detail', params: {id: venue.id}}" v-if="venue.id">{{ venue.name }} -></router-link>
            </dd>
            <div v-show="isLoadingDetails && !editChampionshipData" class="animate-pulse mt-2">
                <div class="h-2 bg-usgaGray rounded"></div>
            </div>
            <div v-if="editChampionshipData" class="-mb-3.5">
				<tw-selectmenu v-for="(venue, i) in championshipData?.location" :key="venue" v-model="updatedLocation[i]" :filterable="true" :options="vl_venues"/>
            </div>
        </div>
        <span>
            <div class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-1 mt-2">
                <dt class="field-label">Champion</dt>      
                <dd v-show="!isLoadingDetails && !editChampionshipData" v-for="(person, i) in championshipData.winner" :key="i" class="link mt-1">
					<router-link :to="{name: 'Player Detail', params: {id: person.nodeTypeWithId}}">{{ person.name }} -></router-link>
                </dd>
                <div v-show="isLoadingDetails && !editChampionshipData" class="animate-pulse mt-2">
                    <div class="h-2 bg-usgaGray rounded"></div>
                </div>
				<div v-if="editChampionshipData">
					<div v-if="championshipData.winner != null">
						<div v-for="(person, i) in championshipData.winner" :key="i">
							<!-- <tw-input v-model="updatedWinner" type="text" ></tw-input> -->
							<tw-selectmenu 
								class="w-full"
								v-model="updatedWinner[i]" 
								:clearable="true" 
								:searchable="true"
								:searchMethod="searchMethod"
								label="" 
								:options="searchResults" 
								:placeholder=championshipData.winner[i].name
							/>
						</div>
					</div>
					<div v-else>
						<div v-if="championshipData.roundTypes.includes('rts')">
							<tw-selectmenu 
								class="w-full"
								v-model="updatedWinner[0]" 
								:clearable="true" 
								:searchable="true"
								:searchMethod="searchMethod"
								label="" 
								:options="searchResults" 
							/>
							<tw-selectmenu 
								class="w-full"
								v-model="updatedWinner[1]" 
								:clearable="true" 
								:searchable="true"
								:searchMethod="searchMethod"
								label="" 
								:options="searchResults" 
							/>
						</div>

						<tw-selectmenu 
								v-else
								class="w-full"
								v-model="updatedWinner" 
								:clearable="true" 
								:searchable="true"
								:searchMethod="searchMethod"
								label="" 
								:options="searchResults" 
							/>
					</div>
				</div>
            </div>
        </span>
        <div class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-1 mt-2">
            <dt class="field-label">Entries</dt>    
            <dd v-show="!isLoadingDetails && !editChampionshipData" class="text-gray-900 mt-1">
                {{ championshipData.entries || '—'}}
            </dd>
            <div v-show="isLoadingDetails && !editChampionshipData" class="animate-pulse mt-2">
                <div class="h-2 bg-usgaGray rounded"></div>
            </div>
            <div v-if="editChampionshipData" class="-mb-3.5">
				<label for="entries" class="sr-only">Entries</label>
				<tw-input v-model="updatedEntries" type="text" id="entries"></tw-input>
            </div>
        </div>
        <div class="sm:col-span-2 lg:col-span-1 mt-2">
            <dt class="field-label">Field Size</dt>    
            <dd v-show="!isLoadingDetails && !editChampionshipData" class="text-gray-900 mt-1">
                {{ championshipData.fieldSize || '—' }}
            </dd>
            <div v-show="isLoadingDetails && !editChampionshipData" class="animate-pulse mt-2">
                <div class="h-2 bg-usgaGray rounded"></div>
            </div>
            <div v-if="editChampionshipData" class="-mb-3.5">
				<label for="field-size" class="sr-only">Field Size</label>
				<tw-input v-model="updatedFieldSize" type="text" id="field-size"></tw-input>
            </div>
        </div>
    </div>
    <div v-show="activeView === 0" class="my-6">
        <tw-table 
            @view="setCurrentPlayer" 
            @sort-query="sortColumn" 
            :config="championshipPlayersTableConfig" 
            :data="data" 
            :currentRecord="currentPlayer" 
            title="Results" 
            :editable="true" 
            :isLoading="isLoadingList" 
            :canEdit="canEdit" 
            :viewable="true">
				<template v-if="showBracket" v-slot:actions>
					<span class="isolate inline-flex">
						<tw-button 
							:class="{'btn-red': activeView === 0, 'btn-white': activeView === 1}" 
							class="relative inline-flex items-center button btn-sm rounded-l-full w-20"
							@click="switchView(0)">
							List
						</tw-button>
						<tw-button 
							:class="{'btn-red': activeView === 1, 'btn-white': activeView === 0}" 
							class="relative -ml-px inline-flex items-center button btn-sm rounded-r-full w-20"
							@click="switchView(1)">
							Bracket
						</tw-button>
					</span>
				</template>
                <template v-slot:scorecard>    
                    <tw-scorecard
                    context="championship"
					:roundTypes="championshipData.roundTypes"
                    :playerId="playerId"
                    :targetId="targetId"
                    :canEdit="canEdit"/>
            </template>
        </tw-table>
    </div>
    <div v-show="activeView === 1" v-if="showBracket">
        <tw-bracket :championshipId="championshipId" :roundTypes="championshipData.roundTypes">
			<template v-slot:actions>
					<span class="isolate inline-flex">
						<tw-button 
							:class="{'btn-red': activeView === 0, 'btn-white': activeView === 1}" 
							class="relative inline-flex items-center button btn-sm rounded-l-full w-20"
							@click="switchView(0)">
							List
						</tw-button>
						<tw-button 
							:class="{'btn-red': activeView === 1, 'btn-white': activeView === 0}" 
							class="relative -ml-px inline-flex items-center button btn-sm rounded-r-full w-20"
							@click="switchView(1)">
							Bracket
						</tw-button>
					</span>
			</template>
		</tw-bracket>
    </div>
</template>
<script setup>

    // #region import statements
    import { defineProps, ref, onMounted, onBeforeUnmount, onBeforeMount, computed } from 'vue'
    import { getChampionshipEntries, getChampionship, listNodes, getPlayersForSelect, byTargetTypeWithIdSortedByFirstName, byTargetTypeWithIdSortedByLastName } from './queries.ts'
    import { useRouter, onBeforeRouteLeave } from 'vue-router';
    import api from "@/utilities/api"
    import formatDateListToRange from "@/utilities/formatDateListToRange.js"
    import EventBus from '@/event'
    import { updateChampionshipInfo } from './mutations.ts'
    import { Auth } from 'aws-amplify'
	import { toTitleCase } from '@/utilities/sanitize.js'
    // #endregion

    // #region setup
    defineProps([
        'id'
    ])

	const activeView = ref(0)
	function switchView(view){
		activeView.value = view
	}
    const championshipData = ref({})
    const editChampionshipData = ref(false)
    // const selectedPlayer = ref({})
    const searchResults = ref([])
    const searchMethod = async function(searchText) {
        const lowerInput = {
            type: 'p',
            sortDirection: 'ASC',
            name: {
                beginsWith: searchText.toLowerCase()
            }
        }
        const upperInput = {
            type: 'p',
            sortDirection: 'ASC',
            name: {
                beginsWith: toTitleCase(searchText)
            }
        }
        const lowerRes = await api(getPlayersForSelect, lowerInput)
        const upperRes = await api(getPlayersForSelect, upperInput)
        const lowerMap = lowerRes.data.nodeByTargetType.items.map(item => {
            return {
                ...item,
                label: item.name
            }
        })
        const upperMap = upperRes.data.nodeByTargetType.items.map(item => {
            return {
                ...item,
                label: item.name
            }
        })
		searchResults.value = [...lowerMap, ...upperMap]
	}
    const updatedWinner = ref([])
    const updatedEntries = ref('')
    const updatedFieldSize = ref('')
    const updatedLocation = ref([])
    const updatedDates = ref([])
    const heading = ref(null)

    const currentPlayer = ref(-1)
    const playerId = ref(null)
    const targetId = ref(null)
    function setCurrentPlayer(payload){
        currentPlayer.value = payload.index
        playerId.value = payload.nodeId
        targetId.value = payload.targetId.split('c-')[1]
    }

	const roundsSummaryMap = ref({})

	const showBracket = computed(() => {
		return championshipData.value?.roundTypes?.includes('rtm') || championshipData.value?.roundTypes?.includes('rm')
	})

	const vl_venues = ref([])

    //Sets up table headers
    const championshipPlayersTableConfig = ref({
        columns: [
            //header: Text that displays for header, prop: Matches column to key in data, mobile: Displays on mobile, mobileBp: Defines mobile break point, 
            //stacked: Is included in stack for mobile display, class: Styling for column
            {header: 'Pos', prop: 'rank',sort: getChampionshipEntries, queryName: "nodeByTargetTypeWithIdSortedByPosition", class: 'w-20 sm:w-28', headerClass: 'w-20 sm:w-28', editable: true},
            // {header: 'Name', prop: 'playerName', class: 'w-32 sm:w-48', headerClass: 'w-32 sm:w-48', editable: false},
			{header: 'First Name', sort: byTargetTypeWithIdSortedByFirstName, queryName: "byTargetTypeWithIdSortedByFirstName", prop: 'nameFirst', class: 'w-32 sm:w-48', headerClass: 'w-32 sm:w-48', editable: false},
			{header: 'Last Name', sort: byTargetTypeWithIdSortedByLastName, queryName: "byTargetTypeWithIdSortedByLastName", prop: 'nameLast', class: 'w-32 sm:w-48', headerClass: 'w-32 sm:w-48', editable: false},
            {header: 'Score', prop: 'total', class: 'w-20 sm:w-28', headerClass: 'w-20 sm:w-28', editable: true},
            // {header: 'Fairways', prop: 'fairwaysHit', mobile: false, mobileBp: 'lg', stacked: true, class: 'text-gray-500 lg:table-cell', editable: true},
            // {header: 'Greens', prop: 'greens', mobile: false, mobileBp: 'lg', stacked: true, class: 'text-gray-500 lg:table-cell', editable: true},
            // {header: 'Putts', prop: 'putts', mobile: false, mobileBp: 'lg', stacked: true, class: 'text-gray-500 lg:table-cell', editable: true},
        ],
        destination: 'Scorecard',
    })

    //Get current route to use in breadcrumbs
    const router = useRouter()
    // const currentRoute = route.currentRoute.value.href
    const championshipId = ref(router.currentRoute.value.params.id)


    //Variables used in getting entries
    const isLoadingDetails = ref(false)
    const isLoadingList = ref(true)
    const error = ref(null)
    const data = ref([]);
    const breadCrumbs = ref([])
    const nextToken = ref(null)
    const sortDirection = ref('ASC')
    const query = ref(getChampionshipEntries)
    const queryName = ref("nodeByTargetTypeWithIdSortedByPosition")
	const roundSlugs = ref([])

    // #endregion

    // #region user authentication
    const canEdit = ref(false);
    const championshipDates = computed(() => {
        return formatDateListToRange(championshipData.value?.dates)
    });
    // load championship data
    (async () => {
        const input = {
            nodeTypeWithId: championshipId.value,
            targetTypeWithId: championshipId.value
        }
        try{
            const res = await api(getChampionship, input)
            //Gets championship name. When sort is called championship record can be first or last item in array so need to use find.
            championshipData.value = res.data.getNode
			if(championshipData.value.location) {
				championshipData.value.location = championshipData.value.location.map(location => {
					location = JSON.parse(location)
					location.value = location.id // set location object for select menu options when editing
					location.label = location.name
					delete location?.create
					return location
				}) 
			}
			else {
				championshipData.value.location = [{value: null, label: null, id: null}]
			}
			if(championshipData.value.winner) {
				championshipData.value.winner = JSON.parse(championshipData.value.winner)
			}

            //Set default values of updatedFields
            championshipData.value?.location?.forEach(location => {updatedLocation.value.push(location)})
			updatedWinner.value = championshipData.value.winner || []
            updatedEntries.value = championshipData.value.entries
            updatedFieldSize.value = championshipData.value.fieldSize
            updatedDates.value = championshipData.value.dates

			//Populates championship table on inital loading of page
			isLoadingDetails.value = true
			const payload = {
				sortDirection: sortDirection.value,
				query: getChampionshipEntries,
				queryName: "nodeByTargetTypeWithIdSortedByPosition"
			}

			if(championshipData.value.eventType === 's')  {
				roundSlugs.value = ['round_1', 'round_2','round_3','round_4']
			
				// add columns to table 
				let total = championshipPlayersTableConfig.value.columns.pop()
				championshipPlayersTableConfig.value.columns.push(
					{header: 'Round 1', prop: 'round1', class: 'w-20 sm:w-28', headerClass: 'w-20 sm:w-28', editable: false},
					{header: 'Round 2', prop: 'round2', class: 'w-20 sm:w-28', headerClass: 'w-20 sm:w-28', editable: false},
					{header: 'Round 3', prop: 'round3', class: 'w-20 sm:w-28', headerClass: 'w-20 sm:w-28', editable: false},
					{header: 'Round 4', prop: 'round4', class: 'w-20 sm:w-28', headerClass: 'w-20 sm:w-28', editable: false},
					total
				)
			}
			else if(championshipData.value.eventType === 'm'){
				roundSlugs.value = ['stroke_play', 'playoff','round_3','round_4']

				// add columns to table 
				let total = championshipPlayersTableConfig.value.columns.pop()
				championshipPlayersTableConfig.value.columns.push(
					{header: 'Round 1', prop: 'round1', class: 'w-20 sm:w-28', headerClass: 'w-20 sm:w-28', editable: false},
					{header: 'Round 2', prop: 'round2', class: 'w-20 sm:w-28', headerClass: 'w-20 sm:w-28', editable: false},
					total
				)
			}
			else if(championshipData.value.eventType === 't'){
				roundSlugs.value = ['round_1', 'round_2','round_3','round_4']

				// add columns to table 
				let total = championshipPlayersTableConfig.value.columns.pop()
				championshipPlayersTableConfig.value.columns.push(
					{header: 'Round 1', prop: 'round1', class: 'w-20 sm:w-28', headerClass: 'w-20 sm:w-28', editable: false},
					{header: 'Round 2', prop: 'round2', class: 'w-20 sm:w-28', headerClass: 'w-20 sm:w-28', editable: false},
					total
				)

				
			}

			await getEntries(payload)

			isLoadingDetails.value = false
        }
        catch(e){
            console.log(e)
        }
    })()

    onBeforeMount( async () => {
        try {
            const user = await Auth.currentAuthenticatedUser()
            canEdit.value = user?.attributes['custom:azureGroup'].indexOf(process.env.VUE_APP_ADMIN_GROUP) > -1
        }
        catch(e) {
            console.log(e)
        }
        
    });
    // #endregion

    // #region infinite scroll setup
	onMounted( () => {
		EventBus.on('infinite-scroll', async () =>{
			const payload = {
				sortDirection: sortDirection.value,
				query: query.value,
				queryName: queryName.value
			}
			if(nextToken.value) await getEntries(payload)
			EventBus.trigger('infinite-scroll-end', null)
		})
	})
		
	onBeforeUnmount( () => {
		EventBus.off('infinite-scroll', null)
	})
	// #endregion

    // #region query and sort table

          

	/**
	 * Sorts column of table based on header clicked
	 * @function sortColumn
	 * @param {Object} payload - Payload object that contains sort direction and query 
	 */
	function sortColumn(payload) {
		sortDirection.value = payload.sortDirection
		query.value = payload.query
		queryName.value = payload.queryName
		nextToken.value = null
		data.value.length = 0
		getEntries(payload)
	}

	/**
	 * Run query to get all players in current championship
	 * @function getEntries
	 * @param {Object} payload - Payload object that contains sort direction and query 
	 */
	async function getEntries(payload){
		isLoadingList.value = true

		// determine entry type - single player or player pair
		let entryType = 'p-'
		if(championshipData?.value?.roundTypes.includes('rtm')) entryType = 'pp-'

		// Query setup to get players stats given a championshipId
		const input = {
			limit: 100,
			filter: {
				nodeTypeWithId: {
					beginsWith: entryType
				}
			},
			targetTypeWithId: championshipId.value,
			sortDirection: sortDirection.value, 
			nextToken: nextToken.value
		}
		try{

			const res = await api(payload.query, input)
			const entries = ref(res.data[payload.queryName].items)

	

			entries.value = entries.value.map(entry => {
				if(roundsSummaryMap.value[entry.nodeTypeWithId]){
					return Object.assign(entry, roundsSummaryMap.value[entry.nodeTypeWithId][roundsSummaryMap.value[entry.nodeTypeWithId].length - 1])
				}
				else return entry
			})

			// entries.value.forEach(async entry => {
			// 	// console.log(entry)
			// 	const input = {
			// 		nodeTypeWithId: entry.nodeTypeWithId,
			// 		targetTypeWithId:{
			// 			beginsWith: 
			// 		},
			// 		sortDirection: 'ASC'
			// 	}
			// 	let res = await api(listPlayersRoundsByChampionship, input)
			// 	res = res.data.listNodes.items
			// 	entry.rank = res[res.length - 1].rank
			// 	// add up total strokes for stroke play
			// 	if(championshipData.value.roundTypes.length === 1 && championshipData.value.roundTypes.includes('rs')) {
			// 		entry.strTotal = res.reduce((sum, a) => Number(sum) + Number(a.strTotal), 0)
			// 	}
			// })

			//Add new queried values to list of data.
			data.value = [...data.value, ...entries.value]
			//Set next token
			nextToken.value = res.data[payload.queryName].nextToken
		} 
		catch(e) {
			error.value = e
			console.log(error.value)
		} 
		finally {
			isLoadingList.value = false
			breadCrumbs.value = [
				{name: 'Championships', route: {name: 'Championships'}},
				{name: 'Championship Detail'},
			]
		}
	}  
	// #endregion

// #region edit 
	function editCancel() {
		//Reset any updated values back to their original values
		updatedLocation.value = championshipData.value.location
		updatedWinner.value = championshipData.value.winner
		updatedEntries.value = championshipData.value.entries
		updatedFieldSize.value = championshipData.value.fieldSize
		updatedDates.value = championshipData.value.dates

		//Show edit button and remove inputs
		editChampionshipData.value = false
	}

	async function editSave() {
		// TO-DO make champ name editable

		// TO-DO update ev and e records with new champ name using lambda
		
		let newLocation = []
		updatedLocation.value.forEach(location => {
			newLocation.push(JSON.stringify({
				name: location.label,
				id: location.value
			}))
		}) 
		let sanitizedEntries = updatedEntries.value == " " ? null : updatedEntries.value
		let sanitizedFieldSize = updatedFieldSize.value == " " ? null : updatedFieldSize.value
		// delete updatedWinner.value[0].label
		// console.log(updatedWinner.value[0])
		const input = {
			nodeTypeWithId: championshipData.value.nodeTypeWithId,
			targetTypeWithId: championshipData.value.targetTypeWithId,
			type: 'c',
			location: newLocation,
			winner: JSON.stringify(updatedWinner.value),
			entries: sanitizedEntries,
			fieldSize: sanitizedFieldSize,
			dates: updatedDates.value
		}
		try{
			const res = await api(updateChampionshipInfo, { input })
			console.log(res)
			//Update champData with updated values
			if(res.data.updateNode.location) {
				championshipData.value.location = res.data.updateNode.location.map(location => {
					location = JSON.parse(location)
					location.value = location.id // set location object for select menu options when editing
					location.label = location.name
					delete location?.create
					return location
				}) 
			}
			championshipData.value.winner = JSON.parse(res.data.updateNode.winner)
			championshipData.value.entries = res.data.updateNode.entries
			championshipData.value.fieldSize = res.data.updateNode.fieldSize
		}catch(e){
			console.log(e)
		}finally{
			//Show edit button and remove inputs
			editChampionshipData.value = false
		}
	}

	async function edit(){
		// Gets all existing venue names for user to select, format data structure to match select option
		const venueQueryInput = {
			type: 'v',
			sortDirection: 'ASC'
		}
		vl_venues.value = (await api(listNodes, venueQueryInput)).data.nodeByTargetType.items.map(record =>{
			return {
				label: record.name,
				name: record.name,
				value: record.nodeTypeWithId
			}
		})
		editChampionshipData.value = true
		heading.value.setInputs()
	}

	//Check if user is editing and ask if they want to save changes or not.
	onBeforeRouteLeave ((to, from, next) => {
		if (editChampionshipData.value){
			const answer = window.confirm('Do you want to leave without saving your changes?')

			if (answer) {
				next()
			}else{
				next(false)
			}
		}else{
			next()
		}
	})

	// #endregion

    // #region route user to player page


            // const router = useRouter()

            /**
             * Routes user to detail page of selected player
             * @function routeToPlayerPage
             * @param {Object} payload - Payload object that contains sort direction and query 
             */
            // function routeToPlayerPage(payload){
            //     router.push({name: championshipPlayersTableConfig.destination, params:{id: payload.id}})
            // }
            // #endregion

</script>
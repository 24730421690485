<template>
<div>
	<label for="ms-in" class="block text-sm font-medium text-usgaDarkGray truncate">{{label}}</label>
	<div @click="openMS()">
		<input 
			:value="selected"
			id="ms-in" 
			class="w-full border border-gray-300 focus:ring-usgaRed focus:border-usgaRed shadow-sm rounded-sm mt-1 pl-2 pt-1" 
			style="height: 28pt; min-width: 100pt;overflow:hidden" size="10" 
		>
		<div v-if="showModal" class="z-10 tw-ms" :style="{height: placement.height, width:placement.width, top:placement.top}">
			<ul :style="{width:placement.width}">
				<li v-for="(item, i) in items" :key="items[i].name" :class="{selected:selected?.indexOf(items[i].name) > -1}" @click="handleClick(items[i].name)">{{ items[i].name }}</li>
			</ul>
		</div>
	</div>
	<div v-if="showModal" class="outside" v-on:click="closeMS()"></div>
</div>
</template>

<script setup>
	import { defineExpose, defineProps, ref, defineEmits } from 'vue'

	// eslint-disable-next-line no-unused-vars
	const props = defineProps({
		items: [Object],
		modelValue: String,
		label: {
			type: String,
			default: '',
		}
	})
    defineExpose({
        handleClick,openMS,closeMS
    })
	const emit = defineEmits(["update:modelValue"])
	// const emit = defineEmits(["udpateMS"])
	const placement = ref({'top': '', 'left': '', 'width':'', 'height':'200pt'})
	const showModal = ref(false)
	const selected = ref(props.modelValue)
	// const selectedItems = ref([])

	function handleClick(name){
		if (selected.value?.indexOf(name) > -1){
			//remove from list
			selected.value = selected.value.replace(name, '')
		}else{
			//add to list and resort
			let arr = []
			if(selected.value) selected.value = selected.value + ", " + name
			else selected.value = name
			arr = selected.value.split(", ")
			arr = arr.sort()
			selected.value = arr.join(', ')
		}
		//clean up commas
		while (selected.value.substring(0, 1) == " " || selected.value.substring(0, 1) == ","){
			selected.value = selected.value.substring(1,selected.value.length)
		}
		selected.value = selected.value.replaceAll(",,", ',')
		selected.value = selected.value.replaceAll(", ,", ',')
		selected.value = selected.value[selected.value.length-2] == "," ? selected.value.substring(0,selected.value.length-2) : selected.value
		handleInput(selected.value)
	}

	function openMS(){
		let elem = document.getElementById('ms-in');
		let rect = elem.getBoundingClientRect();
		if (typeof rect !== 'function') {
			placement.value.top = rect.bottom 
			placement.value.left = rect.left 
			placement.value.width = rect.right - rect.left 
		}
		showModal.value = true 
	}	
	function closeMS(){
		showModal.value = false 
	}
	function handleInput(val) {
		emit('update:modelValue', val)
	}
</script>
<style scoped>
.tw-ms{
	cursor:default;
	position: absolute ;
	overflow:scroll;
	border: solid 1px;
	border-top: 0px;
	border-color: rgba(209, 213, 219);
	border-radius:0.125rem;
	background-color: white;
	padding-bottom: 0px;
}
.selected{
	@apply bg-usgaRed text-white;
}
.outside {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 5;
}
li{
	@apply px-4 py-1;
}

</style>
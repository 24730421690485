<template>
    <div v-if="!loading" class="md:flex md:items-center md:justify-between bg-white px-6">
        <div class="min-w-0 py-2">
            <!-- Displays all breadcrumb links passed from current page -->
            <div class="flex flex-row mt-3 mb-2">
                <div v-for="(crumb, i) in breadCrumb" :key="i" class="flex flex-row">
                    <ChevronRightIcon v-show="i" class="flex-shrink-0 h-5 w-5 text-gray-500 pt-1 mx-1" aria-hidden="true" />
                    <router-link v-if="crumb.route" :to="crumb.route" class="text-gray-500 hover:text-usgaRed"> {{ crumb.name }} </router-link>
                    <span v-else class="text-gray-500"> {{ crumb.name }} </span>
                </div>
            </div>
            <div class="flex align-top">
                <slot></slot>
                <div v-if="!editable">
                    <h2 id="page-heading" class="text-2xl font-bold text-gray-900 sm:text-3xl sm:truncate">{{ title || '' }}</h2>
                    <span v-if="subtitle">
                        <h3 v-if="subtitle.city || subtitle.country" class="text-sm font-medium text-gray-600 sm:truncate">{{ subtitle.city }}, {{ subtitle.country }}</h3>
                        <h3 v-if="subtitle.description" class="text-sm font-medium text-gray-600 sm:truncate">{{ subtitle.description }}</h3>
                        <h3 v-if="subtitle.htmlDescription" class="text-sm font-medium text-gray-600 sm:truncate" v-html="subtitle.htmlDescription"></h3>
                    </span>
                </div>
                <div v-else-if="editable">
                    <fieldset>
                        <legend class="sr-only">Details</legend>
                        <div class="bg-white rounded-sm shadow-sm -space-y-px">
                            <div>
                                <label for="title" class="sr-only">Title</label>
                                <input v-if="title" @input="updateTitle" v-model="updatedTitle" type="text" name="title" id="title" class="px-3 py-1 focus:ring-usgaRed focus:border-usgaRed relative block w-full rounded-none rounded-t-sm bg-transparent focus:z-10 text-sm border-usgaGray" placeholder="Name" />
                            </div>
                            <div class="flex -space-x-px">
                                <div v-if="subtitle" class="w-1/2 flex-1 min-w-0">
                                    <label for="city" class="sr-only">City</label>
                                    <input @input="updateCity" v-model="updatedCity" type="text" name="city" id="city" class="px-3 py-1 focus:ring-usgaRed focus:border-usgaRed relative block w-full rounded-none rounded-bl-sm bg-transparent focus:z-10 text-sm border-usgaGray" placeholder="City" />
                                </div>
                                <div v-if="subtitle" class="flex-1 min-w-0">
									<select 
										v-model="updatedCountry"
										@change="updateCountry" 
										name="country" 
										id="country" 
										class="px-3 py-1 focus:ring-usgaRed focus:border-usgaRed relative block w-full rounded-none rounded-br-sm bg-transparent focus:z-10 text-sm border-usgaGray">
										<option 
											v-for="country in countries" 
											:key="country" 
											:value="country.name">
											{{country.name}}
										</option>
									</select>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="animate-pulse px-6 py-2 max-w-sm w-full">
        <div class="grid grid-cols-3 gap-4 mt-5 mb-2">
            <div v-for="i in 3" :key="i" class="h-2 bg-usgaGray rounded col-span-1"></div>
        </div>
        <div class="flex space-x-4 mt-5">
            <!-- <div class="rounded-full bg-usgaGray h-14 w-14"></div> -->
            <div class="flex-1 space-y-6 py-4">
                <div class="space-y-4">
                    <div class="grid grid-cols-4 gap-4">
                        <div v-for="i in 2" :key="i" class="h-2 bg-usgaGray rounded col-span-2"></div>
                    </div>
                    <div class="h-2 bg-usgaGray rounded w-3/4"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { defineExpose, defineProps, defineEmits, ref, onMounted } from 'vue'
	import countries from "@/utilities/countries.json"
	
    const props = defineProps([
        'title',
        'breadCrumb',
        'subtitle',
        'editable',
        'loading'
    ])

    const emit = defineEmits([
        'updateTitle',
        'updateCity',
        'updateCountry'
    ])

    const updatedTitle = ref('')
    const updatedCity = ref('')
    const updatedCountry = ref('')

    function updateTitle () {
        emit('updateTitle', updatedTitle.value)
    }
    function updateCity() {
        emit('updateCity', updatedCity.value)
    }
    function updateCountry() {
        emit('updateCountry', updatedCountry.value)
    }

    // eslint-disable-next-line no-unused-vars
    function setInputs (){
        updatedTitle.value = props.title
        updatedCity.value = props.subtitle.city
        updatedCountry.value = props.subtitle.country
    }

    onMounted(() => {
        if (props.title && props.subtitle){
            updatedTitle.value = props.title
            updatedCity.value = props.subtitle.city
            updatedCountry.value = props.subtitle.country
        }
    })
    
    defineExpose({setInputs})
</script>
export const championshipSortedByName = /* GraphQL */ `
  query NodeByTargetType(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelnodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByTargetType(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nodeTypeWithId
        targetTypeWithId
        type
        city
        country
        name
        state
        dates
		    winner
        location
      }
      nextToken
    }
  }
`;

export const listChampcodes = /* GraphQL */ `
  query ListChampcodes(
    $id: ID
    $filter: ModelChampcodesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChampcodes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        code
        name
		isActive
      }
      nextToken
    }
  }
`;

export const byYear = /* GraphQL */ `
  query ByYear(
    $allChampYears: Int!
    $year: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelchampYearsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byYear(
      allChampYears: $allChampYears
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        year
      }
      nextToken
    }
  }
`;
<template>
    <div>
        <Listbox as="div" v-model="_modelValue">
            <ListboxLabel class="block text-sm font-medium text-gray-700" :class="{'sr-only' : hiddenLabel}"> {{ label }} </ListboxLabel>
            <div class="mt-1 relative">
            <ListboxButton
                id="listBoxButton" 
                class="bg-white relative w-full border border-gray-300 rounded-sm shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-usgaRed focus:border-usgaRed sm:text-sm"
                :class="{'bg-usgaGray cursor-not-allowed pointer-events-none': disabled}">
                <span v-if="!filterText" class="block truncate" :class="{'text-usgaDarkGray': !modelValue?.label}">{{ modelValue?.label || _placeholder}}</span>
                <span v-else class=" truncate flex align-middle">{{ filterText }}<span class="h-4 bg-black inline-block" style="content: ''; width: 1px; animation: cursor-blink 1s steps(2) infinite;"></span></span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <XCircleIcon 
                        class="h-5 w-5 text-usgaRed hover:text-usgaDarkRed pointer-events-auto" 
                        aria-hidden="true" 
                        v-show="!disabled && modelValue?.label"
                        v-if="clearable"
                        @click.stop="clear"/>
                    <SelectorIcon class="h-5 w-5 text-usgaGray" aria-hidden="true" v-show="!disabled"/>
                </span>
            </ListboxButton>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <ListboxOptions @focus="addListener" @blur="removeListener" class="absolute z-30 mt-1 w-full bg-white shadow-lg max-h-60 rounded-sm py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    <ListboxOption
                        as="template"
                        :key="option" 
                        v-for="option in filteredOptions" 
                        v-slot="{ active, selected }"
                        @mousedown="onSelect(option)"
                        :value="option">
                        <li v-show="option.show" :class="[active ? 'text-white bg-usgaRed' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                            <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                {{ option.label }}
                            </span>
                            <span v-if="selected" :class="[active ? 'text-white' : 'text-usgaRed', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                        </li>
                    </ListboxOption>
                    <ListboxOption as="template" v-show="noFilteredOptions">
                        <li class="pointer-events-none select-none relative py-2 pl-3 pr-9">
                            <span>No results</span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
            </div>
        </Listbox>
    </div>
</template>

<script setup>
    // #region imports
    import {  defineProps, getCurrentInstance, ref, computed, nextTick } from 'vue'
    import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
    import { CheckIcon, ChevronUpDownIcon as SelectorIcon } from '@heroicons/vue/24/solid'
    // #endregion


    // #region setup
    const { emit } = getCurrentInstance()
    const props = defineProps({
        label: String,
        options: [Object],
        disabled: Boolean,
        placeholder: {
            type: String,
            default: 'None selected'
        },
		hiddenLabel: {
			type: Boolean,
			default: false
		},
        clearable: {
            type: Boolean,
            default: false
        },
        filterable: {
            type: Boolean,
            default: false
        },
		searchable: {
            type: Boolean,
            default: false
        },
		searchMethod: {
            type: Function,
            default: () => {return}
        },
        modelValue: {
            type: Object,
            default: null
        }
    })
    // #endregion


    // #region editing model value
    const _modelValue = computed(() => {
        return props.modelValue
    })

    const _placeholder = ref(props.placeholder)
    // #region editing model value

    /**
     * Updates the bound value of the select menu
     * @function onSelect
     * @param {Object} option - the selected option of the select menu
     */
    async function onSelect(option) {
        emit('update:modelValue', option)
        emit('change', option)
        await nextTick()
        return
    }

     /**
     * Clears the selected value
     * @function clear
     */
    async function clear(){
        emit('update:modelValue', {})
        emit('change', {})
        filterText.value = ''
        await nextTick()
        return
    }
    // #endregion


    // #region filtering options
    /**
     * Adds event listener for filter when select menu is focused
     * @function addFilterListener
     */
    function addListener() {
        if(props.disabled) return
		else if(props.filterable) _placeholder.value = 'Type to filter list...'
		else if(props.searchable) _placeholder.value = 'Type to search...'
        filterText.value = ''  
        document.addEventListener('keydown', keyPress, true)
    }

    /**
     * Removes event listener for filter when select menu is unfocused
     * @function removeListener
     */
    function removeListener() {
        _placeholder.value = props.placeholder
        document.removeEventListener('keydown', keyPress, true)
        filterText.value = ''
    }


    const filterText = ref('')

     /**
     * Event to capture keystrokes to filter select options
     * @function keyPress
     */
    function keyPress(event) {
        const key = event.key
        if (key == 'Backspace') {
            filterText.value = filterText.value.slice(0, -1)
			if(props.searchable && filterText.value) {
				props.searchMethod(filterText.value)
			}
        }
        else if(key == 'Escape') {
            removeListener()
        }
        else if((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode == 32) || (key == ' ')) {
            filterText.value = filterText.value + key
			if(props.searchable && filterText.value) {
				props.searchMethod(filterText.value)
			}
        }

		
    }

    /**
     * List of options filtered by filter text
     * @yields {array}
     */
    const filteredOptions = computed(() => {
		if(props.searchable && !filterText.value) return []
		return props.options.map((option) => {
			return {
				...option,
				show: !props.filterable || !filterText.value || option.label.toLowerCase().includes(filterText.value.toLowerCase()) == true
			}
		})
    })

    /**
     * If no options remain when filtered by filter text
     * @yields {boolean}
     */
    const noFilteredOptions = computed(() => {
        return filteredOptions.value.every((option) =>{
            return option.show === false
        })
    })
    // #endregion

</script>

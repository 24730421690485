export const createImport = /* GraphQL */ `
  mutation CreateImport(
    $input: CreateImportInput!
    $condition: ModelImportConditionInput
  ) {
    createImport(input: $input, condition: $condition) {
      id
      name
    }
  }
`;

export const updateImport = /* GraphQL */ `
  mutation UpdateImport(
    $input: UpdateImportInput!
    $condition: ModelImportConditionInput
  ) {
    updateImport(input: $input, condition: $condition) {
      revertedAt
    }
  }
`;
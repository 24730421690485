
import { ref, nextTick, defineComponent} from "vue";

export default defineComponent({
    props: { 
        label: String, 
		hiddenLabel: Boolean,
        type: String, 
        id: String, 
        placeholder: String, 
        required: Boolean, 
        validation: String,
        hint: String,
        isInvalid: Boolean,
		disabled: Boolean,
        modelValue: {
            type: [String, Number],
            default: null
        }
    },
    setup(props: any, args: any): any{

        const { emit } = args;

        const onInput = async ({ target }: any) => {
            emit('update:modelValue', target.value);
            await nextTick()
            validate()
            return
        };

        const valid = ref(true)

        function validate(){
            if((props.required && !props.modelValue) || props.isInvalid ){
                valid.value = false
            }
            else if((props.required && props.modelValue) || !props.isInvalid){
                valid.value = true
            }
        }

        return { valid, validate, onInput }
    }
});

<template>
    <div class="mt-8 mx-6 mb-6 shadow ring-1 ring-black ring-opacity-5">
        <div class="bg-white px-4 py-3 border-b border-gray-200 sm:px-6 flex justify-between">
            <h3 class="text-xl leading-6 font-medium text-gray-900">Results</h3>
			<span v-if="$slots.actions"><slot name="actions"></slot></span>
        </div> 
        <div class="bg-usgaGray overflow-x-scroll">
            <div class="min-w-max" :class="columnClass">
                <div v-for="(column, i) in bracketData" :key="column" class="py-4 px-6 col-span-1 my-auto items-center bg-usgaLightGray">
                    <h3 class="text-gray-500">{{ headerNames[i] }}</h3>
                </div>
                <div v-for="(column, j) in bracketData" :key="column" class="py-8 col-span-1 my-auto items-center flex-none" :class="{'pl-6' : j == 0}">
                    <div v-for="(matchup, i) in column" :key="matchup" class="">
                        <tw-bracketmatchup :matchupInfo="matchup" :matchupIndex="i" :columnIndex="j" :columnCount="columnCount"></tw-bracketmatchup>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script setup>
	import { ref, defineProps } from 'vue'
	import { getMatchPlayRounds } from './queries'
	import api from "@/utilities/api"

	const props = defineProps([
		'championshipId',
		'roundTypes'
	])
	
	let championshipId = props.championshipId.split('c-')[1]
	let teamEventRounds = ['r32', 'r16', 'quarter', 'semi', 'final']
	let matchPlayRounds = ['r64', ...teamEventRounds]
	let roundNames = []
	let roundMatchups = []
	let roundType = ''

	if(props.roundTypes.includes('rtm')){
		roundNames = teamEventRounds
		roundType = 'rtm'
		roundMatchups = [
			[1,32],[16,17],[8,25],[9,24],[4,29],[13,20],[5,28],[12,21],
			[2,31],[15,18],[7,26],[10,23],[3,30],[14,19],[6,27],[11,22]
		]
	}
	else {
		roundNames = matchPlayRounds
		roundType = 'rm'
		roundMatchups = [
			[1,64],[32,33],[16,49],[17,48],[8,57],[25,40],[9,56],[24,41],
			[4,61],[29,36],[13,52],[20,45],[5,60],[28,37],[12,53],[21,44],
			[2,63],[31,34],[15,50],[18,47],[7,58],[26,39],[10,55],[23,42],
			[3,62],[30,35],[14,51],[19,46],[6,59],[27,38],[11,54],[22,43]
		]
	}

	const bracketData = ref([])
	const columnClass = ref('')
	const headerNames = ref([])
	const columnCount = ref(0);

	(async () => {
		// generate first round of bracket matchups based on seed
		const input = {
			targetTypeWithId:`${roundType}-${championshipId}${roundNames[0]}`,
			sortDirection: 'ASC'
		}

		let res = await api(getMatchPlayRounds, input)
		res = res.data.nodeByTargetTypeWithIdSortedByPosition.items
		if(res.length){
			let sorted = res.sort(function(a, b){
				return ('' + a.rank).localeCompare(b.rank, undefined,{numeric: true})
			})
			
			let arr = []

			roundMatchups.forEach( (matchup) => {
				let a = matchup[0]
				let b = matchup[1]

				let entryA = sorted[a-1]
				let entryB = sorted[b-1]
				arr.push(
					[
						entryA,
						entryB
					]
				)
			})
			
			bracketData.value.push(arr)

			for(let i=1; i < roundNames.length; i++) {
				let roundName = roundNames[i]
				const input = {
					targetTypeWithId:`${roundType}-${championshipId}${roundName}`,
					sortDirection: 'ASC'
				}

				let res = await api(getMatchPlayRounds, input)
				res = res?.data?.nodeByTargetTypeWithIdSortedByPosition?.items
				// loop through previous column to find winner
				let arr = []
				bracketData.value[i-1].forEach((matchup,j) => {
					let winningIndex = matchup.findIndex(player => {
						return player.wonRound
					})
					let winningId = matchup[winningIndex].nodeTypeWithId
					if(j % 2 === 0) {
						let entryA = res.find(currentRoundEntry => {return currentRoundEntry.nodeTypeWithId == winningId})
						if(entryA) arr[Math.floor(j / 2)] = [entryA]
					}
					else {
						let entryB = res.find(currentRoundEntry => {return currentRoundEntry.nodeTypeWithId == winningId})
						if(entryB && arr[Math.floor(j / 2)]) arr[Math.floor(j / 2)].push(entryB)
					}
					
				})
				bracketData.value.push(arr)
			}

			// Gets total columns in object and then creates class variable for css to determine how many grid columns are needed
			columnCount.value = bracketData.value.length
			columnClass.value = 'grid grid-cols-' + columnCount.value;

			for (var i = 0; i < columnCount.value; i++){
				headerNames.value.push(bracketData.value[i]?.[0]?.[0]?.round || "—")
			}
		}
	})()


	// function generateBracketMatchups(n){
	// 	let pairs = [[1,2]]
	// 	let numPairs = pairs.length
	// 	while(numPairs < n){
	// 		let newPairs = []
	// 		for(let i = 0; i < numPairs ; i++){
	// 			let a = pairs[i][0]
	// 			let b = pairs[i][1]
	// 			let newPairA = [a, numPairs * 4 + 1 - a]
	// 			let newPairB = [b, numPairs * 4 + 1 - b]
	// 			newPairs.push(newPairA),
	// 			newPairs.push(newPairB)
	// 		}
	// 		pairs = newPairs
	// 		numPairs = pairs.length	
	// 	}
	// 	return pairs
	// }

	
</script>
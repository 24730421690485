/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://yk7v7lcpb5gzti27xqkcxrncne.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-cj26vpcauzchraaw2fwjyluxiu",
    "aws_cloud_logic_custom": [
        {
            "name": "emailSetup",
            "endpoint": "https://ecq2t7yno8.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "playerMerge",
            "endpoint": "https://rw462og4yl.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "playerProfileRegistration",
            "endpoint": "https://1azqcehuvd.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:c0e809d6-4d9b-45ec-8fb9-95594091a89e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_XGQHhoKKG",
    "aws_user_pools_web_client_id": "10qkilu2gtsuoompb6vo8rfvgg",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "imports132941-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

<template>
  <div class="bg-cover layout-background">
    <main>
      <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div class="px-4 py-4 sm:px-0">
          <slot></slot>
        </div>
      </div>
    </main>
  </div>
</template>

<style>
	.layout-background{
		background: url('../assets/img/16_Pine_Needles.jpg');
	}
</style>
<template>
    
     <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-sm sm:px-10">
                <img class="mx-auto h-12 w-auto" src="../../assets/img/USGA-Logo.png" alt="Workflow" />
                <div v-if="!submit">
                    <div class="mt-12 grid grid-cols-1 gap-3">
                        <div class="space-y-4">

                            <tw-input v-model="fullName" label="Full Name" id="'fullName'" :required="true"></tw-input>

                            <tw-input v-model="title" label="Title" id="'title'" :required="true"></tw-input>

                            <tw-input v-model="email" label="Email" id="'Email'" :required="true"></tw-input>

                            <tw-input v-model="company" label="Organization" id="'Company'" :required="true"></tw-input>

                            <div class="form-check">
                                <input v-model="privacy" id="privacyPolicy" aria-describedby="privacy-policy" name="privacyPolicy" type="checkbox" class="focus:ring-usgaRed h-4 w-4 mr-2 text-usgaRed border-gray-300 rounded" />
                                <label class="form-check-label inline-block text-gray-800" for="flexCheckDefault">
                                    I agree to the <a class="underline hover:text-gray-500" href="https://www.usga.org/content/usga/home-page/usga-privacy-policy.html.html" target="_blank">USGA Privacy Policy.</a>
                                </label>
                            </div>

                            <div class="form-check">
                                <input v-model="terms" id="termsOfService" aria-describedby="terms-of-service" name="termsOfService" type="checkbox" class="focus:ring-usgaRed h-4 w-4 mr-2 text-usgaRed border-gray-300 rounded" />
                                <label class="form-check-label inline-block text-gray-800" for="flexCheckDefault">
                                    I agree to the <a class="underline hover:text-gray-500" href="https://www.usga.org/terms-and-conditions.html" target="_blank">USGA Terms of Service.</a>
                                </label>
                            </div>

                        </div>
                        <div>
                            <tw-button @click="submitRequest" class="button btn-base btn-red w-full mt-8" :rounded="true">Submit</tw-button>
                            <span v-if="!filledOut" class="text-usgaRed justify-center mt-2">Please fill out all fields on the form.</span>
                            <tw-button @click="$router.push({name: 'MediaLogin'})" class="button btn-base btn-white w-full mt-4" :rounded="true">Cancel</tw-button>
                        </div>
                    </div>

                </div>

                <div v-if="submit" class="mt-12">
                    <div class="text-3xl mb-4">Thank You</div>
                    <div class="text-md mb-4">Your request has been received. Once approved, you will receive a confirmation email. If you do not receive a response within 24 hours, please contact Joey Geske at <a class="link" href="mailto:jgeske@usga.org">jgeske@usga.org.</a></div>

                    <router-link :to="{name: 'MediaLogin'}" class="button btn-base btn-red w-full mt-8 rounded-full justify-center">Back to Login</router-link>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
    // #region import statements
    import { ref } from 'vue'
    import { saveAccessRequest } from './mutations.ts'
    import { API } from 'aws-amplify'
	import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
    // #endregion

    // #region setup
    const submit = ref(false)
    const filledOut = ref(true)
    const fullName = ref('')
    const title = ref('')
    const email = ref('')
    const company = ref('')
    const privacy = ref(false)
    const terms = ref(false)
    // #endregion

    // #region submit
    async function submitRequest () {
        if (fullName.value == "" || title.value == "" || email.value == "" || company.value == "" || privacy.value == false || terms.value == false){
            filledOut.value = false
            return
        }else{
            filledOut.value = true
        }

        const input = {
            name: fullName.value,
            title: title.value,
            email: email.value,
            company: company.value,
            archived: 0
        }

        try{
            // const res = await api(saveAccessRequest, {input})
            const res = await API.graphql({
                query: saveAccessRequest,
                variables: {input: input},
                authMode: GRAPHQL_AUTH_MODE.AWS_IAM
            })
            submit.value = true
            console.log(res)
        }catch(e){
            console.log(e)
        }
    }
    // #endregion

</script>

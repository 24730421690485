export const createNewPlayer = /* GraphQL */ `
  mutation createNode(
    $input: CreateNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    createNode(input: $input, condition: $condition) {
      nodeTypeWithId
      targetTypeWithId
    }
  }
`;

export const createEmail = /* GraphQL */ `
  mutation CreateEmail(
    $input: CreateEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    createEmail(input: $input, condition: $condition) {
      id
      template
      status
      sentAt
      to
      createdAt
      updatedAt
    }
  }
`;

export const updatePlayer = /* GraphQL */ `
  mutation UpdateNode(
    $input: UpdateNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    updateNode(input: $input, condition: $condition) {
      nodeTypeWithId
      targetTypeWithId
      userId
    }
  }
`;
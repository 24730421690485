/**
 * Formats entered date
 * validateDate
 */
function sanitizeDate(date){
	if(!date) return null
	return new Date(date).toISOString().split('T')[0]
}

function sanitizeDateTime(dateTime){
	if(!dateTime) return null
	return new Date(dateTime).toISOString()
}

function toTitleCase(str) {
	return str.replace(/\w\S*/g, function(txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		}
	);
}


exports.sanitizeDate = sanitizeDate
exports.sanitizeDateTime = sanitizeDateTime
exports.toTitleCase = toTitleCase
import ExportData from './ExportData.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/export-data',
      name: 'ExportData',
      component: ExportData,
      meta: {module: 'exportdata'}
    }
];
import Championships from './Championships.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/championships',
      name: 'Championships',
      component: Championships,
      meta: {module: 'championships'}
    }
];
export const saveImportConfig = /* GraphQL */ `
  mutation CreateImport(
    $input: CreateImportInput!
    $condition: ModelImportConditionInput
  ) {
    createImport(input: $input, condition: $condition) {
      id
      name
      importType
      file
      type
      fieldMapping
      headerBeginsOnRow
      dataBeginsOnRow
      importAs
      user
      status
      venue
      championship
      updatedAt
      createdAt
    }
  }
`;

export const updateImportConfig = /* GraphQL */ `
  mutation UpdateImport(
    $input: UpdateImportInput!
    $condition: ModelImportConditionInput
  ) {
    updateImport(input: $input, condition: $condition) {
      id
      name
      importType
      type
      fieldMapping
      headerBeginsOnRow
      dataBeginsOnRow
      importAs
      updatedAt
      createdAt
    }
  }
`;
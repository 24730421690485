import ChampionshipDetail from './ChampionshipDetail.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/championships/championship-detail/:id',
      name: 'Championship Detail',
      component: ChampionshipDetail,
      meta: {module: 'championships'},
      props: true
    }
];
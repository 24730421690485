export const saveAccessRequest = /* GraphQL */ `
  mutation CreateSignup(
    $input: CreateSignupInput!
    $condition: ModelSignupConditionInput
  ) {
    createSignup(input: $input, condition: $condition) {
      id
      name
    }
  }
`;
import VenueDetail from './VenueDetail.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/venues/venue-detail/:id',
      name: 'Venue Detail',
      component: VenueDetail,
      meta: {module: 'venues'},
      props: true
    }
];